import * as Environment from "../base/Environment.js";

import {LoaderSystem, DataLoaderJob} from "../base/Loader.js";
import {RenderContext} from "../base/Display.js";
import {AnimatableSprite} from "../base/Display2D.js";

import {SiteSection} from "./SiteSection.js";

//
// IntroAnimation extends SiteSection
//

export const IntroAnimation = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.IntroAnimation = IntroAnimation;

IntroAnimation.prototype = Object.create (SiteSection.prototype);

IntroAnimation.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	if (Environment.IS_IN_NEOS_EDITOR)
		return;
	
	const square = this.square = element.querySelector (".sievert-logo-square-standalone > div");
	const headline = this.headline = element.querySelector ("h1");
	const leading = this.leading = element.querySelector (".leading");
	
	this.createCover ();
	
};

IntroAnimation.prototype.createCover = function () {
	const main = document.querySelector ("main");
	if (main)
		main.style.overflow = "hidden";
	
	const cover = this.cover = document.createElement ("div");
	cover.classList.add ("animation-page-cover");
	
	const element = this.element;
	element.classList.add ("invisible");
	element.appendChild (cover);
	
	window.setTimeout (this.watchScrollForCover.bind (this), 1000);
	
};

IntroAnimation.prototype.scroll = function () {
	if (Environment.IS_IN_NEOS_EDITOR)
		return;
	
	SiteSection.prototype.scroll.apply (this, arguments);
	
	if (!this.didAppear) {
		const viewportSize = this.getStage ().size;
		const pageBounds = this.pageBounds;
		
		if (pageBounds.top > viewportSize [1] * .75)
			return;
		
		this.didAppear = true;
		
		const element = this.element;
		element.classList.remove ("invisible");
		
		this.square.style.visibility = "hidden";
		
		const splitElementWords = IntroAnimation.splitElementWords;
		
		const headline = this.headline;
		headline.style.visibility = "hidden";
		splitElementWords (headline);
		this.fadingElements = headline.querySelectorAll ("span");;	
		
		const leading = this.leading;
		if (leading) {
			leading.style.visibility = "hidden";
			splitElementWords (leading);
			this.fadingLeadingElements = leading.querySelectorAll ("span");
			
		}
		this.startAnimation ("Square", {direction: 1, rate: .02, delay: 15});
		
	}
	
	this.watchScrollForCover ();
	
};

IntroAnimation.prototype.watchScrollForCover = function () {
	if (true || !this.states ["FadeCover"]) {
		const viewportSize = this.getStage ().size;
		
		const scrollOffset = RenderContext.getScrollOffset ();
		const pageBounds = this.pageBounds || this.element.getBoundingClientRect ();
		
		const bottomDelta = Math.min (
			viewportSize [1] * .5 - pageBounds.bottom,
			scrollOffset [1] - 100
			
		);
		
		if (bottomDelta > 0 && !this.states ["FadeCover"]) {
			this.element.classList.remove ("invisible");
			this.startAnimation ("FadeCover", {direction: 1, rate: .05});
			
		}
		
		this.updateCoverAlpha ();
		
	}
	
};

IntroAnimation.splitElementWords = function (element) {
	let headlineMarkup = "";
	
	function splitChildren (childNodes) {
		for (let i = 0; i < childNodes.length; i++) {
			const childNode = childNodes [i];
			
			if (childNode.nodeType == 1) {
				headlineMarkup += "<" + childNode.nodeName;
				
				for (let i = childNode.attributes.length; i--;) {
					var attribute = childNode.attributes [i];
					headlineMarkup += " " + attribute.name + " = " + attribute.value;
					
				}
				
				headlineMarkup += ">";
				
			}
			
			if (childNode.children && childNode.children.length) {
				splitChildren (childNode.children);
				
			} else {
				const headlineWords = childNode.textContent.split (/\s+/);
				for (let i = 0; i < headlineWords.length; i++) {
					const headlineWord = headlineWords [i];
					if (!headlineWord) {
						headlineMarkup += " ";
						continue;
						
					}
					
					headlineMarkup += "<span>" + headlineWord + "</span>";
					if (childNode.nodeType != 1 || i < headlineWords.length - 1)
						headlineMarkup += " ";
					
				}
				
			}
			
			if (childNode.nodeType == 1)
				headlineMarkup += "</" + childNode.nodeName + ">";
				
		}
		
	}
	splitChildren (element.childNodes);
	
	element.innerHTML = headlineMarkup;
	
};

IntroAnimation.prototype.animateSquare = function () {
	let state = this.states ["Square"];
	const delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	state = this.updatedState ("Square");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	
	const square = this.square;
	square.style.visibility = "";
	
	if (t < 1) {
		square.style.opacity = Math.min (1, t * 2);
		const scale = Math.pow (.75, 1 - t);
		square.style.transform = "scale(" + scale + ")";
		
	} else {
		square.style.opacity = "";
		square.style.transform = "";
		
	}
	
	if (t > .8 && !this.states ["FadeElements"]) {
		this.headline.style.visibility = "";
		this.startAnimation ("FadeElements", {direction: 1, rate: .015});
		
	}
	
};

IntroAnimation.prototype.animateFadeElements = function () {
	const state = this.updatedState ("FadeElements");
	let t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) * .6;
	
	const fadingElements = this.fadingElements;
	
	const fadeRange = .5;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	for (let i = fadingElements.length; i--;) {
		const fadingElement = fadingElements [i];
		
		const opacity = (t - i * distributionRange) / fadeRange;
		fadingElement.style.opacity = opacity < 1 ? opacity : "";
		
	}
	
	if (state.phase > .35 && !this.states ["Leading"]) {
		const leading = this.leading;
		
		if (leading) {
			leading.style.visibility = "";
			this.startAnimation ("Leading", {direction: 1, rate: .01});
			
		}
		
	}
	
};

IntroAnimation.prototype.animateLeading = function () {
	const state = this.updatedState ("Leading");
	let t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) * .6;
	
	const fadingElements = this.fadingLeadingElements;
	
	const fadeRange = .5;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	for (let i = fadingElements.length; i--;) {
		const fadingElement = fadingElements [i];
		
		const opacity = (t - i * distributionRange) / fadeRange;
		fadingElement.style.opacity = opacity < 1 ? opacity : "";
		
	}
	
	if (state.phase > .4 && !this.states ["FadeCover"]) {
		this.startAnimation ("FadeCover", {direction: 1, rate: .0175 * .75});
		
	}
	
}

IntroAnimation.prototype.animateFadeCover = function () {
	const state = this.updatedState ("FadeCover");
	let t = state.phase;
	
	this.coverAlpha = t;
	this.updateCoverAlpha ();
	
};

IntroAnimation.prototype.updateCoverAlpha = function () {
	const cover = this.cover;
	if (!cover.parentNode)
		return;
	
	const t = this.coverAlpha || 0;
	
	if (t == 0) {
		cover.style.backgroundColor = "#ffffff";
		
	} else if (t < 1) {
		// cover.style.opacity = 2 - t * 2;
		
		const viewportSize = this.getStage ().size;
		cover.style.backgroundColor = "";
		cover.style.backgroundPosition = "0 " + (t * 2400 /* viewportSize [1] */ + (1 - t) * -1200) + "px";
		
	} else {
		cover.parentNode.removeChild (cover);
		
	}
	
};

IntroAnimation.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const cover = this.cover;
	if (cover.parentNode) {
		const bodyBounds = document.body.getBoundingClientRect ();
		const coverBounds = cover.getBoundingClientRect ();
		
		cover.style.height = bodyBounds.height - (coverBounds.top - bodyBounds.top) + "px";
		
	}
	
	this.watchScrollForCover ();
	
};

//
// StoryTeaser extends SiteSection
//

export const StoryTeaser = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.StoryTeaser = StoryTeaser;

StoryTeaser.prototype = Object.create (SiteSection.prototype);

StoryTeaser.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	if (Environment.IS_IN_NEOS_EDITOR)
		return;
	
	const square = this.square = element.querySelector (".sievert-logo-square-multiply-standalone > div");
	
	const image = this.image = element.querySelector ("img");
	image.style.transformOrigin = "50% 45%";
	
	const picture = this.picture = image.parentNode;
	picture.style.position = "relative";
	picture.style.overflow = "hidden";
	
	this.addListener ("mouseover", this.mouseOver, this);
	this.addListener ("mouseout", this.mouseOut, this);
	
};

StoryTeaser.prototype.scroll = function () {
	if (this.didAppear || Environment.IS_IN_NEOS_EDITOR)
		return;
	
	SiteSection.prototype.scroll.apply (this, arguments);
	
	const viewportSize = this.getStage ().size;
	const pageBounds = this.image.getBoundingClientRect ();
	
	if (pageBounds.bottom > viewportSize [1] * .8)
		return;
	
	this.didAppear = true;
	
	this.startAppearing ();
	
};

StoryTeaser.prototype.startAppearing = function () {
	this.startAnimation ("Square", {direction: 1, rate: .02, delay: 15});
	
};

StoryTeaser.prototype.animateSquare = function () {
	let state = this.states ["Square"];
	const delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	state = this.updatedState ("Square");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	
	const square = this.square;
	if (square) {
		square.style.visibility = "";
		
		if (t < 1) {
			square.style.opacity = Math.min (1, t * 2);
			const scale = Math.pow (.75, 1 - t);
			square.style.transform = "scale(" + scale + ")";
			
		} else {
			square.style.opacity = "";
			square.style.transform = "";
			
		}
		
	}
	
};

StoryTeaser.prototype.mouseOver = function (sender) {
	this.startAnimation ("ScaleImage", {direction: 1, rate: .015 * 1.5});
	
};

StoryTeaser.prototype.mouseOut = function (sender) {
	this.startAnimation ("ScaleImage", {direction: 0, rate: .02 * 1.5});
	
};

StoryTeaser.prototype.animateScaleImage = function () {
	const state = this.updatedState ("ScaleImage");
	let t = state.phase;
	t = .5 - Math.cos (t * Math.PI) * .5;
	
	const image = this.image;
	const scale = Math.pow (1.05, t);
	
	image.style.transform = "scale(" + scale + ")";
	
};

//
// StoryTeaserAkurit extends StoryTeaser
//

export const StoryTeaserAkurit = function (context) {
	StoryTeaser.apply (this, arguments);
	
};

window.StoryTeaserAkurit = StoryTeaserAkurit;

StoryTeaserAkurit.prototype = Object.create (StoryTeaser.prototype);

StoryTeaserAkurit.prototype.takeElement = function (element) {
	StoryTeaser.prototype.takeElement.apply (this, arguments);
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		element.querySelector ("img").parentNode.style.visibility = "visible";
		
		return;
		
	}
	
	this.isReversed = element.classList.contains ("flex-row-reverse");
	
};

StoryTeaserAkurit.prototype.startAppearing = function () {
	this.startAnimation ("Appearance", {direction: 1, rate: .015});
	
};

StoryTeaserAkurit.prototype.animateAppearance = function () {
	const state = this.updatedState ("Appearance");
	let t = 1 - state.phase;
	t = .5 - Math.cos (t * Math.PI) * .5;
	t = 1 - t * t;
	
	const isMobile = this.getStage ().size [0] < 560;
	
	const angle = t * Math.PI / 180 * (90 - (isMobile ? 0 : 16));
	const extent = 1280;
	
	const v = [
		Math.cos (angle) * extent,
		Math.sin (angle) * extent
		
	];
	
	const picture = this.picture;
	picture.style.visibility = "visible";
	
	if (t < 1) {
		if (this.isReversed) {
			picture.style.clipPath = "polygon(" +
				"0 100%, " +
				"calc(" + extent + "px) 100%, " +
				"calc(" + v [0] + "px) calc(100% - " + v [1] + "px)" +
				")";
			
		} else {
			picture.style.clipPath = "polygon(" +
				"100% 0, " +
				"calc(100% - " + extent + "px) 0, " +
				"calc(100% - " + v [0] + "px) calc(" + v [1] + "px)" +
				")";
			
		}
		
	} else {
		picture.style.clipPath = "";
		
	}
	
};


//
// AnimatedBackgroundShape extends SiteSection
//

export const AnimatedBackgroundShape = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.AnimatedBackgroundShape = AnimatedBackgroundShape;

AnimatedBackgroundShape.prototype = Object.create (SiteSection.prototype);

AnimatedBackgroundShape.prototype.renderInContext = function () {};

AnimatedBackgroundShape.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	this.isRightAligned = element.firstElementChild.classList.contains ("background-shape-aligned-right");
	
};

AnimatedBackgroundShape.prototype.scroll = function () {
	const viewportSize = this.getStage ().size;
	const pageBounds = this.pageBounds;
	
	if (pageBounds.top < viewportSize [1] * .667)
		this.show ();
	else
		this.hide ();
	
};

AnimatedBackgroundShape.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	this.updateSlideLayout ();
	
};

AnimatedBackgroundShape.prototype.show = function () {
	if (this.isShown)
		return;
	this.isShown = true;
	
	this.startAnimation ("Slide", {direction: 1, rate: .01 * .75});
	
};

AnimatedBackgroundShape.prototype.hide = function () {
	if (!this.isShown)
		return;
	this.isShown = false;
	
	this.startAnimation ("Slide", {direction: 0, rate: .015 * .75});
	
};

AnimatedBackgroundShape.prototype.animateSlide = function () {
	const state = this.updatedState ("Slide");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	t = .5 - Math.cos (t * Math.PI) * .5;
	
	this.slideT = t;
	this.updateSlideLayout ();
	
};

AnimatedBackgroundShape.prototype.updateSlideLayout = function () {
	const t = this.slideT || 0;
	
	const element = this.element;
	const viewportSize = this.getStage ().size;
	
	const offset = viewportSize [0] * (t - 1);
	
	element.firstElementChild.style.transform = "translate(" + offset * (this.isRightAligned ? -1 : 1) + "px, " + offset * -.1 + "px)";
	
};

//
// ReferenceListItem extends SiteSection
//

export const ReferenceListItem = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ReferenceListItem = ReferenceListItem;

ReferenceListItem.prototype = Object.create (SiteSection.prototype);

ReferenceListItem.prototype.renderInContext = function () {};

ReferenceListItem.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const image = this.image = element.querySelector ("img");
	image.style.opacity = 0;
	
	const textElement = this.textElement = element.lastElementChild;
	textElement.style.opacity = 0;
	
};

ReferenceListItem.prototype.scroll = function () {
	if (this.isShown)
		return;
	
	const viewportSize = this.getStage ().size;
	const pageBounds = this.pageBounds;
	
	if (pageBounds.top < viewportSize [1] * .9)
		this.show ();
	
};

ReferenceListItem.prototype.show = function () {
	if (this.isShown)
		return;
	this.isShown = true;
	
	this.startAnimation ("Expansion", {direction: 1, rate: .015});
	
};

ReferenceListItem.prototype.animateExpansion = function () {
	const state = this.updatedState ("Expansion");
	let t = 1 - state.phase;
	t = 1 - t * t * t * t;
	
	const scale = Math.pow (.75, 1 - t);
	
	const image = this.image;
	image.style.transform = "scale(" + scale + ")";
	image.style.opacity = Math.min (1, t * 2);
	
	this.textElement.style.opacity = image.style.opacity;
	
};

//
// TypoAnimation extends SiteSection
//

export const TypoAnimation = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.TypoAnimation = TypoAnimation;

TypoAnimation.prototype = Object.create (SiteSection.prototype);

TypoAnimation.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const paragraphElements = element.children
	const paragraphs = this.paragraphs = new Array ();
	
	for (let i = 0; i < paragraphElements.length; i++) {
		const paragraphElement = paragraphElements [i];
		
		paragraphElement.style.visibility = "hidden";
		
		paragraphs.push (paragraphElement);
		
	}
	
	this.createCover ();
	
};

TypoAnimation.prototype.createCover = IntroAnimation.prototype.createCover;

TypoAnimation.prototype.scroll = function () {
	if (Environment.IS_IN_NEOS_EDITOR)
		return;
	
	if (!this.didAppear) {
		SiteSection.prototype.scroll.apply (this, arguments);
		
		const viewportSize = this.getStage ().size;
		const pageBounds = this.pageBounds;
		
		if (pageBounds.top > viewportSize [1] * .75)
			return;
		
		this.didAppear = true;
		
		const element = this.element;
		element.classList.remove ("invisible");
		this.setViewSize ();
		
		this.fadeNextParagraph ();
		
	}
	
	this.watchScrollForCover ();
	
};

TypoAnimation.prototype.watchScrollForCover = IntroAnimation.prototype.watchScrollForCover;

TypoAnimation.prototype.fadeNextParagraph = function () {
	const paragraphs = this.paragraphs;
	if (!paragraphs.length) {
		if (!this.states ["FadeCover"]) {
			this.startAnimation ("FadeCover", {direction: 1, rate: .0175});
			
		}
		return;
		
	}
	
	const paragraph = this.paragraph = paragraphs.shift ();
	IntroAnimation.splitElementWords (paragraph);
	this.fadingElements = paragraph.querySelectorAll ("span");	
	
	this.startAnimation ("FadeElements", {phase: 0, direction: 1, rate: .01, delay: 0});
	
};

TypoAnimation.prototype.animateFadeElements = function () {
	let state = this.states ["FadeElements"];
	const delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	this.paragraph.style.visibility = "";

	state = this.updatedState ("FadeElements");
	let t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) * .6;
	
	const fadingElements = this.fadingElements;
	
	const fadeRange = .45;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	let didCompleteFade = true;
	
	for (let i = fadingElements.length; i--;) {
		const fadingElement = fadingElements [i];
		
		const opacity = (t - i * distributionRange) / fadeRange;
		fadingElement.style.opacity = opacity < 1 ? opacity : "";
		
		if (opacity < 1)
			didCompleteFade = false;
		
	}
	
	if (didCompleteFade)
		this.fadeNextParagraph ();
	
};

TypoAnimation.prototype.animateFadeCover = IntroAnimation.prototype.animateFadeCover;
TypoAnimation.prototype.updateCoverAlpha = IntroAnimation.prototype.updateCoverAlpha;
TypoAnimation.prototype.setViewSize = IntroAnimation.prototype.setViewSize;


//
// AnimatedSievertShape extends SiteSection
//

export const AnimatedSievertShape = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.AnimatedSievertShape = AnimatedSievertShape;

AnimatedSievertShape.prototype = Object.create (SiteSection.prototype);

AnimatedSievertShape.prototype.renderInContext = function () {};

AnimatedSievertShape.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const maskedImage = this.maskedImage = element.firstElementChild;
	maskedImage.style.visibility = "hidden";
	this.maskOrigin = [0, 0];
	
	this.shifting =
		maskedImage.classList.contains ("background-shape-shifted-up") ? "up" :
		maskedImage.classList.contains ("background-shape-shifted-down") ? "down" :
		"center";
	
};

AnimatedSievertShape.prototype.forceScroll = true;

AnimatedSievertShape.prototype.scroll = function () {
	if (this.isShown)
		return;
	
	const viewportSize = this.getStage ().size;
	const pageBounds = this.pageBounds;
	
	const shifting = this.shifting;
	const boundary =
		shifting == "up" ? viewportSize [0] * .66 : 
		shifting == "down" ? viewportSize [0] * .33 : 
		viewportSize [0] * .5;
	
	if (pageBounds.top - boundary * .5 < viewportSize [1] * 1)
		this.show ();
	else
		this.hide ();
	
};

AnimatedSievertShape.prototype.show = function () {
	if (this.isShown)
		return;
	this.isShown = true;
	this.forceScroll = false;
	
	this.maskedImage.style.visibility = "";
	this.startAnimation ("Appearance", {direction: 1, rate: .01 * .75});
	
};

AnimatedSievertShape.prototype.hide = function () {
	if (!this.isShown)
		return;
	this.isShown = false;
	
	this.startAnimation ("Appearance", {direction: 0, rate: .015 * .75});
	
};

AnimatedSievertShape.prototype.animateAppearance = function () {
	const state = this.updatedState ("Appearance");
	let t = 1 - state.phase;
	t = 1 - t * t;
	
	const maskedImage = this.maskedImage;
	maskedImage.style.display = "block";
	maskedImage.style.opacity = t;
	
	if (t == 1) {
		maskedImage.style.transform = "";
		
	} else {
		maskedImage.style.transform = "translateY(" + (1 - t) * 150 + "px)";
		
	}
	
};

//
// AnimatedTubagHeaderImage extends SiteSection
//

export const AnimatedTubagHeaderImage = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.AnimatedTubagHeaderImage = AnimatedTubagHeaderImage;

AnimatedTubagHeaderImage.prototype = Object.create (SiteSection.prototype);

AnimatedTubagHeaderImage.prototype.renderInContext = function () {};

AnimatedTubagHeaderImage.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const shapeContainer = element.querySelector (".background-shape-image");
	const shapes = this.shapes = shapeContainer.children;
	
	this.inversion = shapeContainer.classList.contains ("background-shape-image--left") ? -1 : 1;
	
	this.updateMaskLayout ();
	
};

AnimatedTubagHeaderImage.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	this.updateMaskLayout ();
	
};

AnimatedTubagHeaderImage.prototype.scroll = function () {
	if (this.isShown)
		return;
	
	const viewportSize = this.getStage ().size;
	const pageBounds = this.pageBounds;
	
	if (pageBounds.top < viewportSize [1] * .8)
		this.show ();
	else
		this.hide ();
	
};

AnimatedTubagHeaderImage.prototype.show = function () {
	if (this.isShown)
		return;
	this.isShown = true;
	
	this.startAnimation ("Appearance", {direction: 1, rate: .01 * .75});
	
};

AnimatedTubagHeaderImage.prototype.hide = function () {
	if (!this.isShown)
		return;
	this.isShown = false;
	
	this.startAnimation ("Appearance", {direction: 0, rate: .015 * .75});
	
};

AnimatedTubagHeaderImage.prototype.animateAppearance = function () {
	const state = this.updatedState ("Appearance");
	let t = 1 - state.phase;
	t = 1 - t * t;
	
	this.maskT = t;
	this.updateMaskLayout ();
	
};

AnimatedTubagHeaderImage.prototype.updateMaskLayout = function () {
	const t = this.maskT || 0;
	const viewportSize = this.getStage ().size;
	
	const transparentCover = this.transparentCover;
	const opaqueCover = this.opaqueCover;
	
	
	const fadingElements = this.shapes;
	
	const fadeRange = .85;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	let t_;
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 0 * distributionRange) / fadeRange));
	t_ = 1 - t_ * t_ * t_
	fadingElements [1].style.transform = "translateX(" + (viewportSize [0] * (t_ - 1) * this.inversion) + "px)";
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 1 * distributionRange) / fadeRange));
	t_ = 1 - t_ * t_ * t_
	fadingElements [0].style.transform = "translateX(" + (viewportSize [0] * (t_ - 1) * this.inversion) + "px)";
	
};

//
// HeaderAnimationAkurit extends SiteSection
//

export const HeaderAnimationAkurit = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.HeaderAnimationAkurit = HeaderAnimationAkurit;

HeaderAnimationAkurit.prototype = Object.create (SiteSection.prototype);

HeaderAnimationAkurit.prototype.renderInContext = function () {};

HeaderAnimationAkurit.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	let variant = element.className.split ("background-shape-container-header-") [1];
	if (!variant)
		return;
	
	variant = this.variant = variant.substr (0, 1);
	
	const backgroundShape = this.backgroundShape = element.querySelector (".background-shape");
	
	const loader = LoaderSystem.getSharedInstance ();
	const job = loader.jobForPathOfClass (
		"/_Resources/Static/Packages/Wysiwyg.Sievert.Akurit/Images/background-shapes/header-" + variant + ".svg",
		DataLoaderJob,
		true
		
	);
	
	job.addListener ("complete", this.completeSVG, this);
	
	loader.enqueueJob (job);
	
};

HeaderAnimationAkurit.prototype.completeSVG = function (job) {
	const data = job.data;
	
	const svg = this.svg = new DOMParser ().parseFromString (data, "image/svg+xml");
	
	svg.documentElement.removeAttribute ("width");
	svg.documentElement.setAttribute ("height", "100%");
	svg.documentElement.setAttribute ("preserveAspectRatio", "xMaxYMid slice");
	
	const path = svg.querySelector ("path");
	const pathContainer = path.parentNode;
	
	pathContainer.removeChild (path);
	const ds = path.getAttribute ("d").replace (/z./g, " Z ").split (" Z ");
	
	const paths = this.paths = new Array ();
	
	for (let i = ds.length; i--;) {
		const d = ds [i];
		
		const path = document.createElementNS ("http://www.w3.org/2000/svg", "path");
		path.setAttribute ("d", d);
		path.setAttribute ("opacity", "0");
		// path.setAttribute ("fill", "#000000");
		
		pathContainer.appendChild (path);
		paths.push (path);
		
	}
	
	const backgroundShape = this.backgroundShape;
	backgroundShape.appendChild (svg.documentElement);
	
	this.startAnimation ("FadePaths", {direction: 1, rate: .02, delay: 45});
	
};

HeaderAnimationAkurit.prototype.interleave = 0;

HeaderAnimationAkurit.prototype.animateFadePaths = function () {
	let state = this.states ["FadePaths"];
	const delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	state = this.updatedState ("FadePaths");
	let t = state.phase;
	
	const fadingElements = this.paths;
	
	const fadeRange = .45;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	let didCompleteFade = true;
	
	for (let i = fadingElements.length; i--;) {
		const fadingElement = fadingElements [i];
		
		const opacity = Math.max (0, Math.min (1, (t - i * distributionRange) / fadeRange));
		fadingElement.setAttribute ("opacity", opacity);
		
		if (opacity < 1)
			didCompleteFade = false;
		
	}
	
	const svg = this.svg;
	const backgroundShape = this.backgroundShape;
	
	// backgroundShape.innerHTML = svg.documentElement.outerHTML;
	
};

//
// SustainabiltityLoop extends SiteSection
//

export const SustainabiltityLoop = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.SustainabiltityLoop = SustainabiltityLoop;

SustainabiltityLoop.prototype = Object.create (SiteSection.prototype);

SustainabiltityLoop.prototype.renderInContext = function () {};

SustainabiltityLoop.prototype.takeElement = async function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const isHeader = element.classList.contains ("header");
	
	const video = this.video = document.createElement ("video");
	video.muted = true;
	video.playsInline = true;
	video.autoplay = true;
	video.loop = true;
	video.src = "/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Images/sustainability/" +
		(Environment.USE_HIGH_RESOLUTION ? "loop-composit.mp4" : "loop-composit-mobile.mp4");
	
	element.appendChild (video);
	
};

SustainabiltityLoop.prototype.awake = async function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	const video = this.video;
	try {
		await video.play ();
		
	} catch (exception) {
		
	}
	
};

SustainabiltityLoop.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	const video = this.video;
	video.pause ();
	video.currentTime = 0;
	
};
