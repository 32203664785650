import * as Environment from "..//base/Environment.js";

import {Site, SiteSection} from "./Site.js";

//
// ApplicationFinder extends SiteSection
//

export const ApplicationFinder = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ApplicationFinder = ApplicationFinder;

ApplicationFinder.prototype = Object.create (SiteSection.prototype);

ApplicationFinder.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const formElement = element.querySelector (".application-finder-form");
	const selectButton = this.selectButton = formElement.querySelector (".select-button");
	const selectElement = formElement.querySelector (".application-selector");
	
	/*
	selectButton.addEventListener ("click", function (event) {
		event = document.createEvent ("MouseEvents");
		event.initMouseEvent ("mousedown", true, true, window);
		selectElement.dispatchEvent (event);
		
		selectElement.click ();
		
	});
	*/
	
	selectElement.addEventListener ("change", function (event) {
		this.changeApplication (selectElement.value, true);
		Site.sharedInstance.resize ();
		
	}.bind (this));
	
	const resultsElement = this.resultsElement = element.querySelector (".application-finder-results");
	
	if (window.location.hash.length > 0 && window.location.hash.startsWith ("#id-")) {
		this.changeApplication (window.location.hash.substring (4));
		
		Array.from (selectElement.children).forEach (option => {
			option.selected = this.applicationId === option.value;
			
		});
		
	}
	
};

ApplicationFinder.prototype.changeApplication = function (applicationId, animated) {
	const lastApplicationId = this.applicationId;
	if (lastApplicationId == applicationId)
		return;
	
	this.applicationId = applicationId;

	const element = this.element;
	
	let optionElement = element.querySelector ("option[value=\"" + applicationId + "\"]");
	if (!optionElement)
		optionElement = element.querySelector ("option");
	
	this.selectButton.textContent = optionElement.textContent;
	
	const lastApplicationNode = this.lastApplicationNode = this.applicationNode;
	const applicationNode = this.applicationNode = element.querySelector ("#id-" + applicationId);
	
	const newURL = location.href.split ("#") [0] + "#id-" + applicationId;
	window.history.replaceState (
		null, null, newURL
		
	);
	
	const resultsElement = this.resultsElement;
	let applicationNodeBounds;
	
	if (animated) {
		if (lastApplicationNode)
			lastApplicationNode.classList.add ("hidden");
		
		if (applicationNode) {
			applicationNode.style.position = "";
			applicationNode.classList.remove ("hidden");
			applicationNodeBounds = applicationNode.getBoundingClientRect ();
			
		}
		
		this.targetHeight = resultsElement.offsetHeight;
		
		if (lastApplicationNode) {
			lastApplicationNode.classList.remove ("hidden");
			lastApplicationNode.style.position = "";
			
		}
		
		if (applicationNode)
			applicationNode.classList.add ("hidden");
		
		this.startHeight = resultsElement.offsetHeight;
		
		if (lastApplicationNode) {
			lastApplicationNode.style.position = "absolute";
			lastApplicationNode.style.width = "100%";
		
		}
		
		if (applicationNode) {
			applicationNode.classList.remove ("hidden");
			applicationNode.style.position = "absolute";
			applicationNode.style.width = "100%";
			
		}
		
		const deltaHeight = Math.abs (this.targetHeight - this.startHeight);
		
		this.startAnimation ("Expansion", {direction: 1, phase: 0, rate: Math.max (.04, Math.min (.08, 800 / deltaHeight * .06))});
		
	} else {
		this.stopAnimation ("Expansion");
		resultsElement.style.height = "";
		
		if (lastApplicationNode)
			lastApplicationNode.classList.add ("hidden");
		
		if (applicationNode) {
			applicationNode.classList.remove ("hidden");
			applicationNode.style.position = "";
			applicationNode.style.opacity = "";
			
			applicationNodeBounds = applicationNode.getBoundingClientRect ();
			
		}
		
	}
	
		if (applicationNodeBounds) {
			const stage = this.getStage ();
			
			if (applicationNodeBounds.top > stage.size [1] * .75) {
				stage.scrollToOffset (
					applicationNodeBounds.top - document.body.getBoundingClientRect ().top - stage.size [1] * .75
					
				);
				
			}
			
		}
		
};

ApplicationFinder.prototype.animateExpansion = function () {
	const state = this.updatedState ("Expansion");
	let t = state.phase;
	t = .5 - Math.cos (t * Math.PI) * .5;
	const t_ = 1 - t;
	
	const resultsElement = this.resultsElement;
	
	const lastApplicationNode = this.lastApplicationNode;
	const applicationNode = this.applicationNode;
	
	if (t < 1) {
		const height = Math.round (
			this.startHeight * t_ + this.targetHeight * t
			
		);
		resultsElement.style.height = height + "px";
		
		if (lastApplicationNode && applicationNode) {
			lastApplicationNode.style.opacity = Math.max (0, Math.min (1, t_ * 1.5 - .5));
			applicationNode.style.opacity = Math.max (0, Math.min (1, (t - .25) / .75));
		
		} else {
			if (lastApplicationNode)
				lastApplicationNode.style.opacity = "";
			if (applicationNode)
				applicationNode.style.opacity = "";
			
		}
		
	} else {
		if (lastApplicationNode) {
			lastApplicationNode.classList.add ("hidden");
			lastApplicationNode.style.position = "";
			
		}
		if (applicationNode) {
			applicationNode.style.position = "";
			applicationNode.style.opacity = "";
			
		}
		
		resultsElement.style.height = "";
		
		Site.sharedInstance.scroll ();
		
	}
	
};
