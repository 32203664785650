import * as Environment from "../base/Environment.js";
import {Dispatcher} from "../base/Base.js";
import {RenderContext} from "../base/Display.js";

import {Site} from "../site/Site.js";

//
// LazyLoader extends Dispatcher
//

export const LazyLoader = function (element) {
	Dispatcher.apply (this, arguments);
	
	if (LazyLoader.sharedInstance) {
		// throw new Error ("Cannot instantiate. LazyLoader is singleton.");
		
	} else {
		LazyLoader.sharedInstance = this;
		
	}
	
	this.element = element = element || document;
	
	var images = this.images = Array.prototype.slice.call (
		element.querySelectorAll (Environment.IS_IE ?
			"picture.lazyload" :
			"picture.lazyload:not(.lazyload--started)"
			
		)
		
	);
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		Site.sharedInstance.addListener ("mutate", this.handleMutation, this);
		return;
		
	}
	
	for (var i = images.length; i--;) {
		var image = images [i];
		LazyLoader.initializeImage (image);
		
	}
	
};

LazyLoader.initializeImage = function (image) {
	if (Environment.IS_IE && image.classList.contains ("lazyload--started"))
		return;
	
	image.classList.add ("lazyload--started");
	
	var aspect =
		parseFloat (image.getAttribute ("height")) /
		parseFloat (image.getAttribute ("width"));
	
	if (aspect) {
		image.lastElementChild.style.height = 0;
		image.lastElementChild.style.paddingBottom = aspect * 100 + "%";
		
	}
	
	image.lastElementChild.style.visibility = "hidden";
	
};

LazyLoader.resizeImage = function (entries, observer) {
	for (let i = 0; i < entries.length; i++) {
		const entry = entries [i];
		const image = entry.target;
		
		const contentBoxSize = entry.contentBoxSize [0];
		
		const fileEntry = LazyLoader.fileEntryForContentWidth (
			image,
			contentBoxSize.inlineSize
			
		);
		// trace ("select", fileEntry.imageWidth, fileEntry.path);
		
		const img = image.lastElementChild;
		if (img.getAttribute ("src") != fileEntry.path)
			img.setAttribute ("src", fileEntry.path);
		
	}
	
};

LazyLoader.fileEntryForContentWidth = function (image, contentWidth) {
	let sourceDefinitions = image.sourceDefinitions;
	
	if (!sourceDefinitions) {
		sourceDefinitions = image.sourceDefinitions = new Array ();
		
		const sourceElements = image.querySelectorAll ("source");
		
		for (let i = sourceElements.length; i--;) {
			const sourceElement = sourceElements [i];
			const media = sourceElement.getAttribute ("media");
			
			const minWidth = parseInt (media.split ("(min-width:").pop ()) || 0;
			
			const sourceSet = sourceElement.getAttribute ("data-srcset");
			const sourceSetFileEntries = sourceElement.fileEntries = new Array ();
			
			let paths = sourceSet.split (",");
			let baseName;
			
			for (let i = paths.length; i--;) {
				const pathComponents = paths [i].trim ().split (" ");
				const path = pathComponents [0];
				
				const imageWidth = parseInt (pathComponents [pathComponents.length - 1]);
				
				sourceSetFileEntries.push ({
					path: path,
					imageWidth: imageWidth
					
				});
				
			}
			
			sourceSetFileEntries.sort (function (a, b) {
				return a.imageWidth < b.imageWidth ? 1 : -1;
				
			});
			
			sourceDefinitions.push ({
				minWidth: minWidth,
				fileEntries: sourceSetFileEntries
				
			});
			
		}
		
		sourceDefinitions.sort (function (a, b) {
			return a.minWidth > b.minWidth ? 1 : -1;
			
		});
		
	}
	
	const viewportWidth = window.innerWidth;
	let devicePixelRatio = window.devicePixelRatio || 1;
	
	if (viewportWidth < 560) // slightly reduce resolution for mobile devices
		devicePixelRatio = (devicePixelRatio * 1 + 1) / 2;
	else if (viewportWidth < 1024)
		devicePixelRatio = (devicePixelRatio * 2 + 1) / 3;
	
	for (let i = sourceDefinitions.length; i--;) {
		const sourceDefinition = sourceDefinitions [i];
		
		if (sourceDefinition.minWidth <= viewportWidth) {
			const fileEntries = sourceDefinition.fileEntries; // image.fileEntriesMap [sourceDefinition.baseName];
			
			for (let i = fileEntries.length; i--;) {
				const fileEntry = fileEntries [i];
				
				if (!i || fileEntry.imageWidth >= contentWidth * devicePixelRatio) {
					return fileEntry;
					
				}
				
			}
			
			return sourceDefinition;
			
		}
		
	}
	
	return {path:
		image.lastElementChild.getAttribute ("data-src") ||
		image.lastElementChild.getAttribute ("src")
		
	};
	
};

LazyLoader.prototype = Object.create (Dispatcher.prototype);

LazyLoader.update = function () {
	this.sharedInstance.update ();
	
};

LazyLoader.prototype.update = function () {
	var images = this.images;
	if (!(images && images.length))
		return;
	
	var viewportSize = RenderContext.getViewportSize ();
	
	var images = this.images;
	for (var i = images.length; i--;) {
		var image = images [i];
		
		try {
			var imageBounds = image.getBoundingClientRect ();
			
			// if (!imageBounds.height)
			//	continue;
			
			if (imageBounds.top < viewportSize [1] * 1.5 /* &&
				imageBounds.bottom >= 0 */) {
				images.splice (i, 1);
				
				this.loadImageAndListen (image);
				
			}
			
		} catch (exception) {
			// IE fix
			
		}
		
	}
	
};

LazyLoader.prototype.loadImageAndListen = function (image) {
	image.lastElementChild.addEventListener ("load", image.loadListener = this.completeImage.bind (this));
	
	this.loadImage (image);
	
	if (image.lastElementChild.complete)
		this.completeImage ({currentTarget: image.lastElementChild});
	
};

LazyLoader.loadImage = LazyLoader.prototype.loadImage = function (image) {
	if (Environment.IS_IN_NEOS_EDITOR) {
		function copyAttribute (attributeName) {
			var value = child.getAttribute ("data-" + attributeName);
			if (value)
				child.setAttribute (attributeName, value);
			
		}
		
		var children = image.children;
		for (var i = children.length; i--;) {
			var child = children [i];
			
			copyAttribute ("src");
			copyAttribute ("srcset");
			copyAttribute ("sizes");
			
		}
		
	} else {
		// use custom adaptive image heuristics
		
		let resizeObserver = LazyLoader.resizeObserver;
		if (!resizeObserver)
			resizeObserver = LazyLoader.resizeObserver = new ResizeObserver (LazyLoader.resizeImage);
		
		resizeObserver.observe (image);
		
		const fileEntry = LazyLoader.fileEntryForContentWidth (
			image,
			image.offsetWidth
			
		);
		
		const img = image.lastElementChild;
		img.setAttribute ("src", fileEntry.path);
		
	}
	
};

LazyLoader.prototype.completeImage = function (event) {
	var image = event.currentTarget.parentNode;
	
	image.lastElementChild.removeEventListener ("load", image.loadListener);
	
	image.lastElementChild.style.height = "";
	image.lastElementChild.style.paddingBottom = "";
	image.lastElementChild.style.visibility = "";
	
	image.classList.add ("lazyload--complete");
	
	this.dispatchEvent ("progress");
	
	if (!this.images.length)
		this.dispatchEvent ("complete");
	
};

// Neos functions

LazyLoader.prototype.handleMutation = function () {
	var element = this.element;
	var images = element.querySelectorAll ("picture.lazyload:not(.lazyload--started)");
	
	for (var i = images.length; i--;) {
		var image = images [i];
		
		image.classList.add ("lazyload--started")
		image.classList.add ("lazyload--complete");
		
		this.loadImage (image);
		
	}
	
};
