import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";

//
// SiteSection extends AnimatableSprite
//

export const SiteSection = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

SiteSection.prototype = Object.create (AnimatableSprite.prototype);

SiteSection.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
};

SiteSection.prototype.startLoading = function () {
	this.didStartLoading = true;
	
};

SiteSection.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
};

SiteSection.prototype.scroll = function (onAwake) {
	
};

SiteSection.prototype.awake = function () {
	this.isAwake = true;
	
};

SiteSection.prototype.sleep = function () {
	this.isAwake = false;
	
};

