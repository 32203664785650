import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";

import {SiteSection} from "./SiteSection.js";

//
// ScrollableTable extends SiteSection
//

export const ScrollableTable = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ScrollableTable = ScrollableTable;

ScrollableTable.prototype = Object.create (SiteSection.prototype);

ScrollableTable.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const tableContainer = this.tableContainer = element.querySelector (".table-container");
	const table = this.table = element.querySelector ("table");
	
	const progressBar = this.progressBar = element.querySelector (".scrollable-table-progress-bar");
	
	tableContainer.addEventListener ("scroll", this.scrollTableContainer.bind (this));
	
	let container = element;
	while (container = container.parentNode) {
		const accordionItem = container.accordionItem;
		if (accordionItem) {
			accordionItem.addListener ("expand", this.expandAccordion, this);
				
		}
		
	}
	
	if (Environment.FAST_PASS) {
		
	}
	
};

ScrollableTable.prototype.expandAccordion = function () {
	this.setViewSize (this.viewSize);
	
};

ScrollableTable.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const element = this.element;
	const table = this.table;
	
	element.style.overflow = "hidden";
	
	const selfWidth = element.offsetWidth;
	const tableWidth = table.offsetWidth;
	
	const maxScroll = this.maxScroll = tableWidth - selfWidth;
	const isOverflowing = maxScroll > 0;
	
	if (isOverflowing) {
		element.classList.add ("scrollable");
		
	} else {
		element.classList.remove ("scrollable");
		
	}
	
	const numIndicators = Math.ceil (tableWidth / selfWidth);
	this.setNumIndicators (numIndicators);
	
	this.scrollTableContainer ();
	
};

ScrollableTable.prototype.setNumIndicators = function (numIndicators) {
	if (this.numIndicators == numIndicators)
		return;
	
	this.numIndicators = numIndicators;
	
	const progressBar = this.progressBar;
	
	while (progressBar.children.length > numIndicators)
		progressBar.removeChild (progressBar.lastElementChild);
	
	while (progressBar.children.length < numIndicators) {
		const indicator = document.createElement ("div");
		indicator.classList.add ("scrollable-table-progress-bar-item");
		
		indicator.index = progressBar.children.length;
		indicator.addEventListener ("click", this.clickIndicator.bind (this));
		
		progressBar.appendChild (indicator);
		
	}
	
};

ScrollableTable.prototype.scrollTableContainer = function (event) {
	const tableContainer = this.tableContainer;
	const scrollLeft = tableContainer.scrollLeft;
	const maxScroll = this.maxScroll;
	
	const element = this.element;
	if (scrollLeft > 0)
		element.classList.add ("scrollable-left");
	else
		element.classList.remove ("scrollable-left");
	
	if (scrollLeft < maxScroll - 1)
		element.classList.add ("scrollable-right");
	else
		element.classList.remove ("scrollable-right");
	
	const numIndicators = this.numIndicators;
	const activeIndex = Math.max (0, Math.min (numIndicators - 1,
		Math.floor (scrollLeft / maxScroll * (numIndicators + 0))
		
	));
	
	const progressBar = this.progressBar;
	const indicators = progressBar.children;
	for (let i = indicators.length; i--;) {
		const indicator = indicators [i];
		if (i == activeIndex)
			indicator.classList.add ("active");
		else
			indicator.classList.remove ("active");
		
	}
	
};

ScrollableTable.prototype.clickIndicator = function (event) {
	const indicator = event.currentTarget;
	const index = indicator.index;
	
	const tableContainer = this.tableContainer;
	const maxScroll = this.maxScroll;
	
	const progressBar = this.progressBar;
	const indicators = progressBar.children;
	
	const startScroll = this.startScroll = tableContainer.scrollLeft;
	const targetScroll = this.targetScroll = Math.max (0, Math.min (maxScroll,
		Math.round (maxScroll * index / (indicators.length - 1))
		
	));
	this.startAnimation ("ScrollTable", {direction: 1, rate: .05, phase: 0});
	
};

ScrollableTable.prototype.animateScrollTable = function (event) {
	const state = this.updatedState ("ScrollTable");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	t = .5 - Math.cos (t * Math.PI) * .5;
	const t_ = 1 - t;
	
	const tableContainer = this.tableContainer;
	tableContainer.scrollLeft = Math.round (
		this.startScroll * t_ + this.targetScroll * t
		
	);
	
};
