import * as Environment from "../base/Environment.js";

import {Dispatcher} from "./Base.js";

//
// class LoaderSystem extends Dispatcher
//

export const LoaderSystem = function () {
	Dispatcher.apply (this, arguments);
	
	this.queue = new Array ();
	this.queueMap = new Object ();
	
	this.numCurrentJobs = 0;
	
	this.cache = LoaderCache.getSharedInstance ();
	
};

LoaderSystem.prototype = Object.create (Dispatcher.prototype);

LoaderSystem.MAX_NUM_CONCURRENT_JOBS = 2;

LoaderSystem.prototype.jobForPathOfClass = function (path, constructor, skipCache) {
	var job = skipCache ? undefined : this.cache.cachedJobForPath (path);
	
	if (!job) {
		job = new constructor (path);
		
		if (skipCache)
			job.skipCache = true;
		else
			this.cache.storeJob (job);
		
	}
	return job;
	
};

LoaderSystem.prototype.enqueueJob = function (job) {
	if (job.state == LoaderJob.STATE_DONE) {
		job.dispatchEvent ("complete");
		
	} else {
		var path = job.path;
		
		var inQueue = this.queueMap [path];
		if (!inQueue || inQueue.skipCache) {
			this.queue.push (job);
			this.queueMap [path] = job;
			
			this.processQueue ();
			
		}
		
	}
	
};

LoaderSystem.prototype.processQueue = function () {
	if (!this.processTimerHandle) {
		var that = this;
		
		var processQueueHandler = this.processQueueHandler;
		if (!processQueueHandler) {
			processQueueHandler = this.processQueueHandler = function () {
				that._processQueue ();
				
			};
			
		}
		this.processTimerHandle = window.setInterval (
			processQueueHandler, 1000. / 30.
			
		);
		
	}
	
};

LoaderSystem.prototype._processQueue = function () {
	var queue = this.queue;
	if (!queue.length) {
		window.clearInterval (this.processTimerHandle);
		delete this.processTimerHandle;
		
		return;
		
	}
	
	var i, job;
	for (i = queue.length; i--;) {
		job = queue [i];
		if (job.priorityClosure)
			job.priority = job.priorityClosure ();
		
	}
	queue.sort (function (a, b) {
		return a.priority > b.priority ? -1:
			a.priority == b.priority ? 0 : 1;
		
	});
	
	var numProcessedJobs = 0;
	for (i = 0; i < queue.length && numProcessedJobs < LoaderSystem.MAX_NUM_CONCURRENT_JOBS; numProcessedJobs++) {
		job = queue [i];
		
		if (!job.state) {
			job.addListener ("complete", this.finishJob, this);
			job.addListener ("ioError", this.finishJob, this);
			
			if (LoaderSystem.ENABLE_LOG)
				console.log ("now loading", job.toString (), "priority: " + job.priority);
			this.numCurrentJobs++;
			job.load ();
			
		} else if (job.state == LoaderJob.STATE_DONE) {
			queue.splice (i, 1);
			continue;
			
		}
		
		if (job == queue [i])
			i++;
		
	}
	
	if (LoaderSystem.ENABLE_LOG)
		console.log ("num concurrent jobs", this.numCurrentJobs ,"remaining queue", queue.length);
	
	for (var i = LoaderSystem.MAX_NUM_CONCURRENT_JOBS; i < queue.length; i++)
		this.cancelJob (queue [i]);
	
};

LoaderSystem.prototype.flushQueue = function () {
	var queue = this.queue;
	while (queue.length)
		this._processQueue ();
	
};

LoaderSystem.prototype.cancelJob = function (job) {
	job.removeListener ("complete", this.finishJob, this);
	job.removeListener ("ioError", this.finishJob, this);
	
	if (job.state == LoaderJob.STATE_BUSY)
		job.cancel ();
	
};

LoaderSystem.prototype.finishJob = function (job) {
	this.numCurrentJobs--;
	this.removeJob (job);
	
	if (LoaderSystem.ENABLE_LOG)
		console.log ("* finish", job, "num concurrent jobs", this.numCurrentJobs);
	
	this.dispatchEvent ("progress");
	if (!this.numCurrentJobs && !this.queue.length)
		this.dispatchEvent ("complete");
	
};

LoaderSystem.prototype.removeJob = function (job) {
	delete this.queueMap [job.path];
	
	this.cancelJob (job);
	
	var queue = this.queue;
	for (var i = queue.length; i--;) {
		if (queue [i] === job) {
			queue.splice (i, 1);
			
		}
		
	}
	
};

//
// class LoaderCache extends Dispatcher
//

var LoaderCache = function () {
	Dispatcher.apply (this, arguments);
	
	this.items = new Array ();
	this.itemMap = new Object ();
	
};

LoaderCache.prototype = Object.create (Dispatcher.prototype);

LoaderCache.CAPACITY = 32 * 4;

LoaderCache.accessCounter = 0;

LoaderCache.prototype.storeJob = function (job) {
	var keyPath = job.path;
	
	if (this.itemMap [keyPath]) {
		// console.log ("warning. key path " + keyPath + " already cached.");
		return;
		
	}
	
	job.addListener ("destroy", this.destroyResult, this);
	
	var cachedItem = {
		job: job,
		lastAccessCount: LoaderCache.accessCounter
		
	};
	this.items.push (cachedItem);
	this.itemMap [keyPath] = cachedItem;
	
	this.flushWithLimit (LoaderCache.CAPACITY, 1);
	
};

LoaderCache.prototype.flush = function () {
	this.flushWithLimit (0, 0);
	
};

LoaderCache.prototype.flushWithLimit = function (limit, tail) {
	var items = this.items;
	var numCachedObjects = items.length;
	
	if (numCachedObjects > limit) {
		items.sort (function (a, b) {
			return a.lastAccessCount > b.lastAccessCount ? 1 : -1;
			
		});
		
		for (var i = numCachedObjects; i-- > tail && numCachedObjects > limit;) {
			var item = items [i];
			if (item.job.retainCount > 1) {
				/* console.log ("sorry. won't dispose now, item " + item.job + " is retained.");
				if (!i)
					console.log ("warning. cache is completely retained.");
				*/
				
			} else {
				var job = item.job;
				// console.log ("removing", job, "from cache");
				
				delete this.itemMap [job.path];
				this.items.splice (i, 1);
				
				numCachedObjects--;
				
			}
			
		}
		
	}
	
};

LoaderCache.prototype.destroyResult = function (job) {
	delete this.itemMap [job.path];
	
	var items = this.items;
	for (var i = items.length; i--;) {
		if (items [i].job === job)
			items.splice (i, 1);
		
	}
	
};

LoaderCache.prototype.cachedJobForPath = function (keyPath) {
	var item = this.itemMap [keyPath];
	if (item) {
		item.lastAccessCount = LoaderCache.accessCount++;
		return item.job;
		
	} else {
		return undefined;
		
	}
	
};

//
// class LoaderJob extends Dispatcher
//

export const LoaderJob = function (path) {
	Dispatcher.apply (this, arguments);
	
	this.path = path;
	this.state = LoaderJob.STATE_IDLE;
	
	this.retainCount = 0;
	
};

LoaderJob.prototype = Object.create (Dispatcher.prototype);

LoaderJob.STATE_IDLE = 0;
LoaderJob.STATE_BUSY = 1;
LoaderJob.STATE_DONE = 2;

LoaderJob.prototype.priority = 0;

LoaderJob.prototype.load = function () {
	if (this.state)
		throw new Error ("cannot load " + this + ", state is " + state + " already.");
	
	// trace ("- now loading", this.path, ".");
	this.state = LoaderJob.STATE_BUSY;
	
};

LoaderJob.prototype.completeLoading = function (object) {
	this.state = LoaderJob.STATE_DONE;
	
	/*
	var that = this;
	window.setTimeout (function () {
		that.dispatchEvent ("complete");
		
	}, 1000);
	*/
	
	this.dispatchEvent ("complete");
	
};

LoaderJob.prototype.cancel = function () {
	if (this.state != LoaderJob.STATE_BUSY)
		throw new Error ("cannot cancel " + this + ", state is " + state + ".");
	
	this.state = LoaderJob.STATE_IDLE;
	
};

LoaderJob.prototype.retain = function () {
	this.retainCount++;
	
};

LoaderJob.prototype.release = function () {
	this.retainCount--;
	
	if (this.retainCount < 0)
		throw new Error ("cannot release " + this + ", retain count is " + this.retainCount + ".");
	else if (!this.retainCount)
		this.dispatchEvent ("destroy");
	
};

LoaderJob.prototype.toString = function () {
	return "[LoaderJob " + this.path + "]";
	
};

//
// DataLoaderJob extends LoaderJob
//

export const DataLoaderJob = function (path) {
	LoaderJob.apply (this, arguments);
	
};

DataLoaderJob.prototype = Object.create (LoaderJob.prototype);

DataLoaderJob.prototype.loadAsynchronously = true;
DataLoaderJob.prototype.method = "GET";

DataLoaderJob.prototype.load = function () {
	LoaderJob.prototype.load.apply (this, arguments);
	
	if (Environment.IS_IE && Environment.IE_VERSION <= 9 && this.useIECORS && window.XDomainRequest) {
		this.loadUsingIECORS ();
		return;
		
	}
	
	if (!window.XMLHttpRequest) {
		var activeXObjectIDs = ["Microsoft.XMLHTTP", "Msxml2.XMLHTTP", "Msxml2.XMLHTTP.4.0"];
		for (var i = activeXObjectIDs.length; i--;) {
			try {
				new ActiveXObject (activeXObjectIDs [i]);
				window.XMLHttpRequest = function () {
					return new ActiveXObject (activeXObjectIDs [i]);
					
				};
				break;
				
			} catch (error) {}
			
		}
		
	}
	var request = this.request = new XMLHttpRequest ();
	
	var that = this;
	request.onreadystatechange = function () {
		that.onStateChange ();
		
	};
	
	try {
		if (request.overrideMimeType) {
			var dataMimeType = this.dataMimeType;
			if (dataMimeType)
				request.overrideMimeType (dataMimeType);
			
		}
		
		var responseType = this.responseType;
		if (responseType)
			request.responseType = responseType;
		
		request.open (this.method, this.path, this.loadAsynchronously);
		
		var serial = null;
		
		var parameters = this.parameters || null;
		if (parameters) {
			request.setRequestHeader ("Content-type", "application/x-www-form-urlencoded");
			
			serial = "";
			for (var key in parameters) {
				if (serial.length)
					serial += "&";
				
				serial += encodeURIComponent (key) + "=" + encodeURIComponent (parameters [key]);
				
			}
			
		}
		this.willSendRequest = true;
		request.send (serial);
		this.willSendRequest = false;
		
	} catch (exception) {
		 this.dispatchEvent ("ioError");
		
	}
	
};


DataLoaderJob.prototype.returnResponseText = true;

DataLoaderJob.prototype.loadUsingIECORS = function () {
	var request = new XDomainRequest ();
	
	try {
		request.open (this.method, this.path);
		
		var serial;
		
		var parameters = this.parameters || null;
		if (parameters) {
			serial = "";
			for (var key in parameters) {
				if (serial.length)
					serial += "&";
				
				serial += encodeURIComponent (key) + "=" + encodeURIComponent (parameters [key]);
				
			}
			
		}
		
		var that = this;
		
		request.ontimeout = function () {
			that.dispatchEvent ("ioError");
			
		};
		request.onerror = function () {
			that.dispatchEvent ("ioError");
			
		};
		request.onload = function () {
			var data = that.data = request.response || (that.returnResponseText ? request.responseText : request.responseXML);
			that.completeLoading (data);
			
		};
		
		this.willSendRequest = true;
		request.send (serial);
		this.willSendRequest = false;
		
	} catch (exception) {
		this.dispatchEvent ("ioError");
		
	}
	
};

DataLoaderJob.prototype.onStateChange = function () {
	var request = this.request;
	
	if (!this.willSendRequest && request.readyState == 4) {
		if (!request.status || request.status == 200) {
			var data = this.data = request.response || (this.returnResponseText ? request.responseText : request.responseXML);
			this.completeLoading (data);
			
		} else {
			console.log ("error while loading " + this.path +
				", http status " + request.status);
			// this.completeLoading (undefined);
			this.dispatchEvent ("ioError");
			
		}
		
	}
	
};

//
// JSONLoaderJob extends DataLoaderJob
//

export const JSONLoaderJob = function (path) {
	DataLoaderJob.apply (this, arguments);
	
};

JSONLoaderJob.prototype = Object.create (DataLoaderJob.prototype);

JSONLoaderJob.prototype.dataMimeType = "application/json";

JSONLoaderJob.prototype.completeLoading = function (data) {
	try {
		this.data = JSON.parse (data);
		
	} catch (exception) {
		
	}
	LoaderJob.prototype.completeLoading.apply (this, arguments);
	
};
