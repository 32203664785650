import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";

import {Navigation} from "./Navigation.js";
import {SiteSection} from "./SiteSection.js";
import {Gallery} from "./GallerySection.js";

//
// ColourSelector extends SiteSection
//

export const ColourSelector = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ColourSelector = ColourSelector;

ColourSelector.prototype = Object.create (SiteSection.prototype);

ColourSelector.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	element.parentNode.style.overflow = "visible";
	
	const contentElement = this.contentElement = element.firstElementChild;
	
	const mainColours = this.mainColours = this.attachSprite (MainColours);
	mainColours.takeElement (element.querySelector (".colour-selector-main-colours-slider"));
	mainColours.addListener ("select", this.selectMainColour, this);
	
	const subColours = this.subColours = this.attachSprite (SubColours);
	subColours.takeElement (element.querySelector (".colour-selector-sub-colours-list"));
	subColours.addListener ("select", this.selectSubColour, this);
	
	const colourDetail = this.colourDetail = this.attachSprite (ColourDetail);
	colourDetail.takeElement (element.querySelector (".colour-selector-detail"));
	
	
	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			mainColours.setActiveColour (mainColours.colours [0]);
			
		}.bind (this), 250);
		window.setTimeout (function () {
			subColours.setActiveColour (subColours.colours [0]);
			
		}.bind (this), 500);
		
		window.setTimeout (function () {
			this.showOverlay ("plastering");
			// this.showOverlay ("facades");
			
		}.bind (this), 750);
		window.setTimeout (function () {
			this.closeOverlay ();
			
		}.bind (this), 2000);
		*/
		
	}
	
};

ColourSelector.prototype.selectMainColour = function (mainColours) {
	// const element = this.element;
	
	// element.style.height = "";
	// const startHeight = element.offsetHeight;
	
	const mainColour = mainColours.activeColour;
	
	const subColours = this.subColours;
	subColours.takeColours (mainColour.subColours);
	
	const colourDetail = this.colourDetail;
	colourDetail.takeColour (undefined);
	
	// const targetHeight = element.offsetHeight;
	
	// this.startHeightAnimation (startHeight, targetHeight);
	
};

ColourSelector.prototype.selectSubColour = function (subColours) {
	const subColour = subColours.activeColour;
	
	const colourDetail = this.colourDetail;
	colourDetail.takeColour (subColour);
	
};

ColourSelector.prototype.cloneElement = function () {
	const element = this.element;
	
	let clonedElement = this.clonedElement;
	if (clonedElement)
		clonedElement.parentNode.removeChild (clonedElement);
	
	clonedElement = this.clonedElement = element.cloneNode (true);
	
	clonedElement.style.position = "absolute";
	clonedElement.style.zIndex = "1";
	
	clonedElement.style.width = element.offsetWidth + "px";
	clonedElement.style.background = "#ffffff";
	
	element.parentNode.insertBefore (clonedElement, element);
	
};

ColourSelector.prototype.startHeightAnimation = function (startHeight, targetHeight) {
	this.startHeight = startHeight;
	this.targetHeight = targetHeight;
	
	const element = this.element;
	const elementBounds = element.getBoundingClientRect ();
	
	const stageSize = this.getStage ().size;
	
	const scrollingElement = this.scrollingElement = document.scrollingElement || document.body;
	const startScroll = this.startScroll = scrollingElement.scrollTop;
	
	let targetScroll;
	const paddingTop = 100;
	const paddingBottom = 30 + 30;
	
	if (elementBounds.top < paddingTop) {
		targetScroll = startScroll + (elementBounds.top - paddingTop);
		
	} else if (elementBounds.bottom > stageSize [1] - paddingBottom) {
		targetScroll = startScroll + (elementBounds.bottom - (stageSize [1] - paddingBottom));
		
	}
	this.targetScroll = targetScroll;
	
	this.startAnimation ("Height", {direction: 1, rate: .03, phase: 0});
	
	
};

ColourSelector.prototype.animateHeight = function () {
	const state = this.updatedState ("Height");
	let t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) * .5;
	const t_ = 1 - t;
	
	const element = this.element;
	element.style.display = "";
	
	const clonedElement = this.clonedElement;
	const overlay = this.overlay;
	
	const startHeight = this.startHeight;
	const targetHeight = this.targetHeight;
	
	if (t < 1) {
		const height = startHeight * t_ + targetHeight * t;
		
		// element.style.overflow = "hidden";
		element.style.clipPath = "inset(0 -100vw)";
		element.style.height = height + "px";
		
		if (clonedElement)
			clonedElement.style.opacity = Math.max (0, 1 - state.phase * 3);
		
		if (overlay) {
			overlay.element.style.height = Math.max (startHeight, targetHeight) + "px";
			overlay.element.style.opacity = Math.min (1, state.phase * 2);
			
		}
		
	} else {
		if (overlay) {
			this.contentElement.style.display = "none";
			overlay.element.style.position = "";
			overlay.element.style.height = "";
			
		}
		if (targetHeight == 0) {
			element.style.display = "none";
			
		} else {
			element.style.overflow = "";
			element.style.height = "";
			
		}
		
		if (clonedElement) {
			clonedElement.parentNode.removeChild (clonedElement);
			this.clonedElement = undefined;
			
		}
		
	}
	
	if (targetHeight) {
		const startScroll = this.startScroll;
		const targetScroll = this.targetScroll;
		
		if (!isNaN (targetScroll)) {
			const scrollTop = startScroll * t_ + targetScroll * t;
			const scrollingElement = this.scrollingElement;
			
			scrollingElement.scrollTop = scrollTop;
			
			if (t == 1 && startScroll > targetScroll) {
				Navigation.sharedInstance.slideIn ();
				
			}
			
		}
		
	}
	
};

ColourSelector.prototype.showOverlay = function (key) {
	let overlayMap = this.overlayMap;
	if (!overlayMap)
		overlayMap = this.overlayMap = new Object ();
	
	const lastOverlay = this.overlay;
	if (lastOverlay)
		lastOverlay.parentNode.removeChild (lastOverlay);
	
	const element = this.element;
	
	const startHeight = element.offsetHeight;
	
	let overlay = overlayMap [key];
	if (!overlay) {
		const overlayElement = element.querySelector (".colour-selector-overlays > .colour-selector-overlay-" + key);
		
		overlay = overlayMap [key] = this.attachSprite (ColourOverlay);
		overlay.takeElement (overlayElement);
		
	}
	
	this.overlay = overlay;
	overlay.element.style.position = "relative";
	element.insertBefore (overlay.element, element.firstChild);
	
	overlay.takeColour (this.colourDetail.colour);
	overlay.resize ();
	
	const contentElement = this.contentElement;
	contentElement.style.display = "none";
	const targetHeight = element.offsetHeight;
	
	contentElement.style.display = "";
	overlay.element.style.position = "absolute";
	
	this.startHeightAnimation (startHeight, targetHeight);
	
};

ColourSelector.prototype.closeOverlay = function () {
	const element = this.element;
	
	const startHeight = element.offsetHeight;
	
	const overlay = this.overlay;
	this.overlay = undefined;
	
	this.clonedElement = overlay.element;
	
	const contentElement = this.contentElement;
	contentElement.style.display = "";
	overlay.element.style.position = "absolute";

	const targetHeight = element.offsetHeight;
	
	this.startHeightAnimation (startHeight, targetHeight);
	
};

//
// Colour
//

const Colour = function (element, isSubColour) {
	this.element = element;
	
	this.label = element.querySelector (".colour-selector-label").textContent;
	
	const backgroundColour = this.backgroundColour = element.querySelector (".colour-selector-field").style.backgroundColor;
	const colourComp = backgroundColour.split (",");
	const rgb = this.rgb = [
		parseInt (colourComp [0].substr (4)),
		parseInt (colourComp [1]),
		parseInt (colourComp [2])
		
	];
	
	let labAttribute = element.getAttribute ("data-lab");
	let lab;
	
	if (labAttribute) {
		labAttribute = labAttribute.split (",");
		lab = [
			parseFloat (labAttribute [0]),
			parseFloat (labAttribute [1]),
			parseFloat (labAttribute [2])
			
		];
		
	} else {
		let r = rgb [0] / 255,
			g = rgb [1] / 255,
			b = rgb [2] / 255,
			x, y, z;
		
		r = (r > 0.04045) ? 
			Math.pow ((r + 0.055) /1.055, 2.4) :
			r / 12.92;
		g = (g > 0.04045) ?
			Math.pow ((g + 0.055) / 1.055, 2.4) :
			g / 12.92;
		b = (b > 0.04045) ?
			Math.pow ((b + 0.055) / 1.055, 2.4) :
			b / 12.92;
		
		x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
		y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
		z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
		
		x = (x > 0.008856) ?
			Math.pow (x, 1 / 3) :
			(7.787 * x) + 16 / 116;
		y = (y > 0.008856) ?
			Math.pow (y, 1 / 3) :
			(7.787 * y) + 16 / 116;
		z = (z > 0.008856) ?
			Math.pow (z, 1 / 3) :
			(7.787 * z) + 16 / 116;
		
		lab = [(116 * y) - 16, 500 * (x - y), 200 * (y - z)];
		
	}
	
	lab [0] = Math.round (lab [0] * 100) / 100;
	lab [1] = Math.round (lab [1] * 100) / 100;
	lab [2] = Math.round (lab [2] * 100) / 100;
	
	this.lab = lab;
	
	this.brightnessReference = parseInt (element.getAttribute ("data-brightness-reference"));
	this.toneClass = element.getAttribute ("data-tone-class");
	
	const subColours = this.subColours = new Array ();
	const subColourElements = element.querySelectorAll (".colour-selector-sub-colours > .colour-selector-colour");
	
	if (subColourElements.length) {
		for (let i = 0; i < subColourElements.length; i++) {
			const subColour = new Colour (subColourElements [i]);
			subColours.push (subColour);
			
		}
		
	} else if (!isSubColour) {
		const mutedRGB = rgb.concat ();
		
		for (let j = 2; j <= 5; j++) {
			const lightenedRGB = mutedRGB.concat ();
			
			for (let i = 3; i <= 8; i++) {
				const element = document.createElement ("div");
				element.className = "colour-selector-colour";
				
				const background = document.createElement ("div");
				background.className = "colour-selector-field";
				background.style.backgroundColor = "rgb(" +
					lightenedRGB [0] + ", " +
					lightenedRGB [1] + ", " +
					lightenedRGB [2] + ")";
				element.appendChild (background);
				
				const label = document.createElement ("div");
				label.className = "colour-selector-label";
				label.textContent = this.label + "-" + j + "-0" + i;
				element.appendChild (label);
				
				subColours.push (new Colour (element, true));
				
				if (Math.random () + (1 - i / 8) < .667)
					break;
				
				lightenedRGB [0] = (lightenedRGB [0] * 3 + 255) * .25;
				lightenedRGB [1] = (lightenedRGB [1] * 3 + 255) * .25;
				lightenedRGB [2] = (lightenedRGB [2] * 3 + 255) * .25;
				
			}
			
			mutedRGB [0] = (mutedRGB [0] * 2 + (mutedRGB [0] + mutedRGB [1] + mutedRGB [2]) / 3) * .3;
			mutedRGB [1] = (mutedRGB [1] * 2 + (mutedRGB [0] + mutedRGB [1] + mutedRGB [2]) / 3) * .3;
			mutedRGB [2] = (mutedRGB [2] * 2 + (mutedRGB [0] + mutedRGB [1] + mutedRGB [2]) / 3) * .3;
			
		}
		
	}
	
};

Colour.prototype = Object.create (Object.prototype);

Colour.prototype.toString = function () {
	return "[" + this.label + "]";
	
};

//
// MainColours extends AnimatableSprite
//

const MainColours = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

MainColours.prototype = Object.create (AnimatableSprite.prototype);

MainColours.prototype.renderInContext = function () {};

MainColours.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	const progressBar = this.progressBar = element.previousElementSibling.querySelector (".colour-selector-progress-bar");
	
	const buttons = this.buttons = element.querySelectorAll (".colour-selector-button");
	buttons [0].addEventListener ("click", function (event) {
		this.scrollInDirection (-1);
		
	}.bind (this));
	buttons [1].addEventListener ("click", function (event) {
		this.scrollInDirection (1);
		
	}.bind (this));
	
	const mainColoursContainer = this.mainColoursContainer = element.querySelector (".colour-selector-main-colours");
	const colourElements = element.querySelectorAll (".colour-selector-main-colours > div > .colour-selector-colour");
	
	const colours = this.colours = new Array ();
	
	for (let i = 0; i < colourElements.length; i++) {
		const colour = new Colour (colourElements [i]);
		
		colour.element.addEventListener ("click", function (event) {
			event.preventDefault ();
			this.setActiveColour (colour);
			
		}.bind (this));
		
		colours.push (colour);
		
	}
	
	const resizeObserver = new ResizeObserver (this.resize.bind (this));
	resizeObserver.observe (element);
	
	mainColoursContainer.addEventListener ("scroll", this.scrollMainColoursContainer.bind (this));
	
};

MainColours.prototype.setActiveColour = function (activeColour) {
	const lastActiveColour = this.activeColour;
	if (lastActiveColour == activeColour)
		return;
	this.activeColour = activeColour;
	
	if (lastActiveColour) {
		lastActiveColour.element.classList.remove ("active");
		
	}
	if (activeColour) {
		activeColour.element.classList.add ("active");
		
	}
	
	const mainColoursContainer = this.mainColoursContainer;
	if (mainColoursContainer) {
		const containerBounds = mainColoursContainer.getBoundingClientRect ();
		
		const colourBounds = activeColour.element.getBoundingClientRect ();
		
		if (colourBounds.left - colourBounds.width * .5 < containerBounds.left) {
			this.scrollInDirection (-1, .02, colourBounds.left - containerBounds.left - colourBounds.width * .5);
			
		} else if (colourBounds.right + colourBounds.width * .5 > containerBounds.right) {
			this.scrollInDirection (1, .02, colourBounds.right + colourBounds.width * .5 - containerBounds.right);
			
		}
		
	}
	
	this.dispatchEvent ("select");
	
};

MainColours.prototype.resize = function (entries) {
	if (this.isResizing)
		return;
	
	this.isResizing = true;
	
	const element = this.element;
	element.classList.remove ("no-scroll");
	
	const selfWidth = element.offsetWidth;
	
	const mainColoursContainer = this.mainColoursContainer;
	
	mainColoursContainer.style.overflow = "visible";
	const contentWidth = mainColoursContainer.offsetWidth;
	mainColoursContainer.style.overflow = "";
	const containerWidth = mainColoursContainer.offsetWidth;
	
	if (selfWidth >= contentWidth) {
		this.maxScroll = 0;
		this.setNumIndicators (0);
		
		element.classList.add ("no-scroll");
		
	} else {
		this.maxScroll = Math.max (0, contentWidth - containerWidth);
		
		const numIndicators = Math.ceil (contentWidth / containerWidth);
		this.setNumIndicators (numIndicators);
		
		this.scrollMainColoursContainer ();
		
	}
	this.isResizing = false;
	
};

MainColours.prototype.setNumIndicators = function (numIndicators) {
	if (this.numIndicators == numIndicators)
		return;
	
	this.numIndicators = numIndicators;
	
	const progressBar = this.progressBar;
	
	while (progressBar.children.length > numIndicators)
		progressBar.removeChild (progressBar.lastElementChild);
	
	while (progressBar.children.length < numIndicators) {
		const indicator = document.createElement ("div");
		indicator.classList.add ("colour-selector-progress-bar-item");
		
		indicator.index = progressBar.children.length;
		indicator.addEventListener ("click", this.clickIndicator.bind (this));
		
		progressBar.appendChild (indicator);
		
	}
	
};

MainColours.prototype.scrollMainColoursContainer = function (event) {
	const mainColoursContainer = this.mainColoursContainer;
	const scrollLeft = mainColoursContainer.scrollLeft;
	const maxScroll = this.maxScroll;
	
	const element = this.element;
	if (scrollLeft > 0)
		element.classList.add ("scrollable-left");
	else
		element.classList.remove ("scrollable-left");
	
	if (scrollLeft < maxScroll)
		element.classList.add ("scrollable-right");
	else
		element.classList.remove ("scrollable-right");
	
	const numIndicators = this.numIndicators;
	const activeIndex = Math.max (0, Math.min (numIndicators - 1,
		Math.floor (scrollLeft / maxScroll * (numIndicators + 0))
		
	));
	
	const progressBar = this.progressBar;
	const indicators = progressBar.children;
	for (let i = indicators.length; i--;) {
		const indicator = indicators [i];
		if (i == activeIndex)
			indicator.classList.add ("active");
		else
			indicator.classList.remove ("active");
		
	}
	
	const state = this.states ["ScrollMainColours"];
	if (state && state.phase == 1)
		this.targetScroll = undefined;
	
};

MainColours.prototype.clickIndicator = function (event) {
	const indicator = event.currentTarget;
	const index = indicator.index;
	
	const mainColoursContainer = this.mainColoursContainer;
	const maxScroll = this.maxScroll;
	
	const progressBar = this.progressBar;
	const indicators = progressBar.children;
	
	const startScroll = this.startScroll = mainColoursContainer.scrollLeft;
	const targetScroll = this.targetScroll = Math.max (0, Math.min (maxScroll,
		Math.round (maxScroll * index / (indicators.length - 1))
		
	));
	this.startAnimation ("ScrollMainColours", {direction: 1, rate: .015, phase: 0});
	
};

MainColours.prototype.scrollInDirection = function (direction, rate, scrollOffset) {
	const mainColoursContainer = this.mainColoursContainer;
	const startScroll = mainColoursContainer.scrollLeft;
	
	const colourBounds0 = this.colours [1].element.getBoundingClientRect ();
	const colourBounds1 = this.colours [2].element.getBoundingClientRect ();
	const dist = colourBounds1.left - colourBounds0.left;
	
	const numColoursInViewport = Math.round (mainColoursContainer.offsetWidth / dist);
	
	const targetScroll = Math.max (0, Math.min (this.maxScroll,
		isNaN (scrollOffset) ?
			Math.round ((this.targetScroll == undefined ? startScroll : this.targetScroll) + (Math.floor (numColoursInViewport * .5)) * dist * direction) :
			Math.round (startScroll + scrollOffset)
		
	));
	
	if (this.targetScroll != targetScroll) {
		this.startScroll = startScroll;
		this.targetScroll = targetScroll;
		
		this.startAnimation ("ScrollMainColours", {direction: 1, rate: rate || .015, phase: 0});
		
	}
	
};

MainColours.prototype.animateScrollMainColours = function (event) {
	const state = this.updatedState ("ScrollMainColours");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	t = .5 - Math.cos (t * Math.PI) * .5;
	const t_ = 1 - t;
	
	const mainColoursContainer = this.mainColoursContainer;
	mainColoursContainer.scrollLeft = Math.round (
		this.startScroll * t_ + this.targetScroll * t
		
	);
	
	if (t == 1)
		this.targetScroll == undefined;
	
};

//
// SubColours extends AnimatableSprite
//

const SubColours = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

SubColours.prototype = Object.create (AnimatableSprite.prototype);

SubColours.prototype.renderInContext = function () {};

SubColours.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
};

SubColours.prototype.takeColours = function (colours) {
	this.colours = colours;
	
	const element = this.element;
	
	element.style.height = "";
	const startHeight = element.offsetHeight;
	
	this.cloneElement ();
	
	element.firstElementChild.innerHTML = "";
	
	for (let i = 0; i < colours.length; i++) {
		const colour = colours [i];
		const colourElement = colour.element.cloneNode (true);
		
		colour.element.addEventListener ("click", function (event) {
			event.preventDefault ();
			this.setActiveColour (colour);
			
		}.bind (this));
		
		element.firstElementChild.appendChild (colour.element);
		
	}
	
	element.style.display = "";
	
	this.setActiveColour (undefined);
	
	const targetHeight = element.offsetHeight;
	
	this.startHeightAnimation (startHeight, targetHeight);
	
};

SubColours.prototype.setActiveColour = MainColours.prototype.setActiveColour;

SubColours.prototype.cloneElement = ColourSelector.prototype.cloneElement;
SubColours.prototype.startHeightAnimation = ColourSelector.prototype.startHeightAnimation;
SubColours.prototype.animateHeight = ColourSelector.prototype.animateHeight;

//
// ColourDetail extends AnimatableSprite
//

const ColourDetail = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

ColourDetail.prototype = Object.create (AnimatableSprite.prototype);

ColourDetail.prototype.renderInContext = function () {};

ColourDetail.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	const plasteringButton = element.querySelector ("[href=\\#see-plastering]");
	const facadesButton = element.querySelector ("[href=\\#see-facades]");
	
	const colourSelector = this.parent;
	
	plasteringButton.addEventListener ("click", function (event) {
		event.preventDefault ();
		colourSelector.showOverlay ("plastering");
		
	});
	facadesButton.addEventListener ("click", function (event) {
		event.preventDefault ();
		colourSelector.showOverlay ("facades");
		
	});
	
};

ColourDetail.prototype.takeColour = function (colour) {
	if (this.colour == colour)
		return;
	
	this.colour = colour;
	
	const element = this.element;
	
	element.style.height = "";
	const startHeight = element.offsetHeight;
	
	this.cloneElement ();
	
	let targetHeight;
	
	if (colour) {
		element.querySelector ("h3").textContent = colour.label;
		element.querySelector (".colour-selector-field").style.backgroundColor = colour.backgroundColour;
		
		const tableRows = element.querySelectorAll ("table tr");
		tableRows [0].lastElementChild.textContent = colour.rgb.join (", ");
		tableRows [1].lastElementChild.textContent = colour.lab.join (", ");
		tableRows [2].lastElementChild.textContent = colour.brightnessReference;
		tableRows [3].lastElementChild.textContent = colour.toneClass;
		
		element.style.display = "";
		targetHeight = element.offsetHeight;
		
	} else {
		targetHeight = 0;
		
	}
	
	this.startHeightAnimation (startHeight, targetHeight);
	
};

ColourDetail.prototype.cloneElement = ColourSelector.prototype.cloneElement;
ColourDetail.prototype.startHeightAnimation = ColourSelector.prototype.startHeightAnimation;
ColourDetail.prototype.animateHeight = ColourSelector.prototype.animateHeight;

//
// ColourOverlay extends AnimatableSprite
//

const ColourOverlay = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

ColourOverlay.prototype = Object.create (AnimatableSprite.prototype);

ColourOverlay.prototype.renderInContext = function () {};

ColourOverlay.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	const variant = this.variant = element.className.split ("colour-selector-overlay colour-selector-overlay-").pop ();
	
	const colourSelector = this.parent;
	
	const closeButton = element.querySelector (".close-button");
	closeButton.addEventListener ("click", function (event) {
		event.preventDefault ();
		colourSelector.closeOverlay ();
		
	});
	
	const galleryElement = element.querySelector (".gallery");
	const gallery = this.gallery = this.attachSprite (Gallery);
	gallery.takeElement (galleryElement);
	
	switch (variant) {
		case "plastering":
			gallery.addListener ("advance", this.advanceGallery, this);
			
			break;
		
	}
	
	this.getStage ().addListener ("resize", this.resize, this);
	
};

ColourOverlay.prototype.takeColour = function (colour) {
	if (this.colour == colour)
		return;
	
	this.colour = colour;
	
	const element = this.element;
	
	const pictures = element.querySelectorAll ("picture");
	const variant = this.variant;
	
	switch (variant) {
		case "plastering":
			const rgb = colour.rgb;
			const cssRGB = "rgb(" + rgb [0] + ", " + rgb [1] + ", " + rgb [2] + ")";
			
			const cssGradient = "linear-gradient(" + cssRGB + ", " + cssRGB + ")";
			
			for (let i = pictures.length; i--;) {
				const picture = pictures [i];
				const image = picture.lastElementChild;
				
				image.style.visibility = "hidden";
				image.style.width = 0;
				
				picture.style.backgroundImage = "url('" + image.getAttribute ("data-src") + "'), " + cssGradient;
				
			}
			
			this.advanceGallery (this.gallery);
			
			break;
			
		case "facades":
			element.querySelector ("h3 > span").textContent = colour.label;
			
			for (let i = pictures.length; i--;) {
				const picture = pictures [i];
				picture.style.backgroundColor = colour.backgroundColour;
				
			}
			break;
		
	}
	
};

ColourOverlay.prototype.resize = function (stage) {
	const gallery = this.gallery;
	gallery.updateLayout ();
	
};

ColourOverlay.prototype.advanceGallery = function (gallery) {
	const index = gallery.viewSize ? gallery.slideIndexForViewOffset (gallery.viewOffset) : 0;
	const slide = gallery.slides [index];
	
	const element = this.element;
	const colour = this.colour;
	
	element.querySelector ("h3").firstChild.textContent = slide.element.textContent.trim () + " ";
	element.querySelector ("h3 > span").textContent = colour.label;
	
};
