import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// TeaserGallerySection extends SiteSection
//

export const TeaserGallerySection = function (context) {
	SiteSection.apply (this, arguments);

};

window.TeaserGallerySection = TeaserGallerySection;

TeaserGallerySection.prototype = Object.create (SiteSection.prototype);

TeaserGallerySection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);

	var gallery = this.gallery = this.attachSprite (TeaserGallery);

	gallery.takeElement (element);
	gallery.contentContainer.element.style.visibility = "hidden";

};

TeaserGallerySection.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);

	var gallery = this.gallery;
	gallery.isAutoRotationLocked = false;

};

TeaserGallerySection.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);

	var gallery = this.gallery;
	gallery.isAutoRotationLocked = true;

};

TeaserGallerySection.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);

	var gallery = this.gallery;
	gallery.updateLayout ();

	window.setTimeout (function () {
		gallery.updateLayout ();
		gallery.contentContainer.element.style.visibility = "";

	}, 50);

};

//
// TeaserGallery extends AnimatableSprite, SlidingSpritePrototype
//

var TeaserGallery = function (context) {
	AnimatableSprite.apply (this, arguments);

};

TeaserGallery.prototype = Object.create (AnimatableSprite.prototype);
TeaserGallery.extendPrototype (SlidingSpritePrototype);

TeaserGallery.prototype.getListenerTargetForScrollingController = function () {
	return this.element;

};

TeaserGallery.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	element.style.height = "auto";
	element.style.visibility = "visible";

	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");

	SlidingSpritePrototype.apply (this, arguments);

	this.isDownloadGallery = element.classList.contains ("teaser-gallery--download");

	var fitting = this.fitting = "content";
	var adjustHeightToContent = this.adjustHeightToContent = fitting == "content";
	if (!adjustHeightToContent) {
		fitting = fitting.split (":");
		var aspectRatio = this.aspectRatio =
			parseFloat (fitting [1]) /
			parseFloat (fitting [0]);

	}

	let isInStepByStepContainer = false;

	let parent = element;
	while (parent.classList) {
		if (parent.classList.contains ("step-by-step-container")) {
			isInStepByStepContainer = true;
			break;

		}
		parent = parent.parentNode;

	}

	this.isInStepByStepContainer = isInStepByStepContainer;

	var contentContainer = this.contentContainer = this.attachSprite ();

	element.removeChild (contentContainer.element);
	contentContainer.element = element.querySelector (".gallery__slide-container") || this.element;
	contentContainer.element.style.overflow = "hidden";
	
	this.parseChildren ();

	this.finishSetup ();

};

TeaserGallery.prototype.parseChildren = function () {
	var element = this.element;
	var context = this.context;

	var slides = this.slides = new Array ();

	var contentContainer = this.contentContainer;
	var children = (Environment.IS_IN_NEOS_EDITOR ? element : element).querySelectorAll (".gallery__slide");

	var numSlides = this.numSlides = children.length;

	var firstChildParent = children [0];

	firstChildParent = firstChildParent && firstChildParent.slide;
	firstChildParent = firstChildParent && firstChildParent.element.parentNode;

	for (var i = 0; i < numSlides; i++) {
		var child = children [i];

		var slide = child.slide;
		if (child.slide) {
			slides.push (slide);
			// slides.unshift (slide);

		} else {
			slide = child.slide = new TeaserGallerySlide (context);
			slide.parentGallery = this;

			slide.takeElement (child);
			slide.setAlpha (0);

			slides.push (slide);

		}

	}

	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		slide.index = i;

		var child = slide.element;

		var slideParentNode = firstChildParent || child.parentNode;
		contentContainer.addChild (slide);
		slideParentNode.appendChild (slide.element);

	}

	var captionContainer = this.captionContainer = element.querySelector (".gallery__caption-footer");

	if (!Environment.IS_IN_NEOS_EDITOR) {
		const minNumEntries = 0; // this.adjustHeightToContent ? 9 : 3;

		while (slides.length > 1 && slides.length < minNumEntries) {
			for (var i = 0; i < numSlides; i++) {
				var child = children [i].cloneNode (true);

				slide = child.slide = new TeaserGallerySlide (context);
				slide.index = slides.length;
				slide.takeElement (child);
				slide.setAlpha (0);

				contentContainer.addChild (slide);

				slides.push (slide);

			}

		}

		if (captionContainer) {
			for (var i = slides.length; i--;) {
				var slide = slides [i];
				var caption = slide.caption;

				if (caption)
					captionContainer.appendChild (caption);

			}

		}

	}
	
	if (numSlides == 1) {
		window.setTimeout (function () {
			slides [0].startLoading ();
			
		}.bind (this), 125);
		
	};

};

TeaserGallery.prototype.takeSlides = function (slides, navigationElement) {
	var element = this.element;
	element.classList.add ("gallery");

	SlidingSpritePrototype.apply (this, arguments);

	this.slides = slides;
	var numSlides = this.numSlides = slides.length;

	var contentContainer = this.contentContainer = this.attachSprite ();
	contentContainer.element.classList.add ("gallery__slide-container");

	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		slide.index = i;

		contentContainer.addChild (slide);

	}

	if (navigationElement) {
		this.navigationElement = navigationElement;
		this.element.appendChild (navigationElement);

	}

	this.finishSetup ();

};

TeaserGallery.prototype.finishSetup = function (viewSize) {
	var element = this.element;

	var navigationElement = this.navigationElement = element.querySelector (".gallery__navigation");
	// this.contentContainer.element.appendChild (navigationElement);

	const numSlides = this.numSlides;

	if (numSlides > 1 || Environment.IS_IN_NEOS_EDITOR) {
		var scrollingController = this.scrollingController;

		if (!Environment.IS_IN_NEOS_EDITOR) {
			scrollingController.stage = this.getStage ();
			scrollingController.getMouseListenerTarget = function (eventType) {
				return element;

			};

			scrollingController.awake ();

			scrollingController.addListener ("beginDrag", this.beginDrag, this);
			scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
			scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
			scrollingController.addListener ("endDrag", this.endDragHorizontal, this);

		}

		const progressBar = this.progressBar = element.querySelector (".gallery__navigation__progress-bar");
		if (progressBar) {
			progressBar.addEventListener ("mousedown", function (event) {
				event.stopPropagation ();

			});
			progressBar.addEventListener ("touchstart", function (event) {
				event.stopPropagation ();

			});

			const indicators = this.indicators = new Array ();

			for (let i = 0; i < numSlides; i++) {
				const indicator = document.createElement ("div");
				indicator.index = i;
				indicator.classList.add ("gallery__navigation__progress-bar-item");

				indicator.addEventListener ("mousedown", function (event) {
					event.stopPropagation ();
					if (!Environment.IS_TOUCH_DEVICE)
						event.preventDefault ();

				});

				indicator.addEventListener ("click", function (event) {
					this.setViewOffsetByIndex (event.currentTarget.index);

				}.bind (this));

				progressBar.appendChild (indicator);
				indicators.push (indicator);

			}

		}

		this.addListener ("completeSliding", this.completeSliding, this);

		this.addListener ("advance", this.advance, this);

		if (navigationElement) {
			var arrowButtons = this.arrowButtons = element.querySelectorAll (".gallery__navigation__arrow");
			arrowButtons [0].addEventListener ("click", function () {
				this.advanceInDirection (-1, true);

			}.bind (this));
			arrowButtons [0].addEventListener ("touchstart", function () {
				this.advanceInDirection (-1, true);

			}.bind (this));
			arrowButtons [1].addEventListener ("click", function () {
				this.advanceInDirection (1, true);

			}.bind (this));
			arrowButtons [1].addEventListener ("touchstart", function () {
				this.advanceInDirection (1, true);

			}.bind (this));

			function stopPropagation (event) {
				event.stopPropagation ();
				event.preventDefault ();

			}

			for (var i = arrowButtons.length; i--;) {
				var arrowButton = arrowButtons [i];
				arrowButton.addEventListener ("mousedown", stopPropagation);
				arrowButton.addEventListener ("touchstart", stopPropagation);

			}

		}

		if (!Environment.IS_IN_NEOS_EDITOR) {
			this.isAutoRotationLocked = true;

			var autoRotationFrequency = this.autoRotationFrequency = parseFloat (element.getAttribute ("data-auto-rotation-frequency")) * 60;
			var shouldAutoRotate = this.shouldAutoRotate = !!autoRotationFrequency;
			if (shouldAutoRotate)
				this.startAutoRotation ();

		}

	} else {
		if (!Environment.IS_IN_NEOS_EDITOR && navigationElement)
			navigationElement.style.display = "none";

	}

	if (Environment.IS_IN_NEOS_EDITOR) {
		// this.getStage ().addListener ("mutate", this.mutateStage, this);

		var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
		mutationObserver.observe (this.element, {
			childList: true,
			subtree: true

		});

	}

};

TeaserGallery.prototype.mutateElement = function (stage) {
	if (this.isMutating)
		return;

	var activeElement = document.activeElement;
	if (activeElement && activeElement.getAttribute ("contenteditable") == "true") {
		this.isMutating = true;
		
		this.updateLayout ();
		
		window.setTimeout (function () {
			this.isMutating = false;
			
		}.bind (this), 50);
		
		return;
		
	}
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve;

	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now

			);

		}

	} else {
		this.handleMutation ();

	}

};

TeaserGallery.prototype.handleMutation = function () {
	this.mutationHandler = null;

	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;

	this.isMutating = true;

	this.parseChildren ();
	this.updateLayout (true);
	this.updateViewOffset ();
	this.advance ();

	window.setTimeout (function () {
		this.updateLayout (true);
		this.updateViewOffset ();

		this.isMutating = false;

	}.bind (this), 50);

};

TeaserGallery.prototype.startAutoRotation = function () {
	 if (Environment.FAST_PASS)
		return;

	this.addListener ("mouseover", this.mouseOverOnAutoRotation, this);
	this.addListener ("mouseout", this.mouseOutOnAutoRotation, this);

	this.autoRotationDelay = this.autoRotationFrequency;
	this.addRunLoopHandler ("processAutoRotation");

};

TeaserGallery.prototype.stopAutoRotation = function () {
	this.removeRunLoopHandler ("processAutoRotation");

};

TeaserGallery.prototype.mouseOverOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = true;

};

TeaserGallery.prototype.mouseOutOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = false;
	this.autoRotationDelay = Math.max (this.autoRotationFrequency, 200);

};

TeaserGallery.prototype.processAutoRotation = function (sender) {
	if (this.isAutoRotationLocked)
		return;

	var autoRotationDelay = this.autoRotationDelay = Math.max (
		0, this.autoRotationDelay - this.context.animationTimer.framesDelta

	);

	if (autoRotationDelay)
		return;

	this.autoRotationDelay = this.autoRotationFrequency;
	this.skipTracking = true;
	this.advanceInDirection (1, {
		maxSpeed: 50,
		acceleration: 4.25

	});

};

TeaserGallery.prototype.updateLayout = function (noResize) {
	var slides = this.slides;
	var slide = slides [0];
	if (!slide)
		return;

	let teaserWidth, containerWidth;
	let numVisibleTeasers;

	const element = this.element;
	const column = element.querySelector (".column");
	column.style.width = "";
	
	const containerElement = this.contentContainer.element;
	containerElement.style.width = "";
	
	const stage = Site.sharedInstance;
	const stageSize = stage.size;

	if (this.isInStepByStepContainer && stageSize [0] >= 768) {
		numVisibleTeasers = 1;
		
		containerWidth = teaserWidth = containerElement.offsetWidth;
		
	} else {
		const gutterWidth = this.spacing = stage.gutterWidth;
		const columnWidth = stage.columnWidth;

		let teaserColumns;

		if (stageSize [0] < 560) {
			numVisibleTeasers = 1;
			teaserColumns = this.isDownloadGallery ? 10 : 8;

		} else if (stageSize [0] < 768) {
			numVisibleTeasers = 2;
			teaserColumns = 6;

		} else if (stageSize [0] < 1024) {
			numVisibleTeasers = 2;
			teaserColumns = 5;

		} else if (stageSize [0] < 1440) {
			numVisibleTeasers = 3;
			teaserColumns = 4;

		} else {
			numVisibleTeasers = 3;
			teaserColumns = 3;

		}

		teaserWidth = columnWidth * teaserColumns + gutterWidth * (teaserColumns - 1);
		containerWidth = (teaserWidth + gutterWidth) * Math.min (slides.length, numVisibleTeasers) - gutterWidth;

		containerElement.style.width = containerWidth + "px";

	}

	this.numVisibleTeasers = numVisibleTeasers;

	const navigation = this.element.querySelector (".gallery__navigation");
	const scrollingController = this.scrollingController;

	if (slides.length > numVisibleTeasers) {
		if (!Environment.IS_IN_NEOS_EDITOR) {
			scrollingController.awake ();

		}

		navigation.style.display = "";
		element.style.paddingBottom = "";

		if (this.shouldAutoRotate && this.isAwake)
			this.startAutoRotation ();

		column.style.width = "";

	} else {
		this.viewOffset = this.currentViewOffset = 0;

		this.releaseDrag ();
		scrollingController.sleep ();

		navigation.style.display = "none";
		element.style.paddingBottom = "0";

		if (this.shouldAutoRotate)
			this.stopAutoRotation ();

		column.style.width = containerWidth + 1 * stage.gutterWidth + "px";
		
	}
	
	slide.element.style.display = "";


	var viewSize;

	if (this.adjustHeightToContent) {
		viewSize = [
			containerWidth,
			Math.round (containerElement.offsetHeight)

		];

		let fullWidth = 0;
		var maxHeight = 0;
		var slides = this.slides;

		var slide = slides [0];
		if (slide.textBox) {
			var maxBoxHeight = 0;

			for (let i = slides.length; i--;) {
				const slide = slides [i];
				const textBox = slide.textBox;
				const slideElement = slide.element;

				slideElement.style.width = teaserWidth + "px";
				slideElement.style.height = "auto";

				textBox.style.height = "auto";
				maxBoxHeight = Math.max (textBox.offsetHeight, maxBoxHeight);

			}

			for (let i = slides.length; i--;) {
				const slide = slides [i];
				const textBox = slide.textBox;

				textBox.style.height = maxBoxHeight + "px";

			}

		}

		for (var i = slides.length; i--;) {
			var slide = slides [i];
			var slideElement = slide.element;

			slideElement.style.width = teaserWidth + "px";
			slideElement.style.height = "auto";

			var displayStyle = slideElement.style.display;

			if (displayStyle)
				slideElement.style.display = "";

			const slideSize = slide.contentSize = [
				teaserWidth,
				slideElement.offsetHeight

			];

			fullWidth += slideSize [0] + this.spacing;


			if (displayStyle)
				slideElement.style.display = displayStyle;

			maxHeight = Math.max (maxHeight, slideSize [1]);

		}

		const slideSize = this.slideSize = [
			teaserWidth,
			maxHeight

		];

		for (var i = slides.length; i--;) {
			var slide = slides [i];
			slide.setViewSize (slideSize);
			slide.element.style.height = maxHeight + "px";

		}

		this.fullWidth = fullWidth;
		viewSize [1] = maxHeight;

		this.scrollThrough = slideSize [0] < containerWidth;

	}

	this.setViewSize (viewSize, noResize);

};

TeaserGallery.prototype.setViewSize = function (viewSize, noResize) {
	this.autoRotationDelay = this.autoRotationFrequency;

	var containerElement = this.contentContainer.element;
	containerElement.style.height = viewSize [1] + "px";


	var captionContainer = this.captionContainer;

	if (captionContainer) {
		var maxCaptionHeight = 0;

		// if (!Environment.IS_IN_NEOS_EDITOR) {
			var slides = this.slides;

			for (var i = slides.length; i--;) {
				var slide = slides [i];
				var caption = slide.caption;

				if (caption) {
					caption.style.display = "";
					maxCaptionHeight = Math.max (maxCaptionHeight, caption.offsetHeight);

				}

			}

		// }

		if (maxCaptionHeight) {
			captionContainer.style.display = "";
			captionContainer.style.paddingBottom = maxCaptionHeight + 3 + "px";

		} else {
			captionContainer.style.display = "none";

		}

	}

	var stageSize = this.getStage ().size;
	var overscan = this.overscan = Math.ceil (
		(stageSize [0] - viewSize [0]) / 2

	);

	const lastViewSize = this.viewSize;
	const lastIndex = lastViewSize && this.slideIndexForViewOffset (this.viewOffset);

	this.viewSize = viewSize;
	this.setSize (viewSize);

	if (lastViewSize && !noResize && !(this.getStage ().nextTimeToObserve > new Date ().getTime ())) {
		/*
		var percentage = Math.round (
			this.currentViewOffset / (lastViewSize [0] + this.spacing)

		);
		this.viewOffset = this.currentViewOffset =
			percentage * (viewSize [0] + this.spacing);
		*/

		this.viewOffset = this.currentViewOffset = this.viewOffsetForSlideIndex (lastIndex);

		this.removeRunLoopHandler ("processSliding");

	}

	this.updateViewOffset ();

	if (!lastViewSize) {
		this.dispatchEvent ("advance");
		this.dispatchEvent ("completeSliding");

	}

};

TeaserGallery.DRAG_DELTA = 150;

TeaserGallery.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);

	// scrollingController.currentEvent.preventDefault ();
	scrollingController.currentEvent.stopPropagation ();

	delete this.maxScrollSpeed;

};

TeaserGallery.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;

};

TeaserGallery.prototype.snapToBounds = function () {
	var viewOffset = this.viewOffset;
	var index = this.slideIndexForViewOffset (viewOffset, true);

	var targetViewOffset = this.viewOffsetForSlideIndex (index);

	if (viewOffset != targetViewOffset) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");

	}

};

TeaserGallery.prototype.completeSliding = function (sender) {
	const slides = this.slides;
	var index = this.slideIndexForViewOffset (this.viewOffset);

	index %= slides.length;
	if (index < 0)
		index += slides.length;

	var currentSlide = slides [index];
	if (!currentSlide.isLoading)
		currentSlide.startLoading ();

	if (slides.length > 1) {
		var nextItem = slides [(index + 1) % slides.length];
		if (!nextItem.isLoading)
			nextItem.startLoading ();

	}

	if (slides.length > 2) {
		var previousItem = slides [(index + slides.length - 1) % slides.length];
		if (!previousItem.isLoading)
			previousItem.startLoading ();

	}

	/*
	if (this.skipTracking) {
		this.skipTracking = false;

	} else {
		if (window.WebtrekkCounter)
			WebtrekkCounter.track ("click", "homepage.teaser." + currentSlide.id);

	}
	*/

};

TeaserGallery.prototype.advance = function (sender) {
	const currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);

	const indicators = this.indicators;
	if (indicators) {

		for (let i = indicators.length; i--;) {
			const indicator = indicators [i];

			if (i == currentImageIndex % indicators.length)
				indicator.classList.add ("active");
			else
				indicator.classList.remove ("active");

		}

	}

	function enableButton (button, isEnabled) {
		if (isEnabled) {
			button.classList.remove ("disabled");

		} else {
			button.classList.add ("disabled");

		}

	}

};

TeaserGallery.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");

	var viewSize = this.viewSize;
	var dragDelta = Math.min (
		viewSize [0] / 2, TeaserGallery.DRAG_DELTA

	);

	if (Math.abs (deltaX) >= dragDelta && !this.scrollThrough)
		this.advanceInDirection (deltaX < 0 ? -1 : 1);
	else
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;

	this.updateViewOffset ();

};

TeaserGallery.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;

	if (didFlick) {
		var targetViewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.5;

		var viewSize = this.viewSize;
		var dragDelta = Math.min (
			viewSize [0] / 2, TeaserGallery.DRAG_DELTA

		);

		if (this.scrollThrough) {
			this.viewOffset = targetViewOffset;
			this.snapToBounds ();

			this.dispatchEvent ("advance");

		} else {
			if (Math.abs (targetViewOffset) >= dragDelta) {
				this.advanceInDirection (scrollingController.flickDeltaX < 0 ? -1 : 1);

			} else {
				this.snapToBounds ();

			}

		}

	} else {
		this.snapToBounds ();

	}

};

TeaserGallery.prototype.advanceInDirection = function (direction, easeIn) {
	this.releaseDrag ();

	var delta = Math.abs (this.currentViewOffset - this.viewOffset);

	var index = this.slideIndexForViewOffset (this.viewOffset, true) + direction;

	var viewSize = this.viewSize;
	this.viewOffset = this.viewOffsetForSlideIndex (index);

	this.slidingInertia = .85;
	if (easeIn && delta < 10)
		this.maxScrollSpeed = 4;
	this.accelerationDescription = undefined;

	this.addRunLoopHandler ("processSliding");

	this.dispatchEvent ("advance");

};

TeaserGallery.prototype.spacing = 40;

TeaserGallery.prototype.slideIndexForViewOffset = function (viewOffset, noWrap) {
	var index = Math.round (
		viewOffset / (this.slideSize [0] + this.spacing)

	);

	if (!noWrap) {
		var slides = this.slides;
		index = index % slides.length;
		if (index < 0)
			index += slides.length;

	}

	return index;

};

TeaserGallery.prototype.bringIndexIntoView = function (index) {
	const currentIndex = this.slideIndexForViewOffset (this.viewOffset);
	const numSlides = this.slides.length;

	for (let i = currentIndex; i < currentIndex + this.numVisibleTeasers; i++) {
		let i_ = i % numSlides;
		if (i_ < 0)
			i_ += numSlides;

		if (i_ == index)
			return;

	}

	this.setViewOffsetByIndex (index);

};

TeaserGallery.prototype.setViewOffsetByIndex = function (index, dontAnimate) {
	var slides = this.slides;
	index = index % slides.length;
	if (index < 0)
		index += slides.length;

	var viewOffset = this.viewOffset;

	var currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
	var delta = (index - currentImageIndex) % slides.length;
	if (delta < 0)
		delta += slides.length;

	if (delta > slides.length * .5)
		delta = delta - slides.length;

	if (!delta)
		return;

	var viewSize = this.viewSize;
	var targetViewOffset = this.viewOffset;

	var direction = slides.length > 2 ? delta > 0 ? 1 : -1 : currentImageIndex > index ? -1 : 1;
	targetViewOffset += (this.slideSize [0] + this.spacing) * Math.abs (delta) * direction;

	if (viewOffset != targetViewOffset) {
		if (dontAnimate) {
			this.viewOffset = this.currentViewOffset = targetViewOffset;
			this.removeRunLoopHandler ("processSliding");
			this.updateViewOffset ();

		} else {
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .85;
			this.maxScrollSpeed = 4;
			this.accelerationDescription = undefined;
			this.addRunLoopHandler ("processSliding");

		}

	}

	this.dispatchEvent ("advance");

};

TeaserGallery.prototype.viewOffsetForSlideIndex = function (slideIndex) {
	if (false && this.adjustHeightToContent) {
		const slides = this.slides;
		const hardOff = Math.floor (
			slideIndex / slides.length

		);

		slideIndex = slideIndex % slides.length;
		if (slideIndex < 0)
			slideIndex += slides.length;

		let cursor = 0;

		for (var i = 0; i < slideIndex; i++) {
			const slide = slides [i];
			cursor += slide.contentSize [0];
			cursor += this.spacing;

		}

		return cursor + hardOff * this.fullWidth;

	} else {
		return slideIndex * (this.slideSize [0] + this.spacing);

	}

};

TeaserGallery.prototype.updateViewOffset = function () {
	var slides = this.slides;
	if (!slides.length)
		return;

	this.updateViewOffsetForVariableContent ();

	this.dispatchEvent ("updateViewOffset");
	this.renderInContext (this.context);

};

TeaserGallery.prototype.updateViewOffsetForVariableContent = function () {
	const slides = this.slides;

	var viewSize = this.viewSize;
	var viewWidth = viewSize [0];
	var spacing = this.spacing;

	var viewOffset = this.currentViewOffset;

	var fullWidth = this.fullWidth;
	var currentSlideIndex = this.slideIndexForViewOffset (viewOffset);

	viewOffset = viewOffset % fullWidth;
	if (viewOffset < 0)
		viewOffset += fullWidth;

	viewOffset = Math.round (viewOffset);

	var slide = slides [currentSlideIndex];

	var cursor = viewOffset;

	var overscan = 0; // this.overscan;

	var j = slides.length;
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		const slideSize = slide.contentSize;

		var offset = Math.round (-cursor);
		if (offset < -slideSize [0] - overscan)
			offset += this.fullWidth;

		if (offset > viewWidth + 2 * overscan)
			offset -= this.fullWidth;

		slide.setPosition (
			offset,
			0

		);

		var percentage = Math.max (0, Math.min (1, (1 - Math.abs (
			slide.position [0] / slideSize [0] * 2

		))));

		slide.setAlpha (1);
		// slide.element.firstElementChild.firstElementChild.style.opacity = (percentage * .67 + .33);

		cursor -= slideSize [0];
		cursor -= this.spacing;
		cursor %= fullWidth;

	}

};

//
// TeaserGallerySlide extends AnimatableSprite
//

const TeaserGallerySlide = function (context) {
	AnimatableSprite.apply (this, arguments);

	this.renderUsingCSSTransform = true;

};

TeaserGallerySlide.prototype = Object.create (AnimatableSprite.prototype);

TeaserGallerySlide.prototype.takeElement = function (element) {
	this.element = element;

	if (element.classList.contains ("gallery__slide--text"))
		element.classList.add ("gallery__slide--text--initialized");

	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");

	element.addEventListener ("click", function (event) {
		if (this.parent.parent.scrollingController.didDrag) {
			event.preventDefault ();
			return;

		}

	}.bind (this));

	const image = this.image = element.querySelector ("picture");
	if (image) {
		image.classList.add ("lazyload--started");

		image.querySelector ("img").addEventListener ("load", function () {
			this.parentGallery.updateLayout ();

		}.bind (this));

		window.setTimeout (function () {
			this.startLoading ();

		}.bind (this), 50);

	} else {
		const img = element.querySelector ("img");
		if (img) {
			img.removeAttribute ("loading");

			img.addEventListener ("load", function () {
				this.parentGallery.updateLayout ();

			}.bind (this));

		}

	}

	var caption = element.querySelector ("figcaption");
	if (caption && caption.textContent)
		this.caption = caption;

	if (Environment.IS_IN_NEOS_EDITOR) {
		element.removeAttribute ("href");

	}

	const anchors = element.querySelectorAll ("a");
	for (let i = anchors.length; i--;) {
		const anchor = anchors [i];

		anchor.addEventListener ("click", function (event) {
			if (this.parentGallery.scrollingController.didDrag)
				event.preventDefault ();

		}.bind (this));

	}

	const textBox = this.textBox = element.querySelector (".bg-white.flex.flex-col");

};

TeaserGallerySlide.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;

	var element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";

};

TeaserGallerySlide.prototype.markLoadingPriority = function (loadingPriority) {
	this.loadingPriority = loadingPriority;

};

TeaserGallerySlide.prototype.startLoading = function () {
	this.isLoading = true;
	
	var image = this.image;
	if (!image)
		return;
	
	image.querySelector ("img").addEventListener ("load", function () {
		this.parent.parent.updateLayout ();
		
	}.bind (this));
	LazyLoader.sharedInstance.loadImageAndListen (image);
	
};

TeaserGallerySlide.prototype.awake = function () {
	this.isAwake = true;

};

TeaserGallerySlide.prototype.sleep = function () {
	this.isAwake = false;

};
