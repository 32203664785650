import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// StepByStep extends SiteSection
//

export const StepByStep = function (context) {
	SiteSection.apply (this, arguments);

};

window.StepByStep = StepByStep;

StepByStep.prototype = Object.create (SiteSection.prototype);

StepByStep.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const navigation = this.navigation = element.querySelector (".numbered-navigation");
	const navigationParent = this.navigationParent = navigation.parentNode;
	
	const contentContainer = this.contentContainer = element.querySelector (".step-by-step-content");
	
	this.parseChildren ();
	
	if (!Environment.IS_IN_NEOS_EDITOR) {
		const containerRow = this.containerRow = document.createElement ("div");
		containerRow.classList.add ("row");
		
		contentContainer.insertBefore (containerRow, contentContainer.firstElementChild);
		
	}
	
	const navigationMobileSibling = this.navigationMobileSibling = contentContainer.querySelector (".column:last-child");
	
	window.setTimeout (function () {
		this.setItemIndex (-this.indexOffset);
		
	}.bind (this), 0);
	
	/*
	if (Environment.FAST_PASS) {
		window.setTimeout (function () {
			this.setItemIndex (1);
			
		}.bind (this), 750);
		
	}
	*/
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
		mutationObserver.observe (this.element, {
			childList: true,
			subtree: true
			
		});
		
	}
	
};

StepByStep.prototype.parseChildren = function () {
	const element = this.element;
	
	const hasZeroState = this.hasZeroState = element.getAttribute ("data-has-zero-state") == "yes";
	const indexOffset = this.indexOffset = hasZeroState ? 1 : 0;
	
	const contentItems = this.contentItems = element.querySelectorAll (".step-by-step-content > div");
	
	for (let i = contentItems.length; i--;) {
		const contentItem = contentItems [i];
		contentItem.index = i;
		contentItem.controller = this;
		
	}
	
	const navigation = this.navigation;
	
	while (navigation.children.length < contentItems.length - indexOffset) {
		const navigationItem = document.createElement ("a");
		
		navigationItem.index = navigation.children.length;
		navigationItem.textContent = navigation.children.length + 1;
		navigationItem.setAttribute ("href", "#");
		
		navigationItem.addEventListener ("click", this.clickNavigationItem.bind (this));
		
		navigation.appendChild (navigationItem);
		
	}
	
	while (navigation.children.length > contentItems.length - indexOffset) {
		navigation.removeChild (navigation.lastElementChild);
		
	}
	
	const navigationItems = this.navigationItems = navigation.querySelectorAll ("a");
	
};

StepByStep.prototype.mutateElement = function (stage) {
	if (this.isMutating)
		return;

	var activeElement = document.activeElement;
	if (activeElement && activeElement.getAttribute ("contenteditable") == "true")
		return;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

StepByStep.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	this.isMutating = true;
	
	this.parseChildren ();
	
	window.setTimeout (function () {
		this.setViewSize (this.viewSize);
		this.isMutating = false;
		
	}.bind (this), 50);
	
};


StepByStep.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const element = this.element;
	
	const stage = Site.sharedInstance;
	const stageSize = stage.size;
	
	const isMobile = stageSize [0] < 768;
	this.setIsMobile (isMobile);
	
	const contentContainer = this.contentContainer;
	
	if (isMobile) {
		contentContainer.style.minHeight = "";
		
	} else {
		const contentItems = this.contentItems;
		
		let maxHeight = 0;
		
		for (let i = contentItems.length; i--;) {
			const contentItem = contentItems [i];
			
			const lastDisplayStyle = contentItem.style.display;
			contentItem.style.display = "flex";
			
			StepByStep.updateControlledChildren (contentItem);
			
			const offsetHeight = contentItem.offsetHeight;
			maxHeight = Math.max (maxHeight, offsetHeight);
			
			contentItem.style.display = lastDisplayStyle;
			
		}
		contentContainer.style.minHeight = maxHeight + "px";
		
	}
	
};


StepByStep.prototype.isMobile = false;

StepByStep.prototype.setIsMobile = function (isMobile) {
	if (this.isMobile == isMobile)
		return;
	
	this.isMobile = isMobile;
	
	if (!Environment.IS_IN_NEOS_EDITOR) {
		const navigation = this.navigation;
		
		if (isMobile) {
			const nextSibling = this.navigationMobileSibling;
			nextSibling.parentNode.insertBefore (navigation, nextSibling);
			
		} else {
			this.navigationParent.appendChild (navigation);
			
		}
		
	}
	
};

StepByStep.prototype.clickNavigationItem = function (event) {
	event.preventDefault ();
	
	const navigationItem = event.currentTarget;
	const itemIndex = navigationItem.index;
	
	this.setItemIndex (itemIndex);
	
};

StepByStep.prototype.setItemIndex = function (itemIndex) {
	let lastItemIndex = this.itemIndex;
	if (lastItemIndex == itemIndex)
		return;
	
	this.itemIndex = itemIndex;
	
	const contentItems = this.contentItems;
	const navigationItems = this.navigationItems;	
	
	const indexOffset = this.indexOffset;
	
	for (let i = contentItems.length; i--;) {
		const contentItem = contentItems [i];
		const navigationItem = navigationItems [i];
		
		const isContentActive = i == itemIndex + indexOffset;
		
		contentItem.style.display = isContentActive ? "flex" : "none";
		
		if (navigationItem) {
			const isNavigationItemActive = i == itemIndex;	
			if (isNavigationItemActive)
				navigationItem.classList.add ("active");
			else
				navigationItem.classList.remove ("active");
			
		}
		
		const controlledChildren = contentItem.querySelectorAll ("[data-processed=yes]");
		
		for (let i = controlledChildren.length; i--;) {
			const controlledChild = controlledChildren [i];
			const controller = controlledChild.section;
			
			if (isContentActive) {
				controller.setViewSize (this.viewSize);
				controller.awake ();
				
			} else {
				controller.sleep ();
				
			}
			
		}
		
	}
	
	itemIndex += indexOffset;
	
	const currentItem = this.currentItem = contentItems [itemIndex];
	const navigationMobileSibling = this.navigationMobileSibling = currentItem.querySelector (".column:last-child");
	
	const isMobile = this.isMobile;
	
	if (isMobile) {
		let lastItemNavigation = currentItem.querySelector (".numbered-navigation");
		if (lastItemNavigation)
			lastItemNavigation.parentNode.removeChild (lastItemNavigation);
		
		const nextSibling = this.navigationMobileSibling;
		nextSibling.parentNode.insertBefore (this.navigation, nextSibling);
		
	}
	
	this.stopAnimation ("CrossFadeContent");
	
	if (isNaN (lastItemIndex))
		return;
	
	lastItemIndex += indexOffset;
	
	currentItem.style.display = "flex";
	currentItem.style.position = "relative";
	currentItem.style.opacity = .005;
	currentItem.style.background = "#ffffff";
	currentItem.style.zIndex = 2;
	currentItem.style.pointerEvents = "";
	
	this.setTransformForItem (currentItem, 0);
	
	StepByStep.updateControlledChildren (currentItem);
	
	const lastItem = this.lastItem = contentItems [lastItemIndex];
	
	lastItem.style.display = "flex";
	lastItem.style.position = "absolute";
	lastItem.style.opacity = 1;
	lastItem.style.background = "#ffffff";
	lastItem.style.zIndex = 1;
	lastItem.style.pointerEvents = "none";
	
	this.setTransformForItem (lastItem, 1);
	
	let lastItemNavigation = lastItem.querySelector (".numbered-navigation");
	if (lastItemNavigation)
		lastItemNavigation.parentNode.removeChild (lastItemNavigation);
	
	if (isMobile) {
		const nextSibling = lastItem.querySelector (".column:last-child");
		nextSibling.parentNode.insertBefore (this.navigation.cloneNode (true), nextSibling);
		
	}
	
	StepByStep.updateControlledChildren (lastItem);
	
	window.setTimeout (function () {
		this.startAnimation ("CrossFadeContent", {direction: 1, rate: .06 * .75, phase: 0});
		
	}.bind (this), 50);
	
};

StepByStep.updateControlledChildren = function (element) {
	const controlledChildren = element.querySelectorAll ("[data-processed=yes]");
	
	for (let i = controlledChildren.length; i--;) {
		const controlledChild = controlledChildren [i];
		const controller = controlledChild.section;
		
		controller.setViewSize (this.viewSize);
		
	}
	
};

StepByStep.prototype.animateCrossFadeContent = function () {
	const state = this.updatedState ("CrossFadeContent");
	const t = state.phase;
	
	const currentItem = this.currentItem;
	const lastItem = this.lastItem;
	
	if (t < 1) {
		currentItem.style.opacity = t * 1.5;
		
	} else {
		lastItem.style.display = "none";
		
	}
	
	this.setTransformForItem (currentItem, t);
	
};

StepByStep.prototype.setTransformForItem = function (item, t) {
	const children = item.children;
	
	const phase = (1 - t) * (1 - t) * (1 - t);
	const offset = 0; // phase * 10;
	const scale = Math.pow (1.2, phase);
	
	const isMobile = this.isMobile;
	
	for (let i = children.length; i--;) {
		const child = children [i];
		
		const offset = 75 * phase * (i < 1 ? -1 : .5); // phase * 10;
		
		child.style.transform = isMobile ? "" : "translateX(" + offset + "px)";
		
	}
	
	item.style.transform = isMobile ? "" : "scale(" + scale + ") translateY(" + offset + "px)";
	
};
