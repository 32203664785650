import * as Environment from "../base/Environment.js";

import {Dispatcher} from "./Base.js";
import {LoaderJob} from "./Loader.js";
import {RenderContext, Animatable} from "./Display.js";

export {RenderContext};

//
// Sprite extends Dispatcher
//

export const Sprite = function (context) {
	Dispatcher.apply (this);
	
	if (!context)
		throw new Error ("sprite needs a context to be created in.");
	this.context = context;
	
	var element = this.element = document.createElement ("div");
	element.className = "sprite";
	
	this.children = new Array ();
	
	this.setPosition ([0, 0]);
	this.setCenter ([0, 0]);
	this.setSize ([0, 0]);
	this.setScale ([1, 1]);
	
	this.setAlpha (1);
	
};

Sprite.prototype = Object.create (Dispatcher.prototype);

Sprite.prototype.setPosition = function (position, y) {
	if (arguments.length == 1) {
		this.position = position.concat ();
		
	} else {
		var thisPosition = this.position;
		thisPosition [0] = position;
		thisPosition [1] = y;
		
	}
	this.needsRepositioning = true;
	this.setNeedsRedraw ();
	
};

Sprite.prototype.setCenter = function (center, centerY) {
	if (arguments.length == 1) {
		this.center = center.concat ();
		
	} else {
		var thisCenter = this.center;
		thisCenter [0] = center;
		thisCenter [1] = centerY;
		
	}
	this.needsRepositioning = true;
	this.setNeedsRedraw ();
	
};

Sprite.prototype.setScale = function (scale, scaleY) {
	if (arguments.length == 1) {
		this.scale = typeof scale != "number" ?
			scale.concat () :
			[scale, scale];
		
	} else {
		var thisScale = this.scale;
		thisScale [0] = scale;
		thisScale [1] = scaleY;
		
	}
	
	this.needsRepositioning = true;
	this.setNeedsRedraw ();
	
};

Sprite.prototype.setRotation = function (rotation) {
	if (this.rotation != rotation) {
		this.rotation = rotation;
		
		this.needsRepositioning = true;
		this.setNeedsRedraw ();
		
	}
	
};

Sprite.prototype.setSize = function (size, height) {
	if (arguments.length == 1) {
		this.size = size.concat ();
		
	} else {
		var thisSize = this.size;
		thisSize [0] = size;
		thisSize [1] = height;
		
	}
	this.needsResizing = true;
	this.setNeedsRedraw ();
	
};

Sprite.prototype.setAlpha = function (alpha) {
	alpha = Math.max (0, Math.min (1, alpha));
	alpha = Math.round (alpha * 1024) / 1024;
	
	if (this.alpha != alpha) {
		this.alpha = alpha;
		this.setNeedsRedraw ();
		
	}
	
};

Sprite.prototype.needsRedraw = true;

Sprite.prototype.setNeedsRedraw = function () {
	if (!this.needsRedraw) {
		this.needsRedraw = true;
		
		if (this.parent)
			this.parent.setNeedsRedraw ();
		
	}
	
};

Sprite.prototype.addChild = function (child) {
	this.addChildAt (child, this.children.length);
	
};

Sprite.prototype.addChildAt = function (child, index) {
	if (child.parent)
		child.parent.removeChild (child);
	
	var children = this.children;
	if (index < children.length)
		children.splice (index, 0, child);
	else
		children.push (child);
	
	child.parent = this;
	
	this.element.appendChild (child.element);
	
	this.needsSortChildren = true;
	this.setNeedsRedraw ();
	
};

Sprite.prototype.attachSprite = function (constructor) {
	var sprite = new (constructor || Sprite) (this.context);
	this.addChild (sprite);
	return sprite;
	
};

Sprite.prototype.removeChild = function (child) {
	var index = this.children.indexOf (child);
	if (index >= 0)
		this.removeChildAt (index);
	
};

Sprite.prototype.removeChildAt = function (index) {
	var children = this.children;
	
	if (index < children.length) {
		var child = children [index];
		delete child.parent;
		delete child.stage;
		
		children.splice (index, 1);
		
		child.element.parentNode.removeChild (child.element);
		
		// this.needsSortChildren = true;
		this.setNeedsRedraw ();
		
	}
	
};

Sprite.prototype.renderInContext = function (context) {
	this.needsRedraw = false;
	
	var element = this.element;
	var elementStyle = element.style;
	
	var alpha = Math.round (this.alpha * 1024);
	if (this.lastAlpha != alpha) {
		this.lastAlpha = alpha;
		
		if (alpha <= 0) {
			if (elementStyle.display != "none")
				elementStyle.display = "none";
			return;
			
		}
		
		if (!Environment.IS_IE || Environment.IE_VERSION >= 9) {
			elementStyle.opacity = alpha / 1024;
			
		} else {
			if (this.isOpaque) {
				// trace ("PASS FADE");
				var texture = this.loadTexture ? this.texture : this.element;
				if (texture) {
					var textureStyle = texture.style;
					textureStyle.display = "";
					
					if (!texture.filters || !texture.filters.alpha)
						textureStyle.filter = "alpha(opacity=100)";
						
					try {
						texture.filters.alpha.opacity = alpha / 1024 * 100;
						
					} catch (error) {}
					
				}
				
			}
			
		}
		if (elementStyle.display)
			elementStyle.display = "";
		
	}
	
	var context = this.context;
	this.renderSelfInContext (context);
	
	var children = this.children;
	var childSortFunction = this.sortChildren;
	
	var child;
	var i;
	
	if (childSortFunction && this.needsSortChildren) {
		this.needsSortChildren = false;
		
		childSortFunction.call (this);
		for (i = children.length; i--;) {
			child = children [i];
			var childStyle = child.element.style;
			if (childStyle.zIndex != i + 1)
				childStyle.zIndex = i + 1;
			
		}
		
	}
	
	for (i = children.length; i--;) {
		child = children [i];
		if (child.needsRedraw)
			child.renderInContext (context);
		
	}
	
};

Sprite.prototype.sortChildren = null;

Sprite.prototype.sortChildrenByZ = function () {
	var children = this.children;
	children.sort (function (a, b) {
		return a.depth > b.depth ? 1 : -1;
		
	});
	
};

Sprite.prototype.rotation = 0;

Sprite.prototype.renderSelfInContext = function (context) {
	var element = this.element;
	var elementStyle = element.style;
	
	var stepSize = 8192;
	
	function assignIfChanged (elementStyle, styleKey, value) {
		if (elementStyle [styleKey] != value)
			elementStyle [styleKey] = value;
		
	}
	
	function assignPixelsIfChanged (elementStyle, styleKey, value) {
		if (isFinite (value)) {
			value = Math.round (value * stepSize) / stepSize;
			assignIfChanged (elementStyle, styleKey, value + "px");
			
		} else {
			assignIfChanged (elementStyle, styleKey, value);
			
		}
		
	}
	
	if (this.needsResizing) {
		this.needsResizing = false;
		
		var size = this.size;
		var texture = this.texture;
		if (size && texture) {
			var textureStyle = texture.style;
			
			if (size [0])
				assignPixelsIfChanged (textureStyle, "width", size [0]);
			else
				textureStyle.width = "";
			
			if (size [1])
				assignPixelsIfChanged (textureStyle, "height", size [1]);
			else
				textureStyle.height = "";
			
		}
		
	}
	
	if (this.needsRepositioning) {
		this.needsRepositioning = false;
		
		var position = this.position;
		var center = this.center;
		var scale = this.scale;
		var rotation = this.rotation % (Math.PI * 2);
		
		if (!Environment.ENABLE_CSS_TRANSFORM || (!this.renderUsingCSSTransform && scale [0] == 1 && scale [1] == 1 && !rotation)) {
			assignPixelsIfChanged (elementStyle, "left", position [0] - center [0]);
			assignPixelsIfChanged (elementStyle, "top", position [1] - center [1]);
			
			if (this.loadTexture) {
				var textureSize = this.size;
				if (textureSize) {
					var texture = this.texture;
					
					if (textureSize [0])
						texture.style.width = Math.round (textureSize [0] * scale [0]) + "px";
					if (textureSize [1])
						texture.style.height = Math.round (textureSize [1] * scale [1]) + "px";
					
				}
				
			}
			assignIfChanged (elementStyle, Environment.VENDOR_PREFIX + "Transform", "");
			assignIfChanged (elementStyle, "transform", "");
			
		} else {
			assignIfChanged (elementStyle, "left", "");
			assignIfChanged (elementStyle, "top", "");
			
			var transform =
				"translate(" + (Math.round ((position [0] - center [0]) * stepSize) / stepSize) + "px, " + (Math.round ((position [1] - center [1]) * stepSize) / stepSize) + "px) " +
				"scale(" + (Math.round (scale [0] * stepSize) / stepSize) + ", " + (Math.round (scale [1] * stepSize) / stepSize) + ")" +
				(rotation ? " rotate(" + (Math.round (rotation * stepSize) / stepSize) + "rad)" : "");
			
			if (this.renderAsLayer)
				transform += " translateZ(0)";
			
			var transformOrigin = (Math.round (center [0] * stepSize) / stepSize) + "px " + (Math.round (center [1] * stepSize) / stepSize) + "px";
			
			if (Environment.IS_WEBKIT) {
				assignIfChanged (elementStyle, "webkitTransformOrigin", transformOrigin);
				assignIfChanged (elementStyle, "webkitTransform", transform);
				
			} else {
				assignIfChanged (elementStyle, "transformOrigin", transformOrigin);
				assignIfChanged (elementStyle, "transform", transform);
				
			}
			
		}
		
	}
	
};

Sprite.prototype.getStage = function () {
	return this.context.stage;
	
};

Sprite.supportedMouseEventMap = [
	"mousedown", "mousemove", "mouseup",
	"click", "dblclick",
	"mouseover", "mouseout",
	"touchstart", "touchmove", "touchcancel", "touchend"
	
].toMap ();

Sprite.touchEventSubstitues = {
	mousedown: ["touchstart"],
	mousemove: ["touchmove"],
	mouseup: ["touchcancel", "touchend"]
	
};

Sprite.prototype.addListener = function (eventType, callback, listener) {
	var listenerDescriptions = this.events [eventType];
	var lastListenerCount = listenerDescriptions ?
		listenerDescriptions.length : 0;
	
	Dispatcher.prototype.addListener.apply (this, arguments);
	
	if (!lastListenerCount && Sprite.supportedMouseEventMap [eventType] && this.events [eventType].length == 1) {
		var that = this;
		var mouseListenerTarget = this.getMouseListenerTarget (eventType);
		
		if (!this.processMouseEventHandler) {
			this.processMouseEventHandler = function (event) {
				if (!event)
					event = window.event;
				
				var currentTarget;
				switch (event.type) {
					case "mouseover":
						currentTarget = event.relatedTarget || event.fromElement;
						break;
					case "mouseout":
						currentTarget = event.relatedTarget || event.toElement;
						break;
					
				}
				while (currentTarget) {
					if (currentTarget == that.element)
						return;
					
					currentTarget = currentTarget.parentNode;
					
				}
				if (event.type.indexOf ("touch") == 0) {
					var touchItem = event.touches.item (0);
					if (touchItem) {
						event.clientX = touchItem.clientX;
						event.clientY = touchItem.clientY;
						
					}
					
				}
				that.dispatchMouseEvent (event);
				
			};
			
		}
		if (!mouseListenerTarget.addEventListener)
			augmentDOMEventDispatcher (mouseListenerTarget);
		
		mouseListenerTarget.addEventListener (eventType, this.processMouseEventHandler, false);
		
	}
	
	if (Environment.IS_TOUCH_DEVICE) {
		var substitutes = Sprite.touchEventSubstitues [eventType];
		if (substitutes) {
			for (var i = substitutes.length; i--;)
				this.addListener (substitutes [i], callback, listener);
			
		}
		
	}
	
};

Sprite.prototype.getMouseListenerTarget = function (eventType) {
	return this.element;
	
};

Sprite.prototype.removeListener = function (eventType, callback, listener) {
	if (!this.events [eventType])
		return;
	
	Dispatcher.prototype.removeListener.apply (this, arguments);
	
	if (Sprite.supportedMouseEventMap [eventType] && !this.events [eventType]) {
		var mouseListenerTarget = this.getMouseListenerTarget (eventType);
		mouseListenerTarget.removeEventListener (eventType, this.processMouseEventHandler, false);
		
	}
	
	if (Environment.IS_TOUCH_DEVICE) {
		var substitutes = Sprite.touchEventSubstitues [eventType];
		if (substitutes) {
			for (var i = substitutes.length; i--;)
				this.removeListener (substitutes [i], callback, listener);
			
		}
		
	}
	
};

Sprite.prototype.dispatchMouseEvent = function (event) {
	this.currentEvent = event;
	this.dispatchEvent (event.type);
	
};

Sprite.boundingRectForElement = function (element) {
	var bounds = element.getBoundingClientRect ();
	bounds = {
		left: bounds.left + window.pageXOffset || document.documentElement.scrollLeft,
		top: bounds.top + window.pageYOffset || document.documentElement.scrollTop,
		width: bounds.width,
		height: bounds.height
		
	};
	return bounds;
	
};

Sprite.prototype.touchDescriptionForObject = function (touchObject) {
	var bounds = this.element.getBoundingClientRect ();
	var mouse = touchObject.touches ? [
		touchObject.touches [0].clientX - bounds.left,
		touchObject.touches [0].clientY - bounds.top
		
	] : [
		touchObject.clientX - bounds.left,
		touchObject.clientY - bounds.top
		
	];
	mouse.time = new Date ().getTime ();
	return mouse;
	
};

Sprite.prototype.toString = function () {
	return this.name ? "[Sprite " + this.name + "]" : "[Sprite]";
	
};

Sprite.colourStringForColour = function (colour) {
	if (Number (colour) == colour) {
		var colourString = colour.toString (16);
		return "#" + ("000000".substring (colourString.length)) + colourString;
		
	} else {
		return colour;
		
	}
	
};

//
// AnimatableSprite extends Sprite
//

export const AnimatableSprite = function (context) {
	Sprite.apply (this, arguments);
	Animatable.apply (this);
	
};

AnimatableSprite.prototype = Object.create (Sprite.prototype);
Animatable.extendConstructor (AnimatableSprite);

AnimatableSprite.prototype.fadeIn = function (rate, delay) {
	this.startAnimation ("Fade", {direction: 1, rate: .05 * (rate || 1), phase: this.alpha, delay: delay});
	
};

AnimatableSprite.prototype.fadeOut = function (rate, delay) {
	this.startAnimation ("Fade", {direction: -1, rate: .05 * (rate || 1), phase: this.alpha, delay: delay});
	
};

AnimatableSprite.prototype.animateFade = function () {
	var state = this.states ["Fade"];
	
	if (state.delay && state.delay--) {
		this.setAlpha (state.phase);
		return;
		
	}
	state = this.updatedState ("Fade");
	this.setAlpha (state.phase);
	
};

//
// AnimatableSprite extensions
//

AnimatableSprite.prototype.snapBack = function () {
  var position = this.position;
  var targetPosition = this.targetPosition || [0, 0];
  
  if (position [0] != targetPosition [0] ||
	  position [1] != targetPosition [1])
	  this.addRunLoopHandler ("processSnapBack");
  
};

AnimatableSprite.prototype.processSnapBack = function () {
  if (this.snapDelay && --this.snapDelay)
	  return;
  
  var position = this.position;
  var targetPosition = this.targetPosition || [0, 0];
  
  var delta = [
	  targetPosition [0] - position [0],
	  targetPosition [1] - position [1]
	  
  ];
  
  var t = .19; // .15;
  
  if (delta [0] * delta [0] + delta [1] * delta [1] < 1) {
	  this.removeRunLoopHandler ("processSnapBack");
	  this.dispatchEvent ("completeSnap");
	  
	  this.setPosition (targetPosition);
	  
  } else {
	  this.setPosition (
		  position [0] + delta [0] * t,
		  position [1] + delta [1] * t
		  
	  );
	  
  }
  
};

AnimatableSprite.prototype.slideTo = function (targetPosition, rate) {
  if (!this.targetPosition) {
	  this.targetPosition = targetPosition;
	  this.setPosition (targetPosition);
	  
  } else {
	  this.startPosition = this.position.concat ();
	  this.targetPosition = targetPosition;
	  this.startAnimation ("Slide", {direction: 1, rate: .05 * (rate || 1), phase: 0});
	  
  }
  
};

AnimatableSprite.prototype.animateSlide = function (targetPosition) {
	var state = this.states ["Slide"];
	if (state.delay && state.delay--)
		return;

	state = this.updatedState ("Slide");
	var t = state.phase;

	if (state.direction > 0) {
		t = 1 - t;
		t = 1 - t * t * t;
	  
	} else {
		t = t * t;
	  
	}

	if (this.slideFunction)
		t = this.slideFunction (t);
	else
		t = .5 - Math.cos (t * Math.PI) / 2;
	
	var t_ = 1 - t;
	
	var startPosition = this.startPosition;
	var targetPosition = this.targetPosition;

	this.setPosition (
		Math.round (startPosition [0] * t_ + targetPosition [0] * t),
		Math.round (startPosition [1] * t_ + targetPosition [1] * t)
		
	);
  
};

//
// TexturedSprite extends AnimatableSprite
//

export const TexturedSprite = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

TexturedSprite.prototype = Object.create (AnimatableSprite.prototype);

TexturedSprite.prototype.loadTexture = function (path) {
	if (this.path == path)
		return;
	this.path = path;
	
	var job = this.currentJob;
	if (job)
		job.removeListener ("complete", this.completeImage, this);
	
	var loader = LoaderSystem.getSharedInstance ();
	job = this.currentJob = loader.jobForPathOfClass (
		path, ImageLoaderJob, this.skipCache
		
	);
	
	if (!job.priorityClosure) {
		var stage = this.getStage ();
		if (!this.priorityClosure && stage && stage.getDefaultLoadingPriority) {
			job.priorityClosure = function () {
				return stage.getDefaultLoadingPriority ();
				
			};
			
		} else {
			job.priorityClosure = this.priorityClosure;
			
		}
		
	}
	
	if (job.state != LoaderJob.STATE_DONE) {
		job.addListener ("complete", this.completeImage, this);
		loader.enqueueJob (job);
		
	} else {
		this.completeImage (job);
		
	}
	
};

TexturedSprite.prototype.completeImage = function (job) {
	job.removeListener ("complete", this.completeImage, this);
	delete this.currentJob;
	
	var lastTexture = this.texture;
	var texture = this.texture = job.image.cloneNode (true);
	var textureSize = this.textureSize = [
		Number (job.image.naturalWidth || job.image.width),
		Number (job.image.naturalHeight || job.image.height)
		
	];
	if (this.measureOnStage || !textureSize [0]) {
		/*
		if (textureSize [0] == job.image.offsetHeight &&
			textureSize [1] == job.image.offsetWidth) {
			trace ("IMAGE IS ROTATED");
			
		}
		*/
		
		document.body.appendChild (job.image);
		textureSize = this.textureSize = [
			job.image.offsetWidth,
			job.image.offsetHeight
			
		];
		document.body.removeChild (job.image);
		
	}
	
	if (!this.dontAssignTextureClass)
		texture.className = "sprite";
	
	if (lastTexture)
		TexturedSprite.copyStyles (lastTexture.style, texture.style);
	else
		this.element.style.display = "none";
	
	var element = this.element;
	if (lastTexture)
		element.removeChild (lastTexture);
	
	if (element.childNodes.length)
		element.insertBefore (texture, element.childNodes [0]);
	else
		element.appendChild (texture);
	
	var size = this.size;
	if (!size [0])
		size [0] = textureSize [0];
	if (!size [1])
		size [1] = textureSize [1];
	
	var imageCenter = this.imageCenter;
	if (imageCenter)
		this.setCenter (
			textureSize [0] * imageCenter [0],
			textureSize [1] * imageCenter [1]
			
		);
	
	this.isComplete = true;
	this.needsResizing = true;
	this.setNeedsRedraw ();
	
	this.dispatchEvent ("complete");
	
};

// TexturedSprite.prototype.imageCenter = [.5, .5];

TexturedSprite.copyStyles = function (sourceStyle, targetStyle) {
	if (Environment.IS_IE && Environment.IE_VERSION < 9) {
		targetStyle ["top"] = sourceStyle ["top"];
		targetStyle ["left"] = sourceStyle ["left"];
		targetStyle ["width"] = sourceStyle ["width"];
		targetStyle ["height"] = sourceStyle ["height"];
		
		if (this.isOpaque)
			targetStyle ["filter"] = sourceStyle ["filter"];
		
	} else {
		for (var i = 0; i < sourceStyle.length; i++)
			targetStyle [sourceStyle [i]] = sourceStyle [sourceStyle [i]];
		
	}
	
};

TexturedSprite.prototype.renderSelfInContext = function (context) {
	if (!this.isComplete)
		return;
	
	AnimatableSprite.prototype.renderSelfInContext.apply (this, arguments);
	
	var element = this.element;
	var elementStyle = element.style;
	
	var texture = this.texture;
	var textureStyle = texture.style;
	
	var alpha = this.alpha;
	if (alpha <= 0) {
		if (elementStyle.display != "none")
			elementStyle.display = "none";
		return;
		
	}
	
	if (elementStyle.display)
		elementStyle.display = "";
	
};

//
// class ImageLoaderJob extends Dispatcher
//

var ImageLoaderJob = function (path) {
	LoaderJob.apply (this, arguments);
	
};

ImageLoaderJob.prototype = Object.create (LoaderJob.prototype);

ImageLoaderJob.prototype.load = function () {
	LoaderJob.prototype.load.apply (this, arguments);
	
	var image = this.currentLoadingImage = new Image ();
	var that = this;
	
	if (!image.addEventListener)
		augmentDOMEventDispatcher (image);
	
	image.addEventListener ("load", function (event) {
		that.completeLoading (image);
		
	}, false);
	image.addEventListener ("error", function (event) {
		that.completeLoading (image);
		
	}, false);
	
	// console.log ("loading", this.path, image.complete);
	
	image.src = this.path;
	
	if (image.complete && !this.image)
		this.completeLoading (image);
	
};

ImageLoaderJob.prototype.completeLoading = function (image) {
	delete this.currentLoadingImage;
	
	this.image = image;
	// console.log ("loaded", image.src);
	
	LoaderJob.prototype.completeLoading.apply (this, arguments);
	
};

//
// SolidSprite extends AnimatableSprite
//

export const SolidSprite = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	var texture = this.texture = this.element;
	
};

SolidSprite.prototype = Object.create (AnimatableSprite.prototype);

SolidSprite.prototype.isComplete = true;

SolidSprite.prototype.setColour = function (colour) {
	var colourString = Sprite.colourStringForColour (colour);
	var textureStyle = this.texture.style;
	
	if (textureStyle.backgroundColor != colourString)
		textureStyle.backgroundColor = colourString;
	
};

SolidSprite.prototype.loadTexture = TexturedSprite.prototype.loadTexture;

SolidSprite.prototype.completeImage = function (job) {
	var texture = this.texture;
	
	job.removeListener ("complete", this.completeImage, this);
	delete this.currentJob;
	
	texture.style.backgroundImage = "url(" + this.path + ")";
	
};

//
// CanvasSprite extends AnimatableSprite
//

var CanvasSprite = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	var texture = this.texture = document.createElement ("canvas");
	var canvasContext = this.canvasContext = texture.getContext ("2d");
	
	texture.className = "sprite";
	this.element.appendChild (texture);
	
};

CanvasSprite.prototype = Object.create (AnimatableSprite.prototype);

CanvasSprite.prototype.setTextureSize = function (textureSize) {
	this.textureSize = textureSize;
	var texture = this.texture;
	
	texture.setAttribute ("width", textureSize [0]);
	texture.setAttribute ("height", textureSize [1]);
	
};

//
// CroppingSprite extends SolidSprite
//

var CroppingSprite = function (context) {
	TexturedSprite.apply (this, arguments);
	
	var texture = this.texture = this.element;
	texture.style.overflow = "hidden";
	
};

CroppingSprite.prototype = Object.create (SolidSprite.prototype);

//
// ContainerSprite extends AnimatableSprite
//

var ContainerSprite = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

ContainerSprite.prototype = Object.create (AnimatableSprite.prototype);

ContainerSprite.prototype.takeOverElement = function (texture) {
	this.texture = texture;
	
	texture.className += " sprite";
	this.estimateTextureSize ();
	
	this.element.style.display = "none";
	this.element.style.top = "-" + (8192 * 2) + "px";
	this.element.style.left = "-" + (8192 * 2) + "px";
	this.element.appendChild (texture);
	this.isComplete = true;
	
};

Sprite.prototype.estimateTextureSize = function () {
	var texture = this.texture;
	var textureParent = texture.parentNode;
	
	var textureSize = this.textureSize;
	if (!textureSize) {
		textureSize = this.textureSize = [
			texture.clientWidth,
			texture.clientHeight
			
		];
		if (!(textureSize [0] && textureSize [1])) {
			texture.style.display = "block";
			document.body.appendChild (texture);
			
			this.textureSize = [
				texture.clientWidth,
				texture.clientHeight
				
			];
			// texture.style.display = "none";
			
		}
		// throw new Error ("break. texture size is " + this.textureSize + ".");
		
	}
	
	if (textureParent)
		textureParent.appendChild (texture);
	
};

Sprite.prototype.reestimateTextureSize = function () {
	var texture = this.texture;
	texture.style.width = "";
	texture.style.height = "";
	
	delete this.textureSize;
	
	this.estimateTextureSize ();
	
	// trace ("reestimated", this.textureSize.width, "x", this.textureSize.height);
	
};

ContainerSprite.prototype.listenForToggling = function () {
	this.getStage ().addListener ("toggleMode", this.reestimateTextureSize, this);
	
};

//
// Label extends Sprite
//

var Label = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	var texture = this.texture = this.element;
	texture.className += " textbox"
	texture.style.display = "none";
	
};

Label.prototype = Object.create (AnimatableSprite.prototype);

Label.prototype.fixedTextureScale = true;

Label.prototype.takeDescription = function (description) {
	this.takeDescriptions ([description]);
	
};

Label.prototype.takeDescriptionApplyingWordWrap = function (description) {
	var labelText = description.labelText;
	if (labelText.indexOf ("\n") < 0) {
		this.takeDescription (description);
		
	} else {
		var descriptions = new Array ();
		var textLines = labelText.split ("\n");
		for (var i = 0; i < textLines.length; i++) {
			descriptions.push ({
				elementNames: description.elementNames,
				className: description.className,
				labelText: textLines [i]
				
			});
			
		}
		this.takeDescriptions (descriptions);
		
	}
	
};

Label.prototype.takeDescriptions = function (descriptions, containerElement, textContainerElement) {
	this.descriptions = descriptions;
	
	var texture = this.texture;
	while (!textContainerElement && texture.childNodes.length)
		texture.removeChild (texture.firstChild);
	
	if (containerElement)
		texture.appendChild (containerElement);
	else
		containerElement = textContainerElement = texture;
	
	for (var i = 0; i < descriptions.length; i++) {
		var description = descriptions [i];
		if (!description)
			continue;
		
		var box = document.createElement (description.elementName || "div");
		box.className = description.className;
		
		var lines = description.labelText.split ("\n");
		for (var j = 0; j < lines.length; j++) {
			if (j)
				box.appendChild (document.createElement ("br"));
			
			var textNode = document.createTextNode (lines [j]);
			box.appendChild (textNode);
			
		}
		textContainerElement.appendChild (box);
		
	}
	
	this.isComplete = true;
	
};

Label.prototype.estimateTextureSize = function () {
	var element = this.element;
	var texture = this.texture;
	
	var displayStyle = element.style.display;
	element.style.display = "";
	
	var elementParent = element.parentNode;

	document.body.appendChild (element);
	
	var textureSize = this.textureSize = /* this.measureContainerElement ? [
		element.offsetWidth,
		element.offsetHeight
		
	] : */ [
		texture.offsetWidth,
		texture.offsetHeight
		
	];
	
	// if (element.className.indexOf ("detail-info") >= 0)
	//		throw new Error ("break.");
	
	if (elementParent)
		elementParent.appendChild (element);
	else
		document.body.removeChild (element);
	
	
	element.style.display = displayStyle;
	
};

ContainerSprite.prototype.estimateTextureSize = Label.prototype.estimateTextureSize;

//
// Button extends AnimatableSprite
//

var Button = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.element.className += " button";
	this.setState (Button.STATE_NONE);
	
	if (!IS_TOUCH_DEVICE)
		this.addListener ("mousedown", this.mouseDownButton, this);
	
};

Button.STATE_NONE = 0;
Button.STATE_HOVERED = 1;
Button.STATE_PRESSED = 2;
Button.STATE_ACTIVE = 4;

Button.prototype = Object.create (AnimatableSprite.prototype);

Button.prototype.state = Button.STATE_NONE;

Button.prototype.setState = function (mask, doSetBits) {
	this.state = doSetBits ?
		this.state | mask :
		this.state & ~mask;
	
};

Button.prototype.takeDescription = function (description) {
	this.description = description;
	
	if (description.imagePath) {
		var image = this.image = this.attachSprite (TexturedSprite);
		image.loadTexture (description.imagePath);
		
	}
	
};

Button.prototype.mouseDownButton = function (button) {
	stopEventPropagation (button.currentEvent);
	cancelEvent (button.currentEvent);
	
};

//
// Stage extends AnimatableSprite
//

export const Stage = function (context, layerCSSRule) {
	AnimatableSprite.apply (this, arguments);
	
	context.stage = this;
	this.element = context.rootElement;
	
	if (this.shouldAutoResize)
		this.resizeContext (context);
	
	context.addListener ("resize", this.resizeContext, this);
	
	this.ticks = 0;
	
	if (this.shouldAutoAwake)
		this.awake ();
	
};

Stage.prototype = Object.create (AnimatableSprite.prototype);

Stage.id = 0;

Stage.fromElement = function (element) {
	element.className += " sprite";
	element.style.left = element.style.top = "0px";
	
	var context = RenderContext.fromElement (element);
	var stage = new this (context);
	
	return stage;
	
};

Stage.prototype.shouldAutoAwake = true;
Stage.prototype.shouldAutoResize = true;

Stage.prototype.awake = function () {
	if (this.isAwake)
		return;
	this.isAwake = true;
	
	// trace ("--- " + this + " awakening ---");
	
	this.startRenderLoop ();
	
};

Stage.prototype.sleep = function () {
	if (!this.isAwake)
		return;
	this.isAwake = false;
	
	// trace ("--- " + this + " going to sleep ---");
	
	this.stopRenderLoop ();
	
};

Stage.prototype.resizeContext = function (context) {
	var contextSize = context.getWindowSize ();
	
	this.setSize (contextSize);
	
};

Stage.prototype.setSize = function (size) {
	this.size = size;
	
	this.dispatchEvent ("resize");
	
};

Stage.prototype.startRenderLoop = function () {
	var animationTimer = this.context.animationTimer;
	animationTimer.addListener ("fire", this.renderNextFrame, this);
	
};

Stage.prototype.stopRenderLoop = function () {
	var animationTimer = this.context.animationTimer;
	animationTimer.removeListener ("fire", this.renderNextFrame, this);
	
	// trace ("--- stopped render loop. ---");
	
};

Stage.prototype.renderNextFrame = function () {
	this.ticks += 1;
	this.context.dispatchEvent ("enterFrame");
	
	try {
		if (this.needsRedraw) {
			var context = this.context;
			context.renderMode =
				RenderContext.RENDER_MODE_DISPLAY;
			
			// this.context.dispatchEvent ("renderFrame");
			this.renderInContext (context);
			
			if (this.alpha && this.element.style.display != "")
				this.element.style.display = "";
			
		}
		
	} catch (error) {
		console.log ("## aborting render loop.");
		this.stopRenderLoop ();
		throw error;
		
	}
	
};

Stage.IE_EVENTS_NOT_IN_WINDOW = [
	"mousedown", "mousemove", "mouseup", "click"
	
].toMap ();

Stage.prototype.getMouseListenerTarget = function (eventType) {
	return Environment.IS_IE && Environment.IE_VERSION <= 8 && Stage.IE_EVENTS_NOT_IN_WINDOW [eventType] ?
		document.body : window;
	
};

//
// Stage extensions
//

Stage.prototype.setBodyClass = function (bodyClass, priority) {
	if (IS_TOUCH_DEVICE)
		return;
	
	var bodyClasses = this.bodyClasses;
	if (!bodyClasses)
		bodyClasses = this.bodyClasses = new Array ();
	
	if (!bodyClass)
		bodyClass = "";
	if (isNaN (priority))
		priority = 1;
	
	if (bodyClasses [priority] != bodyClass) {
		bodyClasses [priority] = bodyClass;
		
		var bodyClassName = "";
		for (var i = 0; i < bodyClasses.length; i++) {
			var className = bodyClasses [i];
			if (!className)
				continue;
			
			bodyClassName = className;
			break;
			
		}
		
		this.bodyClassName = bodyClassName;
		document.body.className = bodyClassName + (this.className ? " " + this.className : "");
		
	}
	
};

//
// Stage extensions
//

Stage.prototype.createRulers = function () {
	if (this.horizontalRuler)
		return;
	
	var horizontalRuler = this.horizontalRuler = document.querySelector (".horizontal-ruler");
	if (horizontalRuler) {
		var verticalRuler = this.verticalRuler = document.querySelector (".vertical-ruler");
		
	} else {
		horizontalRuler = this.horizontalRuler = document.createElement ("div");
		horizontalRuler.className = "horizontal-ruler";
		document.body.appendChild (horizontalRuler);
		
		var verticalRuler = this.verticalRuler = document.createElement ("div");
		verticalRuler.className = "vertical-ruler";
		document.body.appendChild (verticalRuler);
		
	}
	
};

Stage.prototype.getRulerSize = function () {
	this.createRulers ();
	
	var horizontalRulerBounds = this.horizontalRuler.getBoundingClientRect ();
	var verticalRulerBounds = this.verticalRuler.getBoundingClientRect ();
	
	return [
		horizontalRulerBounds.right - horizontalRulerBounds.left,
		verticalRulerBounds.bottom - verticalRulerBounds.top
		
	];
	
};

Stage.prototype.createMediaProbes = function (breakpointSizes) {
	this.breakpointSizes = breakpointSizes;
	
	var probeContainer = document.createElement ("div");
	probeContainer.style.position = "fixed";
	probeContainer.style.left = "-1px";
	probeContainer.style.top = "-1px";
	
	var probeElements = this.probeElements = new Array ();
	var probeElementMap = this.probeElementMap = new Object ();
	
	function createProbeElement (breakpointSize) {
		var element = document.createElement ("span");
		element.className = "visible-" + breakpointSize;
		
		probeContainer.appendChild (element);
		probeElements.push (element);
		probeElementMap [breakpointSize] = element;
		
		return element;
		
	}
	
	for (var i = breakpointSizes.length; i--;)
		createProbeElement (breakpointSizes [i]);
	
	document.body.appendChild (probeContainer);
	
};

Stage.prototype.probeIfBreakpointVisible = function (breakpointSize, testForNone) {
	var probeElementMap = this.probeElementMap;
	var testElement = probeElementMap [breakpointSize];
	if (!testElement)
		return false;
	
	var testElementStyle = window.getComputedStyle ?
		window.getComputedStyle (testElement, null) : testElement.currentStyle;
	
	return testForNone ?
		testElementStyle.display != "none" :
		testElementStyle.display == "block";
	
};

Stage.prototype.getCurrentBreakpoint = function () {
	var currentBreakpoint = this.currentBreakpoint;
	if (currentBreakpoint &&
		this.probeIfBreakpointVisible (currentBreakpoint))
		return currentBreakpoint;
	
	var breakpointSizes = this.breakpointSizes;
	for (var i = breakpointSizes.length; i--;) {
		var breakpointSize = breakpointSizes [i];
		if (this.probeIfBreakpointVisible (breakpointSize))
			return this.currentBreakpoint = breakpointSize;
		
	}
	return this.currentBreakpoint = undefined;
	
};
