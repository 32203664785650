import * as Environment from "../base/Environment.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// ImageSequencer extends SiteSection
//

export const ImageSequencer = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ImageSequencer = ImageSequencer;

ImageSequencer.prototype = Object.create (SiteSection.prototype);

ImageSequencer.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	this.parseChildren ();
	
	this.rotationFrequency = (
		Math.max (2000, parseFloat (element.getAttribute ("data-auto-rotation-frequency")) * 1000)
		
	) || 4000;
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		const mutationObserver = new MutationObserver (this.mutateElement.bind (this));
		mutationObserver.observe (this.element.parentNode.parentNode, {
			childList: true,
			subtree: true
			
		});
		
	}
		
};

ImageSequencer.prototype.parseChildren = function () {
	const element = this.element;
	
	const images = this.images = Array.prototype.slice.call (
		element.querySelectorAll ("picture.lazyload"), 0
		
	);
	 
	for (let i = images.length; i--;) {
		const image = images [i];
		image.classList.add ("lazyload--started");
		image.style.display = i > 0 ? "none" : "";
		
	}
	
};

ImageSequencer.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	this.addRunLoopHandler ("processRotation");
	
};


ImageSequencer.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	this.removeRunLoopHandler ("processRotation");
	
};

ImageSequencer.prototype.startLoading = function () {
	SiteSection.prototype.startLoading.apply (this, arguments);
	
	const image = this.images [0];
	if (image) {
		image.started = true;
		LazyLoader.getSharedInstance ().loadImageAndListen (image);
		
	}
	
};

ImageSequencer.prototype.processRotation = function () {
	const images = this.images;
	if (images.length < 2)
		return;
	
	const now = new Date ().getTime ();
	if (now < this.nextRotationtime)
		return;
	
	const currentImage = images [images.length - 1];
	if (currentImage.started &&
		!currentImage.classList.contains ("lazyload--complete"))
		return;
	
	this.lastImage = this.currentImage;
	this.nextRotationtime = now + this.rotationFrequency - (this.lastImage ? 0 : 2000);
	
	const image = this.currentImage = images.shift ();
	images.push (image);
	
	if (image.classList.contains ("lazyload--complete")) {
		this.updateVisibility ();
		
	} else if (!image.started) {
		image.started = true;
		image.lastElementChild.addEventListener ("load", this.updateVisibility.bind (this));
		LazyLoader.getSharedInstance ().loadImageAndListen (image);
		
		this.didStartLoading = true;
		
	}
	
	const nextImage = images [0];
	if (!nextImage.started) {
		window.setTimeout (function () {
			if (!nextImage.started && image.classList.contains ("lazyload--complete")) {
				nextImage.started = true;
				LazyLoader.getSharedInstance ().loadImageAndListen (nextImage);
				
			}
			
		}, this.rotationFrequency * .5);
		
	}
	
};

ImageSequencer.prototype.updateVisibility = function (event) {
	const lastFadeImage = this.lastFadeImage = this.lastImage;
	const currentFadeImage = this.currentFadeImage = this.currentImage;
	
	if (lastFadeImage)
		this.startAnimation ("FadeOver", {direction: 1, rate: .0075, phase: 0});
	
	const now = new Date ().getTime ();
	if (this.nextRotationtime < now + this.rotationFrequency - 250)
		this.nextRotationtime = now + this.rotationFrequency;
	
};

ImageSequencer.prototype.animateFadeOver = function () {
	const state = this.updatedState ("FadeOver");
	
	const lastFadeImage = this.lastFadeImage;
	const currentFadeImage = this.currentFadeImage;
	
	currentFadeImage.style.zIndex = 1;
	
	currentFadeImage.style.display = "";
	currentFadeImage.style.opacity = "";
	
	if (lastFadeImage == currentFadeImage)
		return;
	
	lastFadeImage.style.zIndex = 2;
	
	let t = state.phase;
	t = .5 - Math.cos (t * Math.PI) * .5;
	
	if (t < 1) {
		lastFadeImage.style.display = "";
		lastFadeImage.style.opacity = 1 - t;
		
	} else {
		lastFadeImage.style.display = "none";
		
	}
	
};

ImageSequencer.prototype.mutateElement = function (stage) {
	if (this.isMutating)
		return;

	const activeElement = document.activeElement;
	if (activeElement && activeElement.getAttribute ("contenteditable") == "true")
		return;
	
	const now = new Date ().getTime ();
	const nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

ImageSequencer.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	const now = new Date ().getTime ();
	const nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	this.isMutating = true;
	
	this.parseChildren ();
	this.updateVisibility ();
	
	window.setTimeout (function () {
		this.updateVisibility ();
		
		this.isMutating = false;
		
	}.bind (this), 50);
	
};

