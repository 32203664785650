import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// GallerySection extends SiteSection
//

export const GallerySection = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.GallerySection = GallerySection;

GallerySection.prototype = Object.create (SiteSection.prototype);

GallerySection.prototype.getGalleryConstructor = function () {
	return Gallery;
	
};

GallerySection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	var gallery = this.gallery = this.attachSprite (this.getGalleryConstructor ());
	
	gallery.takeElement (
		element.classList.contains ("gallery") ?
			element : element.querySelector (".gallery")
		
	);
	gallery.contentContainer.element.style.visibility = "hidden";
	
};

GallerySection.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.isAutoRotationLocked = false;
	
};

GallerySection.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.isAutoRotationLocked = true;
	
};

GallerySection.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.updateLayout ();
	
	window.setTimeout (function () {
		gallery.updateLayout ();
		gallery.contentContainer.element.style.visibility = "";
		
	}, 50);
	
};

//
// Gallery extends AnimatableSprite, SlidingSpritePrototype
//

export const Gallery = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

Gallery.prototype = Object.create (AnimatableSprite.prototype);
Gallery.extendPrototype (SlidingSpritePrototype);

Gallery.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

Gallery.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	element.style.height = "auto";
	element.style.visibility = "visible";
	
	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");
	
	SlidingSpritePrototype.apply (this, arguments);
	
	const isRTEGallery = this.isRTEGallery = element.classList.contains ("rte-gallery");
	
	var fitting = this.fitting = element.getAttribute ("data-fitting");
	var adjustHeightToContent = this.adjustHeightToContent = isRTEGallery || fitting == "content";
	
	if (!adjustHeightToContent) {
		fitting = fitting.split (":");
		var aspectRatio = this.aspectRatio =
			parseFloat (fitting [1]) /
			parseFloat (fitting [0]);
		
	}
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	
	element.removeChild (contentContainer.element);
	contentContainer.element = element.querySelector (".gallery__slide-container");
	
	this.parseChildren ();
	
	this.finishSetup ();
	
};

Gallery.prototype.parseChildren = function () {
	var element = this.element;
	var context = this.context;
	
	var slides = this.slides = new Array ();
	
	var contentContainer = this.contentContainer;
	var children = (Environment.IS_IN_NEOS_EDITOR ? element : element).querySelectorAll (".gallery__slide");
	
	var numSlides = this.numSlides = children.length;
	
	var firstChildParent = children [0];
	
	firstChildParent = firstChildParent && firstChildParent.slide;
	firstChildParent = firstChildParent && firstChildParent.element.parentNode;
	
	for (var i = 0; i < numSlides; i++) {
		var child = children [i];
		
		var slide = child.slide;
		if (child.slide) {
			slides.push (slide);
			
		} else {
			slide = child.slide = new GallerySlide (context);
			slide.parentGallery = this;
			
			slide.takeElement (child);
			slide.setAlpha (0);
			
			slides.push (slide);
			
		}
		
	}
	
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		slide.index = i;
		
		var child = slide.element;
		
		var slideParentNode = firstChildParent || child.parentNode;
		contentContainer.addChild (slide);
		slideParentNode.appendChild (slide.element);
		
	}
	
	var captionContainer = this.captionContainer = element.querySelector (".gallery__caption-footer");
	
	if (!Environment.IS_IN_NEOS_EDITOR) {
		const minNumEntries = 0; // this.adjustHeightToContent ? 9 : 3;
		
		while (slides.length > 1 && slides.length < minNumEntries) {
			for (var i = 0; i < numSlides; i++) {
				var child = children [i].cloneNode (true);
				
				slide = child.slide = new GallerySlide (context);
				slide.index = slides.length;
				slide.takeElement (child);
				slide.setAlpha (0);
				
				contentContainer.addChild (slide);
				
				slides.push (slide);
				
			}
			
		}
		
		if (captionContainer) {
			for (var i = slides.length; i--;) {
				var slide = slides [i];
				var caption = slide.caption;
				
				if (caption)
					captionContainer.appendChild (caption);
				
			}
			
		}
		
	}
	
	if (numSlides == 1) {
		window.setTimeout (function () {
			slides [0].startLoading ();
			
		}.bind (this), 125);
		
	};
	
};

Gallery.prototype.takeSlides = function (slides, navigationElement) {
	var element = this.element;
	element.classList.add ("gallery");
	
	SlidingSpritePrototype.apply (this, arguments);
	
	this.slides = slides;
	var numSlides = this.numSlides = slides.length;
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	contentContainer.element.classList.add ("gallery__slide-container");
	
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		slide.index = i;
		
		contentContainer.addChild (slide);
		
	}
	
	if (navigationElement) {
		this.navigationElement = navigationElement;
		this.element.appendChild (navigationElement);
		
	}
	
	this.finishSetup ();
	
};

Gallery.prototype.finishSetup = function (viewSize) {
	var element = this.element;
	
	var navigationElement = this.navigationElement = element.querySelector (".gallery__navigation");
	if (navigationElement)
		this.contentContainer.element.appendChild (navigationElement);
	
	const numSlides = this.numSlides;
	
	if (numSlides > 1 || Environment.IS_IN_NEOS_EDITOR) {
		var scrollingController = this.scrollingController;
		
		if (!Environment.IS_IN_NEOS_EDITOR) {
			scrollingController.stage = this.getStage ();
			scrollingController.getMouseListenerTarget = function (eventType) {
				return element;
				
			};
			
			scrollingController.awake ();
			
			scrollingController.addListener ("beginDrag", this.beginDrag, this);
			scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
			scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
			scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
			
		}
		
		const progressBar = this.progressBar = element.querySelector (".gallery__navigation__progress-bar");
		if (progressBar) {
			progressBar.addEventListener ("mousedown", function (event) {
				event.stopPropagation ();
				
			});
			progressBar.addEventListener ("touchstart", function (event) {
				event.stopPropagation ();
				
			});
			
			const indicators = this.indicators = new Array ();
			
			for (let i = 0; i < numSlides; i++) {
				const indicator = document.createElement ("div");
				indicator.index = i;
				indicator.classList.add ("gallery__navigation__progress-bar-item");
				
				indicator.addEventListener ("mousedown", function (event) {
					event.stopPropagation ();
					if (!Environment.IS_TOUCH_DEVICE)
						event.preventDefault ();
					
				});
				
				indicator.addEventListener ("click", function (event) {
					this.setViewOffsetByIndex (event.currentTarget.index);
					
				}.bind (this));
				
				progressBar.appendChild (indicator);
				indicators.push (indicator);
				
			}
			
		}
		
		this.addListener ("completeSliding", this.completeSliding, this);
		
		this.addListener ("advance", this.advance, this);
		
		if (navigationElement) {
			var arrowButtons = element.querySelectorAll (".gallery__navigation__arrow");
			if (arrowButtons.length >= 2) {
				arrowButtons [0].addEventListener ("click", function () {
					this.advanceInDirection (-1, true);
					this.markActivity ();
					
				}.bind (this));
				arrowButtons [0].addEventListener ("touchstart", function () {
					this.advanceInDirection (-1, true);
					this.markActivity ();
					
				}.bind (this));
				arrowButtons [1].addEventListener ("click", function () {
					this.advanceInDirection (1, true);
					this.markActivity ();
					
				}.bind (this));
				arrowButtons [1].addEventListener ("touchstart", function () {
					this.advanceInDirection (1, true);
					this.markActivity ();
					
				}.bind (this));
				
				function stopPropagation (event) {
					event.stopPropagation ();
					event.preventDefault ();
					
				}
				
				for (var i = arrowButtons.length; i--;) {
					var arrowButton = arrowButtons [i];
					arrowButton.addEventListener ("mousedown", stopPropagation);
					arrowButton.addEventListener ("touchstart", stopPropagation);
					
				}
				
			}
			
			if (progressBar &&
				(element.getAttribute ("data-class-name") == "GallerySection" ||
				element.getAttribute ("data-class-name") == "SliderProductGallerySection"))
				this.element.appendChild (progressBar);
			
			this.contentContainer.element.parentNode.appendChild (navigationElement);
			
		}
		
		if (!Environment.IS_IN_NEOS_EDITOR) {
			this.isAutoRotationLocked = true;
			
			var autoRotationFrequency = this.autoRotationFrequency = parseFloat (element.getAttribute ("data-auto-rotation-frequency")) * 60;
			var shouldAutoRotate = this.shouldAutoRotate = !!autoRotationFrequency;
			if (shouldAutoRotate)
				this.startAutoRotation ();
			
		}
		
	} else {
		if (!Environment.IS_IN_NEOS_EDITOR)
			navigationElement.style.display = "none";
		
	}
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
		mutationObserver.observe (this.element, {
			childList: true,
			subtree: true
			
		});
		
	}
	
};

Gallery.prototype.mutateElement = function (stage) {
	if (this.isMutating)
		return;

	var activeElement = document.activeElement;
	if (activeElement && activeElement.getAttribute ("contenteditable") == "true")
		return;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

Gallery.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	this.isMutating = true;
	
	this.parseChildren ();
	this.updateLayout (true);
	this.updateViewOffset ();
	this.advance ();
	
	window.setTimeout (function () {
		this.updateLayout (true);
		this.updateViewOffset ();
		
		this.isMutating = false;
		
	}.bind (this), 50);
	
};

Gallery.prototype.startAutoRotation = function () {
	// if (FAST_PASS)
	//	return;
	
	this.addListener ("mouseover", this.mouseOverOnAutoRotation, this);
	this.addListener ("mouseout", this.mouseOutOnAutoRotation, this);

	this.autoRotationDelay = this.autoRotationFrequency;
	this.addRunLoopHandler ("processAutoRotation");
	
};

Gallery.prototype.stopAutoRotation = function () {
	this.removeRunLoopHandler ("processAutoRotation");
	
};

Gallery.prototype.mouseOverOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = true;
	
};

Gallery.prototype.mouseOutOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = false;
	this.autoRotationDelay = Math.max (this.autoRotationFrequency, 200);
	
};

Gallery.prototype.processAutoRotation = function (sender) {
	if (this.isAutoRotationLocked)
		return;
	
	var autoRotationDelay = this.autoRotationDelay = Math.max (
		0, this.autoRotationDelay - this.context.animationTimer.framesDelta
		
	);
	
	if (autoRotationDelay)
		return;
	
	this.autoRotationDelay = this.autoRotationFrequency;
	this.skipTracking = true;
	this.advanceInDirection (1, {
		maxSpeed: 50,
		acceleration: 4.25
		
	});
	
};

Gallery.prototype.updateLayout = function (noResize) {
	var slides = this.slides;
	var slide = slides [0];
	if (!slide)
		return;
	
	this.spacing = Site.sharedInstance.gutterWidth;
	
	slide.element.style.display = "";
	
	var containerElement = this.contentContainer.element;
	var containerWidth = Math.round (containerElement.offsetWidth);
	
	var viewSize;
	
	if (this.adjustHeightToContent) {
		viewSize = [
			containerWidth,
			Math.round (containerElement.offsetHeight)
			
		];
		
		let fullWidth = 0;
		var maxHeight = 0;
		var slides = this.slides;
		
		const isRTEGallery = this.isRTEGallery;
		
		for (var i = slides.length; i--;) {
			var slide = slides [i];
			var slideElement = slide.element;
			
			var displayStyle = slideElement.style.display;
			
			if (displayStyle)
				slideElement.style.display = "";
			
			var slideSize = slide.contentSize = isRTEGallery ?
				[
					slideElement.offsetWidth,
					slideElement.offsetHeight
					
				] :
				[
					slideElement.firstElementChild.firstElementChild.offsetWidth,
					slideElement.firstElementChild.firstElementChild.offsetHeight
					
				];
			
			fullWidth += slideSize [0] + this.spacing;
			
			slideElement.style.width = viewSize [0] + "px";
			slideElement.style.height = "auto";
			
			if (displayStyle)
				slideElement.style.display = displayStyle;
			
			maxHeight = Math.max (maxHeight, slideSize [1]);
			
		}
		
		this.fullWidth = fullWidth;
		viewSize [1] = maxHeight;
		
		this.scrollThrough = slideSize [0] < containerWidth;
		
	} else {
		viewSize = [
			containerWidth,
			Math.round (containerWidth * this.aspectRatio)
			
		];
		
	}
	
	this.setViewSize (viewSize, noResize);
	
};

Gallery.prototype.setViewSize = function (viewSize, noResize) {
	var containerElement = this.contentContainer.element;
	containerElement.style.height = viewSize [1] + "px";
	
	const navigationElement = this.navigationElement;
	if (navigationElement)
		navigationElement.style.height = viewSize [1] + "px";
	
	var captionContainer = this.captionContainer;
	
	if (captionContainer) {
		var maxCaptionHeight = 0;
		
		// if (!Environment.IS_IN_NEOS_EDITOR) {
			var slides = this.slides;
			
			for (var i = slides.length; i--;) {
				var slide = slides [i];
				var caption = slide.caption;
				
				if (caption) {
					caption.style.display = "";
					maxCaptionHeight = Math.max (maxCaptionHeight, caption.offsetHeight);
					
				}
				
			}
			
		// }
		
		
		if (Environment.IS_IN_NEOS_EDITOR) {
			this.element.style.marginBottom = 55 + maxCaptionHeight + "px";
			
		} else {
			if (maxCaptionHeight) {
				captionContainer.style.display = "";
				captionContainer.style.paddingBottom = maxCaptionHeight + 3 + "px";
				
			} else {
				captionContainer.style.display = "none";
				
			}
			
		}
		
	}
	
	var stageSize = this.getStage ().size;
	var overscan = this.overscan = Math.ceil (
		(stageSize [0] - viewSize [0]) / 2
		
	);

	const lastViewSize = this.viewSize;
	const lastIndex = lastViewSize && this.slideIndexForViewOffset (this.viewOffset);
	
	this.viewSize = viewSize;
	this.setSize (viewSize);
	
	if (lastViewSize && !noResize && !(this.getStage ().nextTimeToObserve > new Date ().getTime ())) {
		/*
		var percentage = Math.round (
			this.currentViewOffset / (lastViewSize [0] + this.spacing)
			
		);
		this.viewOffset = this.currentViewOffset =
			percentage * (viewSize [0] + this.spacing);
		*/
		
		this.viewOffset = this.currentViewOffset = this.viewOffsetForSlideIndex (lastIndex);
		
		this.removeRunLoopHandler ("processSliding");
		
	}
	
	this.updateViewOffset ();
	
	if (!lastViewSize) {
		this.dispatchEvent ("advance");
		this.dispatchEvent ("completeSliding");
		
	}
	
};

Gallery.DRAG_DELTA = 150;

Gallery.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	// scrollingController.currentEvent.preventDefault ();
	scrollingController.currentEvent.stopPropagation ();
	
	delete this.maxScrollSpeed;
	
};

Gallery.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;
	
};

Gallery.prototype.snapToBounds = function () {
	var viewOffset = this.viewOffset;
	var index = this.slideIndexForViewOffset (viewOffset, true);
	
	var targetViewOffset = this.viewOffsetForSlideIndex (index);
	
	if (viewOffset != targetViewOffset) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

Gallery.prototype.completeSliding = function (sender) {
	const slides = this.slides;
	var index = this.slideIndexForViewOffset (this.viewOffset);
	
	index %= slides.length;
	if (index < 0)
		index += slides.length;
	
	var currentSlide = slides [index];
	if (!currentSlide.isLoading)
		currentSlide.startLoading ();
	
	if (slides.length > 1) {
		var nextItem = slides [(index + 1) % slides.length];
		if (!nextItem.isLoading)
			nextItem.startLoading ();
		
	}
	
	if (slides.length > 2) {
		var previousItem = slides [(index + slides.length - 1) % slides.length];
		if (!previousItem.isLoading)
			previousItem.startLoading ();
		
	}
	
	/*
	if (this.skipTracking) {
		this.skipTracking = false;
		
	} else {
		if (window.WebtrekkCounter)
			WebtrekkCounter.track ("click", "homepage.teaser." + currentSlide.id);
		
	}
	*/
	
};

Gallery.prototype.advance = function (sender) {
	const indicators = this.indicators;
	if (indicators) {
		let currentImageIndex = this.slideIndexForViewOffset (this.viewOffset) % indicators.length;
		
		if (currentImageIndex < 0)
			currentImageIndex += indicators.length;
		
		for (let i = indicators.length; i--;) {
			const indicator = indicators [i];
			
			if (i == currentImageIndex % indicators.length)
				indicator.classList.add ("active");
			else
				indicator.classList.remove ("active");
			
		}
		
	}
	
};

Gallery.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	var viewSize = this.viewSize;
	var dragDelta = Math.min (
		viewSize [0] / 2, Gallery.DRAG_DELTA
		
	);
	
	if (Math.abs (deltaX) >= dragDelta && !this.scrollThrough)
		this.advanceInDirection (deltaX < 0 ? -1 : 1);
	else
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	this.markActivity ();
	
};

Gallery.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.5;
		
		var viewSize = this.viewSize;
		var dragDelta = Math.min (
			viewSize [0] / 2, Gallery.DRAG_DELTA
			
		);
		
		if (this.scrollThrough) {
			this.viewOffset = targetViewOffset;
			this.snapToBounds ();
			
			this.dispatchEvent ("advance");
			
		} else {
			if (Math.abs (targetViewOffset) >= dragDelta) {
				this.advanceInDirection (scrollingController.flickDeltaX < 0 ? -1 : 1);
				
			} else {
				this.snapToBounds ();
				
			}
			
		}
		
	} else {
		this.snapToBounds ();
		
	}
	
};

Gallery.prototype.advanceInDirection = function (direction, easeIn) {
	if (this.scrollingController.isTouching)
		this.releaseDrag ();
	
	var delta = Math.abs (this.currentViewOffset - this.viewOffset);
	
	var index = this.slideIndexForViewOffset (this.viewOffset, true) + direction;
	
	var viewSize = this.viewSize;
	this.viewOffset = this.viewOffsetForSlideIndex (index);
	
	this.slidingInertia = .85;
	if (easeIn && delta < 10)
		this.maxScrollSpeed = 4;
	this.accelerationDescription = undefined;
	
	this.addRunLoopHandler ("processSliding");
	
	this.dispatchEvent ("advance");
	
};

Gallery.prototype.markActivity = function () {
	this.autoRotationDelay = this.autoRotationFrequency * 1.5;
	
};

Gallery.prototype.spacing = 40;

Gallery.prototype.slideIndexForViewOffset = function (viewOffset, noWrap) {
	var index = Math.round (
		viewOffset / (this.viewSize [0] + this.spacing)
		
	);
	
	if (!noWrap) {
		var slides = this.slides;
		const numSlides = this.numSlides;
		
		index = index % numSlides;
		if (index < 0)
			index += numSlides;
		
	}
	return index;
	
};

Gallery.prototype.setViewOffsetByIndex = function (index, dontAnimate) {
	var slides = this.slides;
	const numSlides = this.numSlides;
	
	index = index % numSlides;
	if (index < 0)
		index += numSlides;
	
	var viewOffset = this.viewOffset;
	
	var currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
	var delta = (index - currentImageIndex) % numSlides;
	if (delta < 0)
		delta += numSlides;
	
	if (delta > numSlides * .5)
		delta = delta - numSlides;
	
	// trace ("delta", delta, currentImageIndex);
	if (!delta)
		return;
	
	var viewSize = this.viewSize;
	var targetViewOffset = this.viewOffset;
	
	var direction = numSlides > 2 ? delta > 0 ? 1 : -1 : currentImageIndex > index ? -1 : 1;
	targetViewOffset += (this.viewSize [0] + this.spacing) * Math.abs (delta) * direction;

	if (viewOffset != targetViewOffset) {
		if (dontAnimate) {
			this.viewOffset = this.currentViewOffset = targetViewOffset;
			this.removeRunLoopHandler ("processSliding");
			this.updateViewOffset ();
			
		} else {
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .85;
			this.maxScrollSpeed = 4;
			this.accelerationDescription = undefined;
			this.addRunLoopHandler ("processSliding");
			
		}
		
	}
	
	this.dispatchEvent ("advance");

};

Gallery.prototype.viewOffsetForSlideIndex = function (slideIndex) {
	if (this.adjustHeightToContent) {
		const slides = this.slides;
		const numSlides = this.numSlides;
		
		const hardOff = Math.floor (
			slideIndex / numSlides
			
		);
		
		slideIndex = slideIndex % numSlides;
		if (slideIndex < 0)
			slideIndex += numSlides;
		
		let cursor = 0;
		
		for (var i = 0; i < slideIndex; i++) {
			const slide = slides [i];
			cursor += slide.contentSize [0] + this.spacing;
			
		}
		
		return cursor + hardOff * this.fullWidth;
		
	} else {
		return slideIndex * (this.viewSize [0] + this.spacing);
		
	}
	
};

Gallery.prototype.updateViewOffset = function () {
	var slides = this.slides;
	if (!slides.length)
		return;
	
	if (this.adjustHeightToContent) {
		this.updateViewOffsetForVariableContent ();
		
	} else {
		var viewSize = this.viewSize;
		var viewWidth = viewSize [0];
		var spacing = this.spacing;
		
		var viewOffset = this.currentViewOffset;
		var currentSlideIndex = this.currentSlideIndex = this.slideIndexForViewOffset (viewOffset);
		
		viewOffset = (viewOffset + (viewWidth + spacing) * .5) % (viewWidth + spacing);
		if (viewOffset < 0)
			viewOffset += (viewWidth + spacing);
		
		viewOffset = viewOffset - (viewWidth + spacing) * .5 + currentSlideIndex * (viewWidth + spacing);
		
		viewOffset = Math.round (viewOffset);
		
		for (var i = slides.length; i--;) {
			var slide = slides [i];
			slide.isUsed = false;
			
		}
		
		var overscan = 0; // this.overscan;
		
		var slide = slides [currentSlideIndex];
		
		var i = currentSlideIndex;
		var cursor = -Math.round (viewSize [0] * .5) - viewOffset + (currentSlideIndex * (viewWidth + spacing));
		
		while (cursor > -viewWidth * .5 + spacing - overscan) {
			i = (i - 1) % slides.length;
			if (i < 0)
				i += slides.length;
			
			var slide = slides [i];
			// slide.setViewSize (viewSize);
			
			cursor -= (slide.contentSize || viewSize) [0] + spacing;
			
		}
		
		var viewOffset = Math.round (viewWidth * .5);
		
		var contentContainer = this.contentContainer;
		
		if (this.numSlides == 1)
			cursor = -viewOffset;
		
		var isInNeosEditor = Environment.IS_IN_NEOS_EDITOR;
		
		var percentageSum = 0;
		
		while (cursor < Math.floor (viewWidth * .5) + overscan) {
			var slide = slides [i];
			if (slide.isUsed)
				break;
			slide.isUsed = true;
			
			slide.setViewSize (viewSize);
			slide.setPosition (
				cursor + viewOffset,
				0
				
			);
			
			// slide.markLoadingPriority (-(cursor + viewOffset) / 8192) + 4.5;
			if (!slide.isLoading)
				slide.startLoading ();
			
			slide.loadingPriority = Math.abs (cursor + viewOffset);
			
			if (!slide.isAwake) {
				slide.setAlpha (1);
				slide.awake ();
				
			}
			
			var percentage = Math.max (0, Math.min (1, (1 - Math.abs (
				slide.position [0] / viewSize [0]
				
			))));
			
			if (!isInNeosEditor) {
				var caption = slide.caption;
				if (caption) {
					if (percentage) {
						caption.style.display = "";
						caption.style.opacity = percentage;
						
						percentageSum += percentage;
						
					} else {
						caption.style.display = "none";
						
					}
					
				}
				
			}
			
			slide.setAlpha (1); // percentage * .67 + .33);
			
			if (slide.setPercentage) {
				slide.setPercentage (percentage);
				
			}
			
			i = (i + 1) % slides.length;
			if (i < 0)
				i += slides.length;
			
			cursor += (slide.contentSize || viewSize) [0] + spacing;
			
		}
		
		for (var i = slides.length; i--;) {
			var slide = slides [i];
			if (!slide.isUsed) {
				// contentContainer.removeChild (slide);
				slide.setAlpha (0);
				slide.element.style.display = "none";
				
				if (slide.isAwake)
					slide.sleep ();
				
				if (!isInNeosEditor) {
					var caption = slide.caption;
					if (caption)
						caption.style.display = "none";
					
				}
				
			}
			
		}
		
	}
	
	this.dispatchEvent ("updateViewOffset");
	
	this.renderInContext (this.context);
	
};

Gallery.prototype.updateViewOffsetForVariableContent = function () {
	const slides = this.slides;
	
	var viewSize = this.viewSize;
	var viewWidth = viewSize [0];
	var spacing = this.spacing;
	
	var viewOffset = this.currentViewOffset;
	
	var fullWidth = this.fullWidth;
	var currentSlideIndex = this.slideIndexForViewOffset (viewOffset);
	
	viewOffset = viewOffset % fullWidth;
	if (viewOffset < 0)
		viewOffset += fullWidth;
	
	viewOffset = Math.round (viewOffset);
	
	var slide = slides [currentSlideIndex];
	
	var cursor = viewOffset;
	
	var overscan = this.overscan;
	const isRTEGallery = this.isRTEGallery;
	
	const centerContent = this.centerContent;
	
	var j = slides.length;
	if (this.preventScroll) {
		cursor = (fullWidth - spacing) * .5 - this.getStage ().size [0] * .5;
		
		for (var i = 0; i < slides.length; i++) {
			var slide = slides [i];
			const slideSize = slide.contentSize;
			
			var offset = Math.round (-cursor);
			slide.setPosition (
				offset,
				0
				
			);
			slide.setAlpha (i < this.numSlides ? 1 : 0);
			
			slide.element.style.opacity = 1;
			slide.element.firstElementChild.firstElementChild.style.opacity = 1;
			
			cursor -= slideSize [0] + spacing;
			
		}
		
	} else {
		for (var i = 0; i < slides.length; i++) {
			var slide = slides [i];
			const slideSize = slide.contentSize;
			
			var offset = Math.round (-cursor);
			
			if (offset < -slideSize [0] - overscan)
				offset += this.fullWidth;
			
			if (offset > viewWidth + 2 * overscan)
				offset -= this.fullWidth;
			
			let position = offset + (centerContent ? overscan : 0);
			if (position > viewWidth + 2 * overscan)
				position -= this.fullWidth;
			
			
			slide.setPosition (
				position,
				0
				
			);
			
			var percentage = Math.max (0, Math.min (1, (1 - Math.abs (
				offset / viewSize [0]
				
			))));
			
			slide.setAlpha (1);
			
			if (isRTEGallery)
				slide.element.style.opacity = (percentage * .67 + .33);
			else
				slide.element.firstElementChild.firstElementChild.style.opacity = (percentage * .67 + .33);
			
			cursor -= slideSize [0] + spacing;
			cursor %= fullWidth;
			
		}
		
	}
	
};

//
// GallerySlide extends AnimatableSprite
//

const GallerySlide = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.renderUsingCSSTransform = true;
	
};

GallerySlide.prototype = Object.create (AnimatableSprite.prototype);

GallerySlide.prototype.takeElement = function (element) {
	this.element = element;
	
	if (element.classList.contains ("gallery__slide--text"))
		element.classList.add ("gallery__slide--text--initialized");
	
	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");
	
	element.addEventListener ("click", function (event) {
		if (this.parent.parent.scrollingController.didDrag) {
			event.preventDefault ();
			return;
			
		}
		
	}.bind (this));
	
	const img = element.querySelector ("img");
	if (img) {
		img.setAttribute ("draggable", "false");
		img.style.userSelect = "none";
		img.style.webkitTouchCallout = "none";
		
	}
	
	var isImageSlide = element.classList.contains ("gallery__slide--image");
	if (isImageSlide) {
		var image = this.image = element.querySelector ("picture");
		if (image) {
			image.classList.add ("lazyload--started");
			/*
			var imageSize = this.imageSize = [
				Number (image.parentNode.getAttribute ("data-width")),
				Number (image.parentNode.getAttribute ("data-height"))
				
			];
			*/
			var imageSize = this.imageSize = [3, 2]
			
		}
		
	}
	
	var caption = element.querySelector ("figcaption");
	if (caption && caption.textContent)
		this.caption = caption;
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		element.removeAttribute ("href");
		
	}
	
};

GallerySlide.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	var element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
	var image = this.image;
	if (!image)
		return;
	
	image.style.width = viewSize [0] + "px";
	image.style.height = viewSize [1] + "px";
	
};

GallerySlide.prototype.markLoadingPriority = function (loadingPriority) {
	this.loadingPriority = loadingPriority;
	
};

GallerySlide.prototype.startLoading = function () {
	this.isLoading = true;
	
	var image = this.image;
	if (!image)
		return;
	
	const img = image.querySelector ("img");
	const onComplete = function (event) {
		img.removeEventListener ("load", onComplete);
		this.parent.parent.updateLayout ();
		
	}.bind (this);
	
	img.addEventListener ("load", onComplete);
	LazyLoader.sharedInstance.loadImageAndListen (image);
	
};

GallerySlide.prototype.awake = function () {
	this.isAwake = true;
	
};

GallerySlide.prototype.sleep = function () {
	this.isAwake = false;
	
};

//
// SliderProductGallerySection extends GallerySection
//

export const SliderProductGallerySection = function (context) {
	GallerySection.apply (this, arguments);
	
};

window.SliderProductGallerySection = SliderProductGallerySection;

SliderProductGallerySection.prototype = Object.create (GallerySection.prototype);

SliderProductGallerySection.prototype.getGalleryConstructor = function () {
	return SliderProductGallery;
	
};

//
// SliderProductGallery extends Gallery
//

export const SliderProductGallery = function (context) {
	Gallery.apply (this, arguments);
	
};

SliderProductGallery.prototype = Object.create (Gallery.prototype);

SliderProductGallery.prototype.centerContent = true;

SliderProductGallery.prototype.parseChildren = function () {
	const element = this.element;
	
	let shouldDoubleSlides;
	
	if (!Environment.IS_IN_NEOS_EDITOR) {
		var slides = new Array ();
		var children = (Environment.IS_IN_NEOS_EDITOR ? element : element).querySelectorAll (".gallery__slide");
		
		if (children <= 3)
			shouldDoubleSlides = this.shouldDoubleSlides = true;
		
		if (shouldDoubleSlides) {
			for (let i = 0; i < children.length; i++) {
				const child = children [i];
				
				child.parentNode.appendChild (child.cloneNode (true));
				
			}
			
		}
		
	}
	
	Gallery.prototype.parseChildren.apply (this, arguments);
	
	if (!Environment.IS_IN_NEOS_EDITOR) {
		let hasTextContent;
		
		const slides = this.slides;
		
		for (var i = slides.length; i--;) {
			var slide = slides [i];
			var slideElement = slide.element;
			
			const overlay = slideElement.querySelector (".gallery__slide--text-overlay");
			if (overlay && overlay.textContent.trim ())
				hasTextContent = true;
			else if (overlay)
				overlay.style.display = "none";
			
		}
		
		if (!hasTextContent)
			element.classList.remove ("gallery--progress-dots-above");
		
	}
	
	if (shouldDoubleSlides)
		this.numSlides /= 2;
	
};

SliderProductGallery.prototype.updateLayout = function (noResize) {
	var slides = this.slides;
	var slide = slides [0];
	if (!slide)
		return;
	
	const spacing = this.spacing = Site.sharedInstance.gutterWidth;
	
	slide.element.style.display = "";
	
	const element = this.element;
	var containerElement = this.contentContainer.element;
	var containerWidth = Math.round (containerElement.offsetWidth);
	
	const selfBounds = containerElement.getBoundingClientRect ();
	
	var viewSize;
	
	viewSize = [
		containerWidth,
		Math.round (containerElement.offsetHeight)
		
	];
	
	let slideWidth;
	
	if (containerWidth < 1920) {
		slideWidth = Math.min (containerWidth * .75, Math.max (
			640 - 10,
			Math.ceil ((containerWidth - spacing * 2) / 3)
			
		));
		
	} else {
		slideWidth = Math.ceil ((1920 - 120 - spacing * 2) / 3)
		
	}
	
	viewSize [0] = slideWidth;
	
	let fullWidth = 0;
	var maxHeight = 0;
	var slides = this.slides;
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		var slideElement = slide.element;
		
		var displayStyle = slideElement.style.display;
		
		if (displayStyle)
			slideElement.style.display = "";
		
		slideElement.style.width = slideWidth + "px";
		slideElement.style.height = "auto";
		
		const overlay = slideElement.querySelector (".gallery__slide--text-overlay");
		const overlayBounds = overlay ? overlay.getBoundingClientRect () : undefined;
		
		var slideSize = slide.contentSize = [
			slideWidth,
			overlayBounds ? overlayBounds.bottom - selfBounds.top : slideElement.offsetHeight
			
		];
		
		fullWidth += slideWidth + this.spacing;
		
		if (displayStyle)
			slideElement.style.display = displayStyle;
		
		maxHeight = Math.max (maxHeight, slideSize [1]);
		
	}
	
	if (this.shouldDoubleSlides)
		fullWidth /= 2;
	
	if (fullWidth <= selfBounds.width) {
		if (!this.preventScroll) {
			this.preventScroll = true;
			element.classList.add ("gallery--no-scroll");
			
		}
		
	} else {
		if (this.preventScroll) {
			this.preventScroll = false;
			element.classList.remove ("gallery--no-scroll");
			
		}
		
	}
	
	this.fullWidth = fullWidth;
	viewSize [1] = maxHeight;
	
	this.setViewSize (viewSize, noResize);
	
	const navigationElement = this.navigationElement;
	if (navigationElement)
		navigationElement.style.height = Math.round (slideWidth * .35 * 2) + "px";
	
};
