import * as Environment from "../base/Environment.js";

import {RenderContext} from "../base/Display.js";
import {Stage, AnimatableSprite} from "../base/Display2D.js";

import {Site} from "./Site.js";

import {OnePagerNavigation} from "./OnePagerNavigation.js";

//
// Navigation extends Stage
//

export const Navigation = function (context) {
	Stage.apply (this, arguments);

	if (Navigation.sharedInstance)
		throw new Error ("Cannot instantiate. Navigation is singleton.");

	Navigation.sharedInstance = this;

	var element = this.element;
	element.style.visibility = "";
	element.classList.add ("unselectable");

	var modeStack = this.modeStack = new Array ();
	
	var shiftContainer = this.shiftContainer = document.createElement ("div");
	shiftContainer.classList.add ("page-header--shift-container");

	var modalCover = this.modalCover = document.createElement ("div");
	modalCover.classList.add ("page-header-cover");
	shiftContainer.appendChild (modalCover);

	var children = element.children;
	for (var i = 0; i < children.length; i++)
		shiftContainer.appendChild (children [i]);
	
	element.appendChild (shiftContainer);
	
	var toTopButton = element.nextElementSibling;
	if (toTopButton && toTopButton.classList.contains ("page-tool-to-top")) {
		this.toTopButton = toTopButton;
		toTopButton.addEventListener ("click", function (event) {
			event.preventDefault ();

			this.hideToTopButton ();
			Site.sharedInstance.scrollToOffset (0);

		}.bind (this));

	}
	
	const navigationDropDrownElement = element.querySelector (".page-navigation");
	if (navigationDropDrownElement) {
		var navigationDropDrown = this.navigationDropDrown = this.attachSprite (NavigationDropDown);
		navigationDropDrown.takeElement (navigationDropDrownElement);
		
	}
	
	this.parseNavigationTools ();

	context.resizeDispatcher.addListener ("scroll", this.scroll, this);

	this.scrollOffset = [0, 0];

	this.addListener ("resize", this.resize, this);
	this.resize ();

	this.addListener ("mouseover", this.mouseOver, this);
	
	if (Environment.FAST_PASS) {
		window.setTimeout (function () {
			// this.enterMode (Navigation.MODE_LOCALE_SELECTION);
			// this.enterMode (Navigation.MODE_SEARCHING);
			
		}.bind (this), 250);
		
	}
	
};

Navigation.prototype = Object.create (Stage.prototype);

Navigation.prototype.parseNavigationTools = function () {
	var element = this.element;

	var headerLogo = this.headerLogo = element.querySelector (".header-logo");

	var toolElement = this.toolElement = element.querySelector (".page-tools");
	if (!toolElement)
		return;
	
	var menuButton = this.menuButton = toolElement.querySelector (".page-tool-menu");

	var navigationDropDrown = this.navigationDropDrown;
	menuButton.addEventListener ("click", function () {
		if (navigationDropDrown.isExpanded) {
			navigationDropDrown.collapse (true);

		} else {
			var lastMode = this.mode;

			if (lastMode != Navigation.MODE_NORMAL) {
				this.enterMode (Navigation.MODE_NORMAL);

			}

			if (lastMode == undefined ||
				lastMode == Navigation.MODE_NORMAL ||
				lastMode == Navigation.MODE_SEARCHING) {
				navigationDropDrown.expand (true);

				navigationDropDrown.navigateToActivePage ();
				navigationDropDrown.updateLayout (false, true);

			}

		}

	}.bind (this));

	var reducedToolElements = element.querySelectorAll (".page-tools-reduced");

	for (var i = reducedToolElements.length; i--;) {
		var reducedToolElement = reducedToolElements [i];

		var menuButton = this.reducedMenuButton = reducedToolElement.querySelector (".page-tool-menu");
		if (menuButton) {
			menuButton.addEventListener ("click", function () {
				this.unreduce ();
				
			}.bind (this));
			
		}

		var closeButton = reducedToolElement.querySelector (".page-tool-close");

		closeButton.addEventListener ("click", function () {
			this.leaveMode ();

		}.bind (this));

	}

	var searchButton = this.searchButton = toolElement.querySelector (".page-tool-search");
	searchButton.addEventListener ("click", function () {
		this.enterMode (this.mode == Navigation.MODE_SEARCHING ?
			Navigation.MODE_NORMAL :
			Navigation.MODE_SEARCHING

		);

	}.bind (this));
	
	var localeButton = this.localeButton = toolElement.querySelector (".page-tool-locale");
	if (localeButton) {
		localeButton.addEventListener ("click", function () {
			this.enterMode (this.mode == Navigation.MODE_LOCALE_SELECTION ?
				Navigation.MODE_NORMAL :
				Navigation.MODE_LOCALE_SELECTION

			);

		}.bind (this));
		
	}
	
};

Navigation.prototype.leaveMode = function () {
	var modeStack = this.modeStack;
	if (modeStack.length)
		this.popMode ();
	
};

Navigation.prototype.resizeContext = function (context) {
	this.setSize ([
		this.element.offsetWidth,
		(Environment.IS_TOUCH_DEVICE ?
			RenderContext.getViewportSize () :
			this.getRulerSize ()

		) [1]

	]);

};

Navigation.prototype.resize = function (sender) {
	this.isResizing = true;

	var viewSize = this.viewSize = this.size;
	this.offsetHeight = this.element.offsetHeight;
	
	var navigationDropDrown = this.navigationDropDrown;
	if (navigationDropDrown)
		navigationDropDrown.setViewSize (viewSize);

	this.updateSearchFieldLayout ();

	this.scroll ();

	this.isResizing = false;
	
	const element = this.element;
	if (!element.style.background)
		element.style.background = "transparent";
	
	var localeLayer = this.localeLayer;
	if (localeLayer)
		localeLayer.updateLayout ();
	
};

Navigation.prototype.scroll = function (sender) {
	if (this.modalClass)
		return;

	var lastScrollOffset = this.scrollOffset;
	var scrollOffset = this.scrollOffset = RenderContext.getScrollOffset ();

	var viewSize = this.size;
	var documentHeight = document.body.offsetHeight;

	scrollOffset [1] = Math.max (0, Math.min (documentHeight - viewSize [1], scrollOffset [1]));
	
	if (lastScrollOffset) {
		var delta = scrollOffset [1] - lastScrollOffset [1];
		this.handleScrollDelta (scrollOffset [1], delta, "covering");
		
	}
	
	this.dispatchEvent ("scroll");
	
};

Navigation.prototype.renderSelfInContext = function () {

};

Navigation.prototype.handleScrollDelta = function (scrollOffset, delta, coverClass) {
	if (this.keepHeaderOnTop && this.isReduced) {
		this.element.style.transform = "translateY(" + -scrollOffset + "px)";
		this.position [1] = -scrollOffset;
		return;
		
	}

	if (delta < 0) {
		this.setPosition (
			this.position [0],
			Math.min (0, this.position [1] - delta)

		);

	} else {
		if (this.isSlidingIn ||
			(this.navigationDropDrown && this.navigationDropDrown.isExpanded)) {
			
		} else {
			this.setPosition (
				this.position [0],
				Math.max (-(this.offsetHeight || this.element.offsetHeight), this.position [1] - delta)
				
			);
			
		}
		
	}
	
	var element = this.element;
	var topOffset = this.position [1] + scrollOffset;

	if (topOffset >= 5) {
		 element.classList.add (coverClass);

	} else {
		element.classList.remove (coverClass);

	}
	
	this.element.style.transform = "translateY(" + this.position [1] + "px)";

	var toTopButton = this.toTopButton;
	if (toTopButton) {
		toTopButton.style.transform = "translateY(" + -this.position [1] + "px)";

		if (!Site.sharedInstance || !Site.sharedInstance.isScrolling) {
			if (scrollOffset > 10)
				this.showToTopButton ();
			else
				this.hideToTopButton ();

		}

	}

	this.renderInContext ();

};

Navigation.prototype.showToTopButton = function () {
	if (!this.showsToTopButton) {
		this.showsToTopButton = true;
		this.startAnimation ("SlideToTopButton", {direction: 1, rate: .04});

	}

};

Navigation.prototype.hideToTopButton = function () {
	if (this.showsToTopButton) {
		this.showsToTopButton = false;
		this.startAnimation ("SlideToTopButton", {direction: 0, rate: .04});

	}

};

Navigation.prototype.animateSlideToTopButton = function () {
	var state = this.updatedState ("SlideToTopButton");
	var t = state.phase;

	t = 1 - t * t * t;
	var toTopButton = this.toTopButton;
	toTopButton.style.bottom = t * -100 + "px";

	toTopButton.style.display = state.phase ? "block" : "none";

};

Navigation.prototype.mouseOver = function (sender) {
	if (!this.position [1])
		return;

	var target = sender.currentTarget;
	while (target && target != this.element) {
		target = target.parentNode;

	}

	if (!target)
		return;

	this.slideIn ();

};

Navigation.prototype.slideIn = function () {
	if (this.isSlidingIn)
		return;

	var startSlidePosition = this.position [1];
	if (startSlidePosition) {
		this.isSlidingIn = true;

		this.startSlidePosition = startSlidePosition;
		this.startAnimation ("SlideIn", {direction: 1, rate: .015 / (-startSlidePosition / 200), phase: 0});

	}

};

Navigation.prototype.animateSlideIn = function () {
	var state = this.updatedState ("SlideIn");
	var t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (Math.PI * t) / 2;
	var t_ = 1 - t;

	this.setPosition (
		this.position [0],
		t_ * this.startSlidePosition

	);

	this.element.style.transform = "translateY(" + this.position [1] + "px)";

	if (state.phase == 1)
		this.isSlidingIn = false;

};

Navigation.prototype.reduce = function (modalClass) {
	if (this.isReduced)
		return;
	
	this.isReduced = true;

	var element = this.element;

	element.classList.remove ("covering");

	// trace ("set modal class", modalClass);
	if (modalClass && this.modalClass)
		element.classList.remove (this.modalClass);

	this.modalClass = modalClass;

	window.setTimeout (function () {
		element.classList.add ("reduced");
		if (this.isModalNegative)
			element.classList.add ("negative");
		else
			element.classList.remove ("negative");

		if (modalClass) {
			element.classList.add (modalClass);

		}

	}.bind (this), this.navigationDropDrown.isMobile ? 100 : 50);

	this.slideIn ();

	if (this.toTopButton)
		this.hideToTopButton ();

	var clickStageWhileUnreduced = this.clickStageWhileUnreduced;
	if (clickStageWhileUnreduced)
		window.removeEventListener ("click", clickStageWhileUnreduced);

};

Navigation.prototype.unreduce = function () {
	if (!this.isReduced)
		return;

	this.isReduced = false;

	var element = this.element;

	element.classList.remove ("covering");

	var modalClass = this.modalClass;
	this.modalClass = Site.sharedInstance.mode == Site.MODE_MODAL ? "modal-story" : undefined;

	window.setTimeout (function () {
		element.classList.remove ("reduced");
		element.classList.remove ("negative");

	}.bind (this), 50);

	var clickStageWhileUnreduced = this.clickStageWhileUnreduced;
	if (!clickStageWhileUnreduced)
		clickStageWhileUnreduced = this.clickStageWhileUnreduced = Navigation.clickStageWhileUnreduced.bind (this);
	
	this.slideIn ();

};

Navigation.clickStageWhileUnreduced = function (event) {
	var element = this.element;
	var target = event.target;

	while (target) {
		if (target == element)
			return;

		target = target.parentNode;

	}

	event.preventDefault ();
	event.stopPropagation ();
	event.stopImmediatePropagation ();

	this.navigationDropDrown.collapse (true);

	this.reduce (this.modalClass);

};

Navigation.MODE_NORMAL = "normal";
Navigation.MODE_SEARCHING = "searching";
Navigation.MODE_LOCALE_SELECTION = "locale-selection";

Navigation.prototype.enterMode = function (mode) {
	var lastMode = this.mode;
	if (lastMode == mode)
		return;

	// trace ("leave mode", lastMode);

	var navigationDropDrown = this.navigationDropDrown;

	switch (lastMode) {
		case Navigation.MODE_NORMAL:
			break;
		case Navigation.MODE_SEARCHING:
			this.leaveSearch ();
			break;
		case Navigation.MODE_LOCALE_SELECTION:
			this.leaveLocaleSelection ();
			break;

	}

	this.mode = mode;
	// trace ("enter mode", mode);

	switch (mode) {
		case Navigation.MODE_NORMAL:
			break;
		case Navigation.MODE_SEARCHING:
			navigationDropDrown.collapse (true);
			this.enterSearch ();
			break;
		case Navigation.MODE_LOCALE_SELECTION:
			navigationDropDrown.collapse (true);
			this.enterLocaleSelection ();
			break;

	}

};

Navigation.prototype.pushMode = function (mode, isModalNegative) {
	var modeStack = this.modeStack;
	var element = this.element;

	modeStack.push ({
		lastMode: this.modalClass,
		mode: mode,
		wasReduced: this.isReduced,
		wasModalNegative: this.isModalNegative,

		wasCovering: element.classList.contains ("covering"),
		wasModalCovering: element.classList.contains ("modal-covering")

	});

	element.classList.remove ("covering");
	element.classList.remove ("modal-covering");

	this.isModalNegative = isModalNegative;
	this.isReduced = false;
	this.reduce (mode);

};

Navigation.prototype.popMode = function (mode) {
	var modeStack = this.modeStack;
	var modeConfiguration = modeStack.pop ();

	var isModalNegative = this.isModalNegative = modeConfiguration.wasModalNegative;

	var element = this.element;
	element.classList.remove (modeConfiguration.mode);

	if (modeConfiguration.wasReduced) {
		this.isReduced = false;
		this.reduce (modeConfiguration.lastMode);

	} else {
		this.unreduce ();
		document.body.classList.remove ("scroll-locked");
		this.scroll ();

	}

	if (modeConfiguration.wasCovering)
		element.classList.add ("covering");

	if (modeConfiguration.wasModalCovering)
		element.classList.add ("modal-covering");

	this.dispatchEvent ("popMode");

};

Navigation.prototype.enterSearch = function () {
	var searchField = this.searchField;
	if (!searchField) {
		searchField = this.searchField = this.attachSprite (Navigation.SearchField);
		searchField.takeElement (this.element.querySelector (".search-field"));

	}

	this.slideIn ();
	searchField.show ();
	this.updateSearchFieldLayout ();

	var clickStageWhileSearching = this.clickStageWhileSearching;
	if (!clickStageWhileSearching)
		clickStageWhileSearching = this.clickStageWhileSearching = Navigation.clickStageWhileSearching.bind (this);

	window.addEventListener ("click", clickStageWhileSearching, true);

};

Navigation.clickStageWhileSearching = function (event) {
	var element = this.element;
	var target = event.target;

	while (target) {
		if (target == element)
			return;

		target = target.parentNode;

	}

	event.preventDefault ();
	event.stopPropagation ();
	event.stopImmediatePropagation ();

	this.enterMode (Navigation.MODE_NORMAL);

};

Navigation.prototype.leaveSearch = function () {
	var searchField = this.searchField;
	searchField.hide ();

	var clickStageWhileSearching = this.clickStageWhileSearching;
	window.removeEventListener ("click", clickStageWhileSearching, true);

};

Navigation.prototype.enterLocaleSelection = function () {
	var localeLayer = this.localeLayer;
	if (!localeLayer) {
		localeLayer = this.localeLayer = this.attachSprite (Navigation.LocaleLayer);
		localeLayer.takeElement (this.element.querySelector (".locale-layer"));
		
	}
	
	this.slideIn ();
	localeLayer.show ();
	
	var localeButton = this.localeButton;
	localeButton.classList.add ("open");
	
	var clickStageWhileSelectingLocale = this.clickStageWhileSelectingLocale;
	if (!clickStageWhileSelectingLocale)
		clickStageWhileSelectingLocale = this.clickStageWhileSelectingLocale = Navigation.clickStageWhileSelectingLocale.bind (this);
	
	window.addEventListener ("click", clickStageWhileSelectingLocale, true);
	
};

Navigation.clickStageWhileSelectingLocale = function (event) {
	Navigation.clickStageWhileSearching.call (this, event);
	
};

Navigation.prototype.leaveLocaleSelection = function () {
	var localeLayer = this.localeLayer;
	localeLayer.hide ();
	
	var localeButton = this.localeButton;
	localeButton.classList.remove ("open");
	
	window.addEventListener ("click", this.clickStageWhileSelectingLocale, true);
	
};

Navigation.prototype.updateSearchFieldLayout = function () {
	var searchField = this.searchField;
	if (!searchField)
		return;

	var navigationDropDrown = this.navigationDropDrown;

	var selfBounds = navigationDropDrown.element.parentNode.getBoundingClientRect ();
	var navigationBounds = navigationDropDrown.element.getBoundingClientRect ();
	var searchIconBounds = this.searchButton.getBoundingClientRect ();
	
	const size = this.size;
	
	if (navigationBounds.width) {
		searchField.hideHeaderLogo = false;
		
		searchField.setPosition (navigationBounds.left - selfBounds.left - (size [0] < 1440 ? 1 : 6), undefined);
		searchField.setWidth (searchIconBounds.right - navigationBounds.left - 15);
		
	} else {
		var hideHeaderLogo = searchField.hideHeaderLogo = size [0] < 560;
		let offsetLeft;
		
		if (hideHeaderLogo) {
			var gridColumns = Site.sharedInstance.gridColumns;
			offsetLeft = gridColumns [0].firstElementChild.getBoundingClientRect ().left - 23;
			
			searchField.setWidth (searchIconBounds.right - selfBounds.left + 68, true, hideHeaderLogo);

		} else {
			offsetLeft = 113 + 33;
			searchField.setWidth (searchIconBounds.right - selfBounds.left - offsetLeft + 11, true, hideHeaderLogo);
			
		}
		searchField.setPosition (offsetLeft, undefined);
		
		searchField.renderInContext ();

	}
	
};

//
// NavigationDropDown extends AnimatableSprite
//

var NavigationDropDown = function (context) {
	AnimatableSprite.apply (this, arguments);

};

NavigationDropDown.prototype = Object.create (AnimatableSprite.prototype);

NavigationDropDown.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	var navigationOverlay = this.navigationOverlay = document.createElement ("div");
	navigationOverlay.style.display = "none";
	navigationOverlay.classList.add ("page-navigation-overlay");

	var gridContainer = this.gridContainer = document.createElement ("div");
	gridContainer.classList.add ("container");

	var gridRow = document.createElement ("div");
	gridRow.classList.add ("row");

	var gridColumn = document.createElement ("div");
	gridColumn.classList.add ("column");

	var gridContent = document.createElement ("div");

	gridColumn.appendChild (gridContent);
	gridRow.appendChild (gridColumn);
	gridContainer.appendChild (gridRow);
	navigationOverlay.appendChild (gridContainer);

	var navigationOverlayCloseButton = element.querySelector (".page-navigation-overlay-close-button");
	if (navigationOverlayCloseButton) {
		navigationOverlayCloseButton.addEventListener ("click", this.clickOverlayCloseButton.bind (this));
		gridContent.appendChild (navigationOverlayCloseButton);
		navigationOverlayCloseButton.style.display = "";
		
	}
	var stage = this.getStage ();
	stage.shiftContainer.appendChild (navigationOverlay);

	var navigationLevelContainers = this.navigationLevelContainers = new Array ();

	var navigationItems = this.navigationItems = new Array ();
	this.parseNavigationItems ();

	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			stage.isResizing = true;
			
			this.expand ();
			
			this.navigateToActivePage ();
			this.updateLayout (false, false);
			
			stage.isResizing = false;

		}.bind (this), 50);
		*/
		
		/*
		window.setTimeout (function () {
			this.collapse (true);

		}.bind (this), 500);
		*/
		
		/*
		window.setTimeout (function () {
			this.selectUpperLevel ();

		}.bind (this), 500);
		*/
		
		/*
		window.setTimeout (function () {
			// this.selectNavigationItem (NavigationItem.map ["Unternehmen"], true);
			this.selectNavigationItem (NavigationItem.map ["Marken"], true);
			
		}.bind (this), 50);
		*/
		
		/*
		window.setTimeout (function () {
			// this.selectNavigationItem (NavigationItem.map ["Technische Dienstleistungen"]);
			// this.selectNavigationItem (NavigationItem.map ["CharmⓇ — Dichtheitsüberprüfung"]);
			this.selectNavigationItem (NavigationItem.map ["für Kunden"], true);

		}.bind (this), 450);
		*/
		
		/*
		window.setTimeout (function () {
			 this.getStage ().enterMode (Navigation.MODE_SEARCHING);

		}.bind (this), 450);
		*/
		
	}

};

NavigationDropDown.prototype.parseNavigationItems = function () {
	var element = this.element;

	var rootNavigationLevel = this.rootNavigationLevel = this.attachSprite (NavigationLevel);
	rootNavigationLevel.takeElement (element.querySelector ("ul"), 0);

	var navigationLevels = this.navigationLevels = [rootNavigationLevel];

};

NavigationDropDown.prototype.navigateToActivePage = function () {
	var rootNavigationLevel = this.rootNavigationLevel;
	var navigationItems = rootNavigationLevel.navigationItems;

	var navigationLevels = this.navigationLevels = [rootNavigationLevel];

	if (this.currentTeaser) {
		if (this.currentTeaser.element.parentNode)
			this.currentTeaser.element.parentNode.removeChild (this.currentTeaser.element);

	}

	this.currentTeaser = undefined;

	for (var i = navigationItems.length; i--;) {
		var navigationItem = navigationItems [i];

		if (navigationItem.isActive) {
			if (navigationItem.isCombinedLevel)
				continue;

			if (navigationItem.teaser) {
				// navigationLevels.push (navigationItem.teaser);
				this.currentTeaser = navigationItem.teaser;
				
			}
			
			if (navigationItem.subItemList) {
				var nextNavigationLevel = navigationItem.getSubNavigationLevel ();

				if (nextNavigationLevel) {
					navigationLevels.push (nextNavigationLevel);

					navigationItems = nextNavigationLevel.navigationItems;
					i = navigationItems.length;

					nextNavigationLevel.markItemsAreCurrent (false);

				}

			} else {
				break;

			}

		}

	}

};

NavigationDropDown.prototype.selectNavigationItem = function (navigationItem, fromHeaderNavigation) {
	if (fromHeaderNavigation) {
		if (this.isExpanded && navigationItem.isCurrent) {
			if (navigationItem.isCurrent) {
				navigationItem.markIsCurrent (false);
				this.collapse (true);

				return;

			}

		} else {

			if (this.isExpanded) {
				this.setUpCurrentNavigationTransition ();

			} else {
				this.expand (true);

			}

			if (navigationItem.isActive) {
				this.navigateToActivePage ();
				this.updateLayout (false, true);

				return;

			}

		}

	}

	var navigationLevels = this.navigationLevels = new Array ();

	if (navigationItem) {
		navigationItem.parentLevel.element.classList.add ("opened");

		var currentLevel = navigationItem.parentLevel;
		currentLevel.element.classList.add ("opened");

		currentLevel.markItemsAreCurrent (false);

		while (currentLevel) {
			navigationLevels.unshift (currentLevel);

			var parentItem = currentLevel.parentItem;
			if (!parentItem)
				break;

			/*
			if (parentItem.teaser)
				navigationLevels.unshift (parentItem.teaser);
			*/

			currentLevel = parentItem.parentLevel;

		}

		/*
		if (navigationItem.teaser)
			navigationLevels.push (navigationItem.teaser);
		*/

		if (this.currentTeaser) {
			if (this.currentTeaser.element.parentNode)
				this.currentTeaser.element.parentNode.removeChild (this.currentTeaser.element);

		}

		if (navigationItem.teaser)
			this.currentTeaser = navigationItem.teaser;
		else if (navigationItem.parentLevel.depth == 0)
			this.currentTeaser = undefined;
		
		if (navigationItem.subItemList) {
			var subLevel = navigationItem.getSubNavigationLevel ();

			subLevel.markItemsAreCurrent (false);
			navigationLevels.push (subLevel);

		}

	} else {
		navigationLevels.push (this.rootNavigationLevel);

	}

	this.updateLayout (!fromHeaderNavigation, true);

	this.getStage ().enterMode (Navigation.MODE_NORMAL);

};

NavigationDropDown.prototype.clickOverlayCloseButton = function (event) {
	event.preventDefault ();

	this.collapse (true);

};

NavigationDropDown.prototype.selectUpperLevel = function () {
	var navigationLevel = this.navigationLevels.pop ();
	this.selectNavigationItem (navigationLevel.parentItem.parentLevel.parentItem);

	navigationLevel.parentItem.parentLevel.element.classList.remove ("opened");
	navigationLevel.parentItem.parentLevel.markItemsAreCurrent (false);

};

NavigationDropDown.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	var isMobile = this.isMobile = viewSize [0] < 1024;

	this.updateLayout ();

};

NavigationDropDown.prototype.updateLayout = function (animated, animatedOverlay) {
	if (!this.isExpanded)
		return;

	var navigationLevels = this.navigationLevels.concat ();
	var navigationLevelContainers = this.navigationLevelContainers;

	var isMobile = this.isMobile;

	var backLink;

	if (isMobile) {
		var teaser = navigationLevels [1];
		if (teaser && teaser.element.classList.contains ("page-navigation-teaser")) {
			navigationLevels.splice (1, 1);

		}

	} else {
		navigationLevels.shift ();
		backLink = navigationLevels [0] && navigationLevels [0].backLink;

	}

	if (backLink)
		backLink.classList.add ("hidden");

	var navigationOverlay = this.navigationOverlay;
	navigationOverlay.style.display = "";



	var gridColumns = Site.sharedInstance.gridColumns;

	var offsetLeft = this.offsetLeft = gridColumns [0].firstElementChild.getBoundingClientRect ().left;
	var offsetRight = this.offsetRight = gridColumns [11].firstElementChild.getBoundingClientRect ().right;

	var layoutWidth = offsetRight - offsetLeft;

	var columnSpacing = this.columnSpacing =
		gridColumns [1].firstElementChild.getBoundingClientRect ().left -
		gridColumns [0].firstElementChild.getBoundingClientRect ().right;

	var viewSize = this.viewSize.concat ();
	viewSize [0] = layoutWidth;

	var numColumns = navigationLevels.length;
	var numVisibleColumns = false && viewSize [0] <= 504 ?
		1 :
		Math.min (4, Math.max (1, Math.round (
			viewSize [0] / 320

		)));
	
	var columnWidth = this.columnWidth = (viewSize [0] - (numVisibleColumns - 1) * columnSpacing) / numVisibleColumns + columnSpacing;

	for (var i = 0; i < navigationLevels.length; i++) {
		var navigationLevel = navigationLevels [i];

		var container = navigationLevelContainers [i];
		if (!container) {
			container = navigationLevelContainers [i] = this.attachSprite (NavigationLevelContainer);
			navigationOverlay.appendChild (container.element);

			container.didCreate = true;

			if (animated) {
				container.setAlpha (.4);
				container.fadeIn ();

			}

		}

		var lastNavigationLevel = container.takeNavigationLevel (navigationLevel);
		if (lastNavigationLevel && animated) {
			var fadeOutContainer = this.attachSprite (NavigationLevelContainer);
			navigationOverlay.appendChild (fadeOutContainer.element);

			fadeOutContainer.takeNavigationLevel (lastNavigationLevel);
			fadeOutContainer.setPosition (container.position);
			fadeOutContainer.element.style.width = container.element.style.width;
			fadeOutContainer.element.style.height = container.element.style.height;

			fadeOutContainer.element.style.zIndex = container.element.style.zIndex;
			container.element.style.zIndex += 1;

			var previousContainer = navigationLevelContainers [i - 1];
			if (previousContainer)
				fadeOutContainer.setTargetOffset (numColumns >= numVisibleColumns  ? previousContainer.targetOffset : Math.max (offsetLeft + columnWidth, previousContainer.targetOffset), true);

			// trace ("prev", numColumns, numVisibleColumns);

			fadeOutContainer.addListener ("completeFade", this.completeFadeContainer, this);
			fadeOutContainer.fadeOut (5, 20);

			// fadeOutContainer.navigationLevel.mobileNavigationTools.classList.add ("hidden");

			// trace ("did shift", fadeOutContainer.element.querySelector ("li a").textContent);

			// trace ("did shift", fadeOutContainer.element.querySelector ("li a").textContent);

		}

	}

	var firstContainer = navigationLevelContainers [0];
	var coversTeaser = firstContainer && firstContainer.baseOffset - this.viewOffset >= 0;

	while (navigationLevelContainers.length > navigationLevels.length) {
		var container = navigationLevelContainers.pop ();

		if (animated) {
			var firstContainer = navigationLevelContainers [0];
			var previousContainer = navigationLevelContainers [navigationLevelContainers.length - 1];
			if (previousContainer && coversTeaser)
				container.setTargetOffset (previousContainer.targetOffset, true);

			container.addListener ("completeFade", this.completeFadeContainer, this);
			container.fadeOut (2, 0);

			// trace ("did shift", container.element.querySelector ("li a").textContent);

		} else {
			this.completeFadeContainer (container);

		}

	}

	var navigationOverlay = this.navigationOverlay;
	var navigationOverlayBounds = navigationOverlay.getBoundingClientRect ();
	var layoutHeight = viewSize [1] - navigationOverlayBounds.top;

	var cursor = isMobile ? 0 : columnWidth;
	var maxHeight = 0;

	for (var i = 0; i < navigationLevelContainers.length; i++) {
		var navigationLevelContainer = navigationLevelContainers [i];
		navigationLevelContainer.baseOffset = cursor + columnSpacing;

		navigationLevelContainer.size [0] = columnWidth - columnSpacing;
		navigationLevelContainer.element.style.width = columnWidth - columnSpacing + "px";
		cursor += columnWidth;

		var levelHeight = navigationLevelContainer.contentWrapper.offsetHeight;

		maxHeight = Math.max (maxHeight, levelHeight);

		navigationLevelContainer.element.style.zIndex = 1000 - 1 - i * 10;

		var parentItem = navigationLevelContainer.navigationLevel.parentItem;
		if (parentItem) {
			parentItem.parentLevel.markItemsAreCurrent (false);
			parentItem.markIsCurrent (true);

		}

	}

	var currentTeaser = this.currentTeaser;
	
	if (currentTeaser && firstContainer) {
		if (currentTeaser.element.parentNode != navigationOverlay)
			navigationOverlay.appendChild (currentTeaser.element);
		
		const insetTeaser = this.insetTeaser = viewSize [0] < 1024 && viewSize [0] >= 768;
		if (insetTeaser) {
			const stageSize = this.getStage ().size;
			const teaserInset = this.teaserInset = (stageSize [0] - (offsetRight - offsetLeft)) * -.5 + 60;
			currentTeaser.baseOffset = teaserInset;
			
			currentTeaser.element.style.paddingRight = "0";
			
		} else {
			currentTeaser.baseOffset = 0; // (numVisibleColumns - 1) * columnWidth;
			
			currentTeaser.element.style.paddingRight = "";
			
		}
		
		var isResizing = this.getStage ().isResizing;

		// if (cursor - 1 <= currentTeaser.baseOffset && !this.isMobile) {
		if (!this.isMobile) {
			currentTeaser.isShown = true;

			if (!this.insetTeaser) {
				if (isResizing) {
					currentTeaser.stopAnimation ("Fade");
					currentTeaser.setAlpha (1);
					
				} else {
					currentTeaser.fadeIn (4);
					
				}
				
			}

			currentTeaser.element.style.position = "absolute";
			currentTeaser.element.style.width = columnWidth - columnSpacing + "px";

			currentTeaser.element.style.display = "";
			maxHeight = Math.max (maxHeight, currentTeaser.element.offsetHeight);
			
			if (!currentTeaser.alpha)
				currentTeaser.element.style.display = "none";
			
		} else {
			currentTeaser.isShown = false;

			if (isResizing  || numVisibleColumns <= 2 || !animated) {
				currentTeaser.stopAnimation ("Fade");
				currentTeaser.setAlpha (0);
				currentTeaser.element.style.display = "none";

			} else {
				currentTeaser.fadeOut (4);

			}
			
		}

		// trace ("currentTeaser.isShown", currentTeaser.isShown);

	}

	maxHeight += 5;

	var viewSize = this.viewSize;
	var offsetTop; // = navigationOverlay.getBoundingClientRect ().top;

	if (this.isMobile) {
		offsetTop = 60;
		maxHeight += offsetTop;
		maxHeight = viewSize [1]; // - offsetTop;

	} else {
		offsetTop = 100; // viewSize [0] < 992 ? 78 : 100;
		maxHeight += offsetTop;
		maxHeight = Math.min (maxHeight, viewSize [1]); // - offsetTop);

	}

	this.offsetTop = offsetTop;
	
	maxHeight = this.overlayHeight = firstContainer ?
		Math.min (maxHeight, layoutHeight) :
		this.getStage ().offsetHeight;
	
	this.setOverlayHeight (maxHeight, animated || animatedOverlay);

	var viewOffset = this.viewOffset = Math.max (columnSpacing, cursor - viewSize [0] + 2 * (this.viewSize [0] - offsetRight));

	this.updateViewOffset (animated);

};

NavigationDropDown.prototype.setOverlayHeight = function (targetOverlayHeight, animated) {
	if (targetOverlayHeight > 0 && targetOverlayHeight < 10) {
		this.collapse (true);
		return;

	}

	var navigationOverlay = this.navigationOverlay;

	if (animated) {
		this.startOverlayHeight = parseFloat (navigationOverlay.style.height) || 0;
		this.targetOverlayHeight = targetOverlayHeight;

		this.startAnimation ("OverlayHeight", {direction: 1, rate: .04, phase: 0});

	} else {
		this.targetOverlayHeight = targetOverlayHeight;
		navigationOverlay.style.height = targetOverlayHeight + "px";

		this.updateLevelContainersHeight (true);

	}

};

NavigationDropDown.borderColourForOverlayHeight = function (overlayHeight) {
	return "rgba(1, 60, 116, " + (Math.max (0, Math.min (1, overlayHeight * .01 - .5)) * .2) + ")";

};

NavigationDropDown.prototype.animateOverlayHeight = function () {
	var state = this.updatedState ("OverlayHeight");
	var t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) / 2;

	var t_ = 1 - t;

	var navigationOverlay = this.navigationOverlay;
	var overlayHeight = t_ * this.startOverlayHeight + t * this.targetOverlayHeight;

	if (overlayHeight) {
		navigationOverlay.style.height = overlayHeight + "px";

		navigationOverlay.style.borderBottomColor = NavigationDropDown.borderColourForOverlayHeight (overlayHeight);

		this.updateLevelContainersHeight (t == 1);

	} else {
		this.getStage ().element.classList.remove ("opened");

	}
	const size = this.getStage ().size;
	navigationOverlay.style.display =
		overlayHeight < (size [0] < 768 ? this.getStage ().offsetHeight : 5) ? "none" : "";

	var transitionLayer = this.transitionLayer;
	if (transitionLayer)
		transitionLayer.element.style.height = parseFloat (navigationOverlay.style.height) - this.offsetTop + "px";

};

NavigationDropDown.prototype.updateLevelContainersHeight = function (enableScrolling) {
	var navigationOverlay = this.navigationOverlay;
	var overlayHeight = parseFloat (navigationOverlay.style.height); // this.overlayHeight; // parseFloat (navigationOverlay.style.height);

	var navigationLevelContainers = this.children;
	var offsetTop = this.offsetTop;

	overlayHeight -= offsetTop;

	for (var i = navigationLevelContainers.length; i--;) {
		var navigationLevelContainer = navigationLevelContainers [i];
		if (!(navigationLevelContainer instanceof NavigationLevelContainer))
			continue;

		navigationLevelContainer.element.style.height = overlayHeight + "px";
		navigationLevelContainer.contentElement.style.height = overlayHeight + "px";
		navigationLevelContainer.contentElement.style.overflow = enableScrolling ? "" : "hidden";

	}

	/*
	var currentTeaser = this.currentTeaser;
	if (currentTeaser) {
		currentTeaser.element.style.height = overlayHeight + "px";
		currentTeaser.element.style.overflow = enableScrolling ? "scroll" : "hidden";

	}
	*/

};

NavigationDropDown.prototype.completeFadeContainer = function (container) {
	container.removeListener ("completeFade", this.completeFadeContainer, this);

	this.removeChild (container);

	container.navigationLevel.element.classList.remove ("opened");

	var containerElement = container.element;
	if (containerElement.parentNode)
		containerElement.parentNode.removeChild (containerElement);

};

NavigationDropDown.prototype.updateViewOffset = function (animated) {
	var viewOffset = this.viewOffset;
	const currentViewOffset = viewOffset;
	
	var offsetLeft = this.offsetLeft;
	viewOffset -= offsetLeft;
	
	var navigationLevelContainers = this.navigationLevelContainers;
	for (var i = navigationLevelContainers.length; i--;) {
		var navigationLevelContainer = navigationLevelContainers [i];

		var baseOffset = navigationLevelContainer.baseOffset;

		if (navigationLevelContainer.didCreate) {
			var previousContainer = navigationLevelContainers [i - 1];

			if (previousContainer && animated) {
				navigationLevelContainer.position [0] = previousContainer.position [0]; // baseOffset - this.viewOffset;
				navigationLevelContainer.setTargetOffset (baseOffset - viewOffset, previousContainer.baseOffset - this.viewOffset >= -1);

				// trace ("did shift", navigationLevelContainer.element.querySelector ("li a").textContent, navigationLevelContainer.targetOffset);

			} else {
				navigationLevelContainer.setTargetOffset (baseOffset - viewOffset, animated);

			}

			navigationLevelContainer.didCreate = false;

		} else {
			navigationLevelContainer.navigationLevel.setAlpha (baseOffset - viewOffset - this.offsetLeft < -1 ? 0 : 1);
			navigationLevelContainer.setTargetOffset (baseOffset - viewOffset, animated);

		}

	}
	
	function showBackLinkForNavigationLevelContainer (navigationLevelContainer, doShow) {
		if (!navigationLevelContainer)
			return;
		
		const navigationLevel = navigationLevelContainer.navigationLevel;
		
		const backLink = navigationLevel.backLink;
		
		if (doShow) {
			backLink && backLink.classList.remove ("hidden");
			
		} else {
			backLink && backLink.classList.add ("hidden");
			
		}
		
	};
	
	function showMobileToolsForNavigationLevelContainer (navigationLevelContainer, doShow) {
		if (!navigationLevelContainer)
			return;
		
		const navigationLevel = navigationLevelContainer.navigationLevel;
		const mobileNavigationTools = navigationLevel.mobileNavigationTools;
		
		if (doShow) {
			mobileNavigationTools && mobileNavigationTools.classList.remove ("hidden");
			
		} else {
			mobileNavigationTools && mobileNavigationTools.classList.add ("hidden");
			
		}
		
	};
	
	var navigationLevelContainer = navigationLevelContainers [0];
	showBackLinkForNavigationLevelContainer (
		navigationLevelContainer,
		!this.isMobile && viewOffset > 15
		
	);
	
	for (var i = 1; i < navigationLevelContainers.length; i++) {
		var navigationLevelContainer = navigationLevelContainers [i];
		var previousContainer = navigationLevelContainers [i - 1];
		
		showBackLinkForNavigationLevelContainer (
			navigationLevelContainer,
			previousContainer.baseOffset + this.offsetLeft - viewOffset < (this.insetTeaser ? -this.teaserInset : -1)
			
		);
		showMobileToolsForNavigationLevelContainer (
			navigationLevelContainer,
			previousContainer.baseOffset + this.offsetLeft - viewOffset < (this.insetTeaser ? -this.teaserInset : -1)
			
		);
		
	}
	
	const currentTeaser = this.currentTeaser;
	if (currentTeaser) {
		currentTeaser.setPosition (
			currentTeaser.baseOffset + offsetLeft,
			0

		);
		
		if (this.insetTeaser) {
			if (currentViewOffset < 60)
				currentTeaser.fadeIn (1);
			else if (currentTeaser.alpha)
				currentTeaser.fadeOut (1.25);
			
		} else {
			currentTeaser.stopAnimation ("Fade");
			currentTeaser.setAlpha (1);
			
		}
		
		currentTeaser.renderInContext ();
		
	}
	this.renderInContext ();

};

NavigationDropDown.prototype.expand = function (animated) {
	if (this.isExpanded)
		return;

	this.isExpanded = true;

	var stage = this.getStage ();
	var element = stage.element;
	element.classList.add ("expanded");

	var clickStageWhileExpanded = this.clickStageWhileExpanded;
	if (!clickStageWhileExpanded)
		clickStageWhileExpanded = this.clickStageWhileExpanded = NavigationDropDown.clickStageWhileExpanded.bind (this);

	window.addEventListener ("click", clickStageWhileExpanded, true);

	stage.slideIn ();

	stage.element.classList.add ("opened");
	this.element.classList.add ("opened");
	document.body.classList.add ("scroll-locked");
	
	if (!Navigation.isTouchDevice) {
		stage.addListener ("mouseout", this.mouseOutWhileExpanded, this);
		stage.addListener ("mouseover", this.mouseOverWhileExpanded, this);

	}

};

NavigationDropDown.prototype.collapse = function (animated) {
	if (!this.isExpanded)
		return;

	this.isExpanded = false;

	var navigationItems = this.navigationLevels [0].navigationItems;
	for (var i = navigationItems.length; i--;) {
		var navigationItem = navigationItems [i];
		navigationItem.markIsCurrent (false);

	}

	var stage = this.getStage ();
	var element = stage.element;
	window.setTimeout (function () {
		element.classList.remove ("expanded");

	}, 100);
	
	this.setOverlayHeight (0, animated);

	var clickStageWhileExpanded = this.clickStageWhileExpanded;
	window.removeEventListener ("click", clickStageWhileExpanded, true);

	this.element.classList.remove ("opened");
	document.body.classList.remove ("scroll-locked");

	if (!Navigation.isTouchDevice) {
		stage.removeListener ("mouseout", this.mouseOutWhileExpanded, this);
		stage.removeListener ("mouseover", this.mouseOverWhileExpanded, this);

	}

};

NavigationDropDown.prototype.mouseOutWhileExpanded = function () {
	this.mouseOutHandle = window.setTimeout (function () {
		this.mouseOutHandle = null;

		if (this.isMobile)
			return;

		this.collapse (true);

	}.bind (this), 200);

};

NavigationDropDown.prototype.mouseOverWhileExpanded = function () {
	if (this.mouseOutHandle) {
		window.clearTimeout (this.mouseOutHandle);
		this.mouseOutHandle = null;

	}

};

NavigationDropDown.clickStageWhileExpanded = function (event) {
	var element = this.getStage ().element;
	var target = event.target;

	while (target) {
		if (target == element)
			return;

		target = target.parentNode;

	}

	event.preventDefault ();
	event.stopPropagation ();
	event.stopImmediatePropagation ();

	this.collapse (true);

};

NavigationDropDown.prototype.setUpCurrentNavigationTransition = function () {
	var navigationLevelContainers = this.navigationLevelContainers;

	var transitionLayer = this.transitionLayer = this.attachSprite (AnimatableSprite);
	transitionLayer.setPosition (undefined, undefined);

	var navigationOverlay = this.navigationOverlay;
	navigationOverlay.parentNode.insertBefore (transitionLayer.element, navigationOverlay);

	transitionLayer.element.classList.add ("page-navigation-overlay");
	transitionLayer.element.classList.add ("transition-layer");

	for (var i = navigationLevelContainers.length; i--;) {
		const navigationLevelContainer = navigationLevelContainers [i];
		transitionLayer.addChild (navigationLevelContainer);

	}

	var currentTeaser = this.currentTeaser;
	if (currentTeaser) {
		transitionLayer.element.appendChild (currentTeaser.element)

		this.currentTeaser = undefined;

	}

	transitionLayer.element.style.height = this.navigationOverlay.style.height;
	transitionLayer.element.style.zIndex = 1000;

	navigationLevelContainers.length = 0;

	transitionLayer.addListener ("completeFade", this.completeFadeTransitionLayer, this);
	transitionLayer.fadeOut (1.85);

};

NavigationDropDown.prototype.completeFadeTransitionLayer = function (transitionLayer) {
	transitionLayer.removeListener ("completeFade", this.completeFadeTransitionLayer, this);

	transitionLayer.parent.removeChild (transitionLayer);
	this.transitionLayer = null;

};

//
// NavigationLevelContainer extends AnimatableSprite
//

var NavigationLevelContainer = function (context) {
	AnimatableSprite.apply (this, arguments);

	var element = this.element;
	element.classList.add ("page-navigation-level-container-wrapper");

	var contentElement = this.contentElement = document.createElement ("div");
	contentElement.classList.add ("page-navigation-level-container");
	element.appendChild (contentElement);

	var contentWrapper = this.contentWrapper = document.createElement ("div");
	contentElement.appendChild (contentWrapper);

};

NavigationLevelContainer.prototype = Object.create (AnimatableSprite.prototype);

NavigationLevelContainer.prototype.takeNavigationLevel = function (navigationLevel) {
	var lastNavigationLevel = this.navigationLevel;
	if (lastNavigationLevel == navigationLevel)
		return;

	this.navigationLevel = navigationLevel;

	var contentElement = this.contentElement;
	var contentWrapper = this.contentWrapper;

	while (contentWrapper.hasChildNodes ())
		contentWrapper.removeChild (contentWrapper.lastChild);


	var backLink = navigationLevel.backLink;
	if (backLink)
		contentWrapper.appendChild (backLink);

	if (!navigationLevel.depth)
		contentElement.classList.add ("page-navigation-level-container-level-one");

	contentWrapper.appendChild (navigationLevel.element);

	var mobileNavigationTools = navigationLevel.mobileNavigationTools = this.getStage ().element.querySelector (".navigation-tools-mobile").cloneNode (true);
	contentWrapper.appendChild (mobileNavigationTools);


	return lastNavigationLevel;

};

NavigationLevelContainer.prototype.setTargetOffset = function (offset, animated) {
	if (animated) {
		var startOffset = this.position [0];
		if (Math.abs (startOffset - offset) <= 1)
			return;

		this.startOffset = startOffset;
		this.targetOffset = offset;

		this.startAnimation ("Slide", {direction: 1, rate: .04, phase: 0});

	} else {
		this.targetOffset = offset;

		this.stopAnimation ("Slide");
		this.setPosition (offset, undefined);

		this.updateAlpha ();

	}

};

NavigationLevelContainer.prototype.animateSlide = function () {
	var state = this.updatedState ("Slide");
	var t = 1 - state.phase;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) / 2;
	var t_ = 1 - t;

	this.setPosition (
		Math.round (t_ * this.startOffset + t * this.targetOffset),
		undefined

	);

	this.updateAlpha ();

};

NavigationLevelContainer.prototype.updateAlpha = function () {
	const parent = this.parent;
	if (!parent)
		return;
	
	var width = this.size [0] / 1.125;

	this.navigationLevel.setAlpha (
		Math.max (.001,
			(this.position [0] - parent.offsetLeft + width) / width

		)

	);

};

//
// NavigationLevel extends AnimatableSprite
//

var NavigationLevel = function (context) {
	AnimatableSprite.apply (this, arguments);

};

NavigationLevel.prototype = Object.create (AnimatableSprite.prototype);

NavigationLevel.prototype.toString = function () {
	return "[NavigationLevel " + this.depth + " " + this.navigationItems + "]";

};

NavigationLevel.prototype.takeElement = function (sourceElement, depth, combineTwoLevels) {
	this.depth = depth;

	var element = this.element;
	element.parentNode.removeChild (element);

	var contentElement = this.element = document.createElement ("ul");
	contentElement.classList.add ("page-navigation-level");

	if (!depth)
		contentElement.classList.add ("page-navigation-level-one");

	var children = sourceElement.children;
	var navigationItems = this.navigationItems = new Array ();

	// var combineTwoLevels = depth == 1;

	for (var i = 0; i < children.length; i++) {
		var originalChild = children [i];
		if (!depth) {
			if (originalChild.classList.contains ("isActive"))
				originalChild.classList.add ("active");
			
		}
		var child = originalChild.cloneNode (true);
		
		contentElement.appendChild (child);

		var navigationItem = this.attachSprite (NavigationItem);
		navigationItem.parentLevel = this;
		navigationItem.takeElement (child, originalChild, depth);
		navigationItems.push (navigationItem);

		if (combineTwoLevels) {
			navigationItem.isCombinedLevel = true;

			var hasActiveChild = false;

			var subList = child.querySelector ("ul");
			if (subList) {
				var subChildren = subList.children;

				for (var j = 0; j < subChildren.length; j++) {
					var subChild = subChildren [j];

					if (!hasActiveChild && (
						subChild.classList.contains ("active") ||
						subChild.classList.contains ("isActive") ||
						subChild.classList.contains ("isCurrent")))
						hasActiveChild = true;

					var subNavigationItem = this.attachSprite (NavigationItem);
					subNavigationItem.parentLevel = this;
					subNavigationItem.takeElement (subChild, null, depth);
					navigationItems.push (subNavigationItem);

				}

			}

			if (!hasActiveChild) {
				navigationItem.element.classList.add ("has-no-active-child");

			}

		}

	}

	if (combineTwoLevels)
		contentElement.classList.add ("page-navigation-level-two");

	var parentItem = this.parentItem;
	if (parentItem) {
		var backLink = this.backLink = document.createElement ("div");
		backLink.classList.add ("page-navigation-back-link");

		var span = document.createElement ("span");
		span.appendChild (document.createTextNode (
			parentItem.title

		));
		backLink.appendChild (span);

		backLink.addEventListener ("click", function (event) {
			this.getStage ().navigationDropDrown.selectUpperLevel ();
			// this.getStage ().navigationDropDrown.selectNavigationItem (parentItem.parentLevel.parentItem);

		}.bind (this));

	}

};

NavigationLevel.prototype.markItemsAreCurrent = function (markItemsAreCurrent) {
	var navigationItems = this.navigationItems;
	for (var i = navigationItems.length; i--;) {
		var navigationItem = navigationItems [i];
		navigationItem.markIsCurrent (markItemsAreCurrent);

	}

	// this.element.classList.remove ("opened");

};

//
// NavigationItem extends AnimatableSprite
//

var NavigationItem = function (context) {
	AnimatableSprite.apply (this, arguments);


};

NavigationItem.map = new Object ();

NavigationItem.prototype = Object.create (AnimatableSprite.prototype);

NavigationItem.prototype.toString = function () {
	return "[NavigationItem " + this.title + "]";

};

NavigationItem.prototype.takeElement = function (element, originalElement, depth) {
	this.depth = depth;

	this.element.parentNode.removeChild (this.element);
	this.element = element;
	this.originalElement = originalElement;
	
	var isActive = this.isActive =
		element.classList.contains ("active") ||
		element.classList.contains ("isActive") ||
		element.classList.contains ("isCurrent");
	
	if (isActive)
		element.classList.add ("active");
	element.classList.add ("page-navigation-item");

	function clickHandler (event) {
		var anchor = event.currentTarget;
		if (anchor.classList.contains ("folder-link")) {
			event.preventDefault ();

		}

		if (this.mouseOverHandle) {
			event.preventDefault ();
			return;
			
		}
		
		if (new Date ().getTime () < this.clickDebounceTime && this.subItemList) {
			event.preventDefault ();
			return;
			
		}

		if (this.isCombinedLevel) {
			// event.preventDefault ();

		} else if (this.subItemList) {
			event.preventDefault ();
			this.getStage ().navigationDropDrown.selectNavigationItem (this, event.currentTarget == originalAnchor);

		}

	};

	var anchor = element.querySelector ("a");
	if (!anchor)
		return;
	anchor.addEventListener ("click", clickHandler.bind (this));

	if (Environment.IS_IE) {
		var anchorContent = anchor.innerHTML;
		if (anchorContent.indexOf ("/-") > 0)
			anchor.innerHTML = anchorContent.replace ("/-", "/&thinsp;-");

	}

	if (originalElement) {
		var originalAnchor = originalElement.querySelector ("a");
		originalAnchor.addEventListener ("click", clickHandler.bind (this));

		if (!this.parentLevel.depth) {
			originalAnchor.addEventListener ("touchstart", function (event) {
				Navigation.isTouchDevice = true;

			}.bind (this));

			originalAnchor.addEventListener ("mouseover", function (event) {
				if (Navigation.isTouchDevice)
					return;
				
				if (!this.subItemList) {
					/*
					var navigationDropDrown = this.getStage ().navigationDropDrown;
					navigationDropDrown.currentTeaser = undefined;
					*/
					
				}
				
				this.mouseOverHandle = window.setTimeout (function () {
					this.mouseOverHandle = null;
					var navigationDropDrown = this.getStage ().navigationDropDrown;

					if (navigationDropDrown.isExpanded && this.isCurrent) {

					} else {
						this.getStage ().navigationDropDrown.selectNavigationItem (this, true);
						this.clickDebounceTime = new Date ().getTime () + 500;

					}

				}.bind (this), 125);

			}.bind (this));

			originalAnchor.addEventListener ("mouseout", function (event) {
				if (this.mouseOverHandle) {
					window.clearTimeout (this.mouseOverHandle);
					this.mouseOverHandle = null;

				}

			}.bind (this));

		}

	}

	var title = this.title = anchor.textContent;
	NavigationItem.map [title] = this;

	var childContainer = element.querySelector ("div");
	if (childContainer) {
		var children = childContainer.children;
		for (var i = 0; i < children.length; i++) {
			var child = children [i];
			if (child.classList.contains ("page-navigation-teaser")) {
				var teaser = this.teaser = this.parent.attachSprite (AnimatableSprite);
				teaser.element.parentNode.removeChild (teaser.element);
				teaser.element = child;
				
			} else if (child.nodeName == "UL") {
				this.subItemList = child;

				element.classList.add ("page-navigation-item--with-sub-items");

			}

		}

	}

};

NavigationItem.prototype.getSubNavigationLevel = function () {
	var subItemList = this.subItemList;
	if (!subItemList)
		return;

	var subNavigationLevel = this.subNavigationLevel;
	if (!subNavigationLevel) {
		subNavigationLevel = this.subNavigationLevel = this.attachSprite (NavigationLevel);
		subNavigationLevel.parentItem = this;

		subNavigationLevel.takeElement (subItemList, this.depth + 1, this.element.getAttribute ("data-combine-two-levels") == "yes");

	}

	return subNavigationLevel;

};

NavigationItem.prototype.markIsCurrent = function (isCurrent) {
	if (this.isCurrent == isCurrent)
		return;

	this.isCurrent = isCurrent;

	function applyClass (element) {
		if (!element)
			return;

		if (isCurrent) {
			element.classList.add ("current");
			
		} else {
			element.classList.remove ("current");
			// element.classList.remove ("active");
			
		}
		
	}
	
	applyClass (this.element);
	applyClass (this.originalElement);
	
};

//
// Navigation.SearchField extends AnimatableSprite
//

Navigation.SearchField = function (context) {
	AnimatableSprite.apply (this, arguments);

};

Navigation.SearchField.prototype = Object.create (AnimatableSprite.prototype);

Navigation.SearchField.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	var closeButton = this.closeButton = element.querySelector (".page-tool-close");
	closeButton.addEventListener ("click", this.clickCloseButton.bind (this));
	
	const form = element.querySelector ("form");
	const submitButton = this.submitButton = form.querySelector ("button[type=submit]");
	const inputField = this.inputField = form.querySelector ("input[type=search]");
	
	form.addEventListener ("submit", function (event) {
		if (!inputField.value.trim ())
			event.preventDefault ();
		
	});
	
};

Navigation.SearchField.prototype.setWidth = function (width, keepMenuButton, hideHeaderLogo) {
	this.width = width;
	this.keepMenuButton = keepMenuButton;
	this.hideHeaderLogo = hideHeaderLogo;

	var element = this.element;
	element.style.width = width + "px";

	this.drawExpansionForT (this.lastT || 0);

};

Navigation.SearchField.prototype.clickCloseButton = function () {
	this.getStage ().enterMode (Navigation.MODE_NORMAL);

};

Navigation.SearchField.prototype.show = function () {
	if (this.isShown)
		return;

	this.isShown = true;

	this.startAnimation ("Expansion", {direction: 1, rate: .07});

	var viewportMeta = document.querySelector ("meta[name=viewport]");
	if (viewportMeta) {
		this.originalViewportSetting = viewportMeta.getAttribute ("content");
		viewportMeta.setAttribute ("content", "width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=0");

	}

	var inputField = this.inputField;
	inputField.focus ();

};

Navigation.SearchField.prototype.hide = function () {
	if (!this.isShown)
		return;

	this.isShown = false;

	this.startAnimation ("Expansion", {direction: 0, rate: .07});

	var originalViewportSetting = this.originalViewportSetting;
	if (originalViewportSetting) {
		var viewportMeta = document.querySelector ("meta[name=viewport]");
		viewportMeta.setAttribute ("content", originalViewportSetting);

	}

};

Navigation.SearchField.prototype.animateExpansion = function () {
	var state = this.updatedState ("Expansion");
	var t = 1 - state.phase;

	this.drawExpansionForT (t);

};

Navigation.SearchField.prototype.drawExpansionForT = function (t) {
	this.lastT = t;

	t = 1 - t * t;
	var t_ = 1 - t;

	var element = this.element;
	element.style.width = this.width * t + "px";

	element.style.display = t ? "" : "none";

	const closeButton = this.closeButton;
	closeButton.style.opacity = Math.max (0, Math.min (1, t * 10 - 9));

	var submitButton = this.submitButton;
	submitButton.style.opacity = t * 2 - 1;
	
	const menuButton = this.parent.menuButton;
	menuButton.style.opacity = this.hideHeaderLogo ?
		1 - Math.max (0, Math.min (1, t * 10 - 9)) :
		1;
	
	const localeButton = this.parent.localeButton;
	if (localeButton) {
		localeButton.style.opacity =
			1 - Math.max (0, Math.min (1, t * 2));
		
	}
	
	/*
	*/
	var stage = this.getStage ();

	var navigationDropDrown = stage.navigationDropDrown;
	navigationDropDrown.element.style.opacity = 1 - t * 2;
	navigationDropDrown.element.style.visibility = t == 1 ? "hidden" : "";

	var toolElement = stage.toolElement;
	var searchButton = stage.searchButton;

	// trace ("keepMenuButton", this.keepMenuButton);

	if (this.keepMenuButton) {
		toolElement.style.opacity = "";
		toolElement.style.visibility = "";

		searchButton.style.opacity = 1 - t;
		searchButton.style.visibility = t == 1 ? "hidden" : "";

	} else {
		toolElement.style.opacity = 1 - t;
		toolElement.style.visibility = t == 1 ? "hidden" : "";

		searchButton.style.opacity = "";
		searchButton.style.visibility = "";

	}

	var headerLogo = stage.headerLogo;

	if (this.hideHeaderLogo) {
		headerLogo.style.opacity = 1 - t;
		headerLogo.style.visibility = t == 1 ? "hidden" : "";

	} else {
		headerLogo.style.opacity = "";
		headerLogo.style.visibility = "";

	}

};

//
// Navigation.LocaleLayer extends AnimatableSprite
//

Navigation.LocaleLayer = function (context) {
	AnimatableSprite.apply (this, arguments);

};

Navigation.LocaleLayer.prototype = Object.create (AnimatableSprite.prototype);

Navigation.LocaleLayer.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

};

Navigation.LocaleLayer.prototype.show = function () {
	if (this.isShown)
		return;

	this.isShown = true;

	var element = this.element;
	element.style.height = "auto";
	element.style.display = "";

	var stage = this.getStage ();
	var element = stage.element;
	element.classList.add ("expanded");

	this.updateLayout ();
	this.startAnimation ("Expansion", {direction: 1, rate: .04});

	document.body.classList.add ("scroll-locked");
	
	if (!Navigation.isTouchDevice) {
		stage.addListener ("mouseout", this.mouseOutWhileExpanded, this);
		stage.addListener ("mouseover", this.mouseOverWhileExpanded, this);

	}
	
};

Navigation.LocaleLayer.prototype.hide = function () {
	if (!this.isShown)
		return;

	this.isShown = false;

	var stage = this.getStage ();
	var element = stage.element;
	element.classList.remove ("expanded");

	this.startAnimation ("Expansion", {direction: 0, rate: .04});

	document.body.classList.remove ("scroll-locked");
	
	if (!Navigation.isTouchDevice) {
		stage.removeListener ("mouseout", this.mouseOutWhileExpanded, this);
		stage.removeListener ("mouseover", this.mouseOverWhileExpanded, this);
		
	}
	
};

Navigation.LocaleLayer.prototype.mouseOutWhileExpanded = function () {
	this.mouseOutHandle = window.setTimeout (function () {
		this.mouseOutHandle = null;

		if (this.isMobile)
			return;
		
		this.close ();
		
	}.bind (this), 200);

};

Navigation.LocaleLayer.prototype.mouseOverWhileExpanded = function () {
	if (this.mouseOutHandle) {
		window.clearTimeout (this.mouseOutHandle);
		this.mouseOutHandle = null;

	}

};

Navigation.LocaleLayer.prototype.close = function () {
	var navigation = Navigation.sharedInstance;
	navigation.enterMode (Navigation.MODE_NORMAL);

};

Navigation.LocaleLayer.prototype.updateLayout = function () {
	var element = this.element;

	var contentContainer = element.firstElementChild;

	contentContainer.style.height = "";
	var offsetHeight = this.offsetHeight = contentContainer.offsetHeight;
	
	var selfBounds = element.getBoundingClientRect ();
	var containerBounds = contentContainer.getBoundingClientRect ();

	var offsetTop = this.offsetTop = containerBounds.top - selfBounds.top;

	this.expandForT (this.lastT || 0);

};

Navigation.LocaleLayer.prototype.animateExpansion = function () {
	var state = this.updatedState ("Expansion");
	var t = state.phase;

	this.expandForT (t);

};

Navigation.LocaleLayer.prototype.expandForT = function (t) {
	this.lastT = t;

	t = 1 - t;
	t = 1 - t * t;
	t = .5 - Math.cos (t * Math.PI) / 2;
	var t_ = 1 - t;

	var viewSize = this.getStage ().size;

	var offsetHeight = this.offsetHeight;
	var offsetTop = this.offsetTop;

	var targetHeight = Math.min (viewSize [1], offsetHeight + offsetTop);

	var element = this.element;
	var contentContainer = element.firstElementChild;

	if (offsetHeight > viewSize [1]) {
		if (!contentContainer.style.overflow)
			contentContainer.style.overflow = "scroll";

	} else {
		if (contentContainer.style.overflow)
			contentContainer.style.overflow = "";

	}

	var overlayHeight = Math.round (t * targetHeight);

	element.style.height = overlayHeight + "px";
	element.style.borderBottomColor = NavigationDropDown.borderColourForOverlayHeight (overlayHeight);

	contentContainer.style.height = Math.round (t * (targetHeight - offsetTop)) + "px";

	element.style.display = t ? "" : "none";

};

