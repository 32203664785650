import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// ProductOverviewSlider extends SiteSection, SlidingSpritePrototype
//

export const ProductOverviewSlider = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ProductOverviewSlider = ProductOverviewSlider;

ProductOverviewSlider.prototype = Object.create (SiteSection.prototype);
ProductOverviewSlider.extendPrototype (SlidingSpritePrototype);

ProductOverviewSlider.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	SlidingSpritePrototype.apply (this, arguments);
	
	const scrollingController = this.scrollingController;
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	const teasers = this.teasers = element.querySelectorAll (".teaser");
	for (let i = teasers.length; i--;) {
		const teaser = teasers [i];
		
		teaser.addEventListener ("click", function (event) {
			if (Environment.FAST_PASS || scrollingController.didDrag) {
				event.preventDefault ();
				event.stopPropagation ();
				
			}
			
		}.bind (this));
		
		const img = teaser.querySelector ("img");
		if (img) {
			img.removeAttribute ("loading");
			/*
			img.addEventListener ("load", function () {
				this.updateLayout ();
				
			}.bind (this));
			*/
			
		}
		
	}
	
};

ProductOverviewSlider.prototype.getListenerTargetForScrollingController = function () {
	return this.element.parentNode;
	
};

ProductOverviewSlider.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	this.setIsScrollable (viewSize [0] < 768);
	this.updateLayout ();
	
};

ProductOverviewSlider.prototype.setIsScrollable = function (isScrollable) {
	if (this.isScrollable == isScrollable)
		return;
	this.isScrollable = isScrollable;
	
	const element = this.element;
	const scrollingController = this.scrollingController;
	
	if (isScrollable) {
		element.classList.add ("scrollable");
		scrollingController.awake ();
		this.snapToBounds ();
		this.currentViewOffset = this.viewOffset;
		this.updateViewOffset ();
		
	} else {
		element.classList.remove ("scrollable");
		scrollingController.sleep ();
		this.removeRunLoopHandler ("processSliding");
		element.style.transform = "";
		
	}
	
};

ProductOverviewSlider.prototype.updateLayout = function () {
	const teasers = this.teasers;
	const isScrollable = this.isScrollable;
	
	const viewportSize = this.getStage ().size;
	const width = this.element.offsetWidth;
	
	const layoutWidth = width + (viewportSize [0] - width) * .5;
	let teaserWidth = layoutWidth / 2.5;
	if (teaserWidth < 200)
		teaserWidth = Math.max (200, Math.min (300, layoutWidth / 1.5));
	
	for (let i = teasers.length; i--;) {
		const teaser = teasers [i];
		
		teaser.parentNode.style.flex = isScrollable ?
			"1 0 " + teaserWidth + "px" : "";
		teaser.parentNode.style.maxWidth = teaserWidth + "px";
		
	}
	
};

ProductOverviewSlider.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	// scrollingController.currentEvent.preventDefault ();
	scrollingController.currentEvent.stopPropagation ();
	
	delete this.maxScrollSpeed;
	
};

ProductOverviewSlider.prototype.boundedViewOffset = function (viewOffset) {
	const teasers = this.teasers;
	if (!teasers.length)
		return 0;
	
	const element = this.element;
	const width = element.offsetWidth;
	const viewportSize = this.getStage ().size;
	
	const teasersLeft = teasers [0].getBoundingClientRect ().left;
	const teasersRight = teasers [teasers.length - 1].getBoundingClientRect ().right;
	const teasersWidth = Math.round (teasersRight - teasersLeft);
	
	return Math.max (0, Math.min (teasersWidth - width + 20, viewOffset));
	
};

ProductOverviewSlider.prototype.snapToBounds = function (forceSnap) {
	var viewOffset = this.viewOffset;
	var targetViewOffset = this.boundedViewOffset (viewOffset);
	
	if (viewOffset != targetViewOffset || forceSnap) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

ProductOverviewSlider.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	
};

ProductOverviewSlider.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.5;
		
		this.viewOffset = targetViewOffset;
		this.snapToBounds (true);
		
	} else {
		this.snapToBounds ();
		
	}
	
};

ProductOverviewSlider.prototype.spacing = 40;

ProductOverviewSlider.prototype.bringIndexIntoView = function (index) {
	
};

ProductOverviewSlider.prototype.updateViewOffset = function () {
	const element = this.element;
	let viewOffset = this.currentViewOffset;
	viewOffset = (viewOffset + this.boundedViewOffset (viewOffset)) * .5;
	
	element.style.transform = "translateX(" +  -viewOffset + "px)";
	
	this.dispatchEvent ("updateViewOffset");
	this.renderInContext (this.context);
	
};
