import * as Environment from "../base/Environment.js";

import {RenderContext} from "../base/Display.js";
import {AnimatableSprite} from "../base/Display2D.js";

import {SiteSection} from "./SiteSection.js";
import {Navigation} from "./Navigation.js";

//
// OnePagerNavigation extends SiteSection
//

export const OnePagerNavigation = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.OnePagerNavigation = OnePagerNavigation;

OnePagerNavigation.prototype = Object.create (SiteSection.prototype);

OnePagerNavigation.prototype.renderInContext = function () {};

OnePagerNavigation.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const navigation = Navigation.sharedInstance;
	navigation.addListener ("scroll", this.scrollNavigation, this);
	
	const background = this.background = element.querySelector (".tubag-100-page-navigation-background, .page-navigation-background");
	const title = this.title = element.querySelector (".tubag-100-page-navigation-title, .page-navigation-title");
	title.addEventListener ("click", function (event) {
		// Site.sharedInstance.scrollToOffset (0);
		this.scrollToTargetElement (document.getElementById ("intro"), "intro");
		
	}.bind (this));
	
	const anchors = this.anchors = element.querySelectorAll ("a");
	const anchorMap = this.anchorMap = new Object ();
	
	const sectionElements = this.sectionElements = new Array ();
	
	for (let i = anchors.length; i--;) {
		let anchor = anchors [i];
		const sectionId = anchor.sectionId = anchor.getAttribute ("href").substr (1);
		anchorMap [sectionId] = anchor;
		
		const sectionElement = document.querySelector ("#" + sectionId);
		sectionElements.unshift (sectionElement);
		
		anchor.addEventListener ("click", function (event) {
			event.preventDefault ();
			this.scrollToTargetElement (sectionElement, sectionId);
			
		}.bind (this));
		
	}
	
	const hash = location.hash.substr (1);
	if (hash) {
		const targetElement = document.getElementById (hash);
		if (targetElement) {
			window.setTimeout (function () {
				this.scrollToTargetElement (targetElement);
				
			}.bind (this), 250);
			
		}
		
	}
	
};

OnePagerNavigation.prototype.scrollToTargetElement = function (targetElement, sectionId) {
	const bodyBounds = document.body.getBoundingClientRect ();
	const targetBounds = targetElement.getBoundingClientRect ();

	const maxScroll = Math.max (0, bodyBounds.height - RenderContext.getViewportSize () [1]);

	let targetScroll = Math.min (maxScroll,
		targetBounds.top - bodyBounds.top

	);

	const willScrollDown = targetScroll > RenderContext.getScrollOffset () [1];

	Site.sharedInstance.scrollToOffset (
		Math.max (0, targetScroll - (willScrollDown ? 0 : Navigation.sharedInstance.offsetHeight) - this.element.offsetHeight - 50 - 50)
		
	);
	
	if (sectionId) {
		const newURL = new URL (location.href);
		newURL.hash = "#" + sectionId;
		history.replaceState (null, "", newURL);
		
	}
	
	const navigationDropDrown = Navigation.sharedInstance.navigationDropDrown;
	if (navigationDropDrown && navigationDropDrown.isExpanded)
		navigationDropDrown.collapse (true);
	
};

OnePagerNavigation.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
};

OnePagerNavigation.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
};

OnePagerNavigation.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	this.updateBackgroundOffset ();
	
};

OnePagerNavigation.prototype.scrollNavigation = function (navigation) {
	const element = this.element;
	element.style.position = "";
	element.style.top = "";
	
	const bounds = element.getBoundingClientRect ();
	const navigationBottom = navigation.offsetHeight + navigation.position [1];
	
	if (bounds.top < navigationBottom) {
		element.style.position = "fixed";
		element.style.top = navigationBottom + "px";
		
	}
	
	const viewportSize = this.getStage ().size;
	
	const sectionElements = this.sectionElements;
	let activeSection;
	
	for (let i = sectionElements.length; i--;) {
		const sectionElement = sectionElements [i];
		const sectionBounds = sectionElement.getBoundingClientRect ();
		
		if (sectionBounds.top < viewportSize [1] * .5) {
			activeSection = sectionElement;
			break;
			
		}
		
	}
	
	this.setActiveSectionId (activeSection && activeSection.id);
	
};

OnePagerNavigation.prototype.setActiveSectionId = function (activeSectionId) {
	if (this.activeSectionId == activeSectionId)
		return;
	
	this.activeSectionId = activeSectionId;
	
	const anchors = this.anchors;
	for (let i = anchors.length; i--;) {
		const anchor = anchors [i];
		if (anchor.sectionId == activeSectionId) {
			if (!anchor.classList.contains ("active"))
				anchor.classList.add ("active");
			
		} else {
			if (anchor.classList.contains ("active"))
				anchor.classList.remove ("active");
			
		}
		
	}
	
	this.updateBackgroundOffset (true);
	
};

OnePagerNavigation.prototype.updateBackgroundOffset = function (animated) {
	const activeSectionId = this.activeSectionId;
	
	const activeAnchor = this.anchorMap [activeSectionId];
	
	const selfBounds = this.element.getBoundingClientRect ();
	if (activeAnchor) {
		this.setBackgroundOffset (
			selfBounds.width - activeAnchor.getBoundingClientRect ().right + 19,
			animated
			
		);
		
	} else {
		const title = this.title;
		const titleBounds = title.getBoundingClientRect ();
		
		if (titleBounds.width) {
			this.setBackgroundOffset (
				selfBounds.width - titleBounds.right + 19,
				animated
				
			);

		} else {
			this.setBackgroundOffset (
				selfBounds.width + 100,
				animated
				
			);
			
		}
		
	}
	
};

OnePagerNavigation.prototype.backgroundOffset = 0;

OnePagerNavigation.prototype.setBackgroundOffset = function (targetBackgroundOffset, animated) {
	this.targetBackgroundOffset = targetBackgroundOffset;
	
	if (animated) {
		if (this.backgroundOffset != targetBackgroundOffset) {
			this.addRunLoopHandler ("processBackgroundOffset");
			
		}
		
	} else {
		this.backgroundOffset = targetBackgroundOffset;
		this.removeRunLoopHandler ("processBackgroundOffset");
		
		const background = this.background;
		background.style.right = targetBackgroundOffset + "px";
		
	}
	
};

OnePagerNavigation.prototype.processBackgroundOffset = function () {
	let backgroundOffset = this.backgroundOffset;
	const targetBackgroundOffset = this.targetBackgroundOffset;
	
	const delta = targetBackgroundOffset - backgroundOffset;
	
	if (Math.abs (delta) > .5) {
		backgroundOffset = (backgroundOffset * 7 + targetBackgroundOffset) / 8;
		
	} else {
		backgroundOffset = targetBackgroundOffset;
		
		this.removeRunLoopHandler ("processBackgroundOffset");
		
	}
	this.backgroundOffset = backgroundOffset;
	
	const background = this.background;
	background.style.right = backgroundOffset + "px";
	
};

