import * as Environment from "../base/Environment.js";

import {RenderContext} from "../base/Display.js";
import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {SiteSection} from "./SiteSection.js";
import {Navigation} from "./Navigation.js";

//
// Tubag100Header extends SiteSection
//

export const Tubag100Header = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.Tubag100Header = Tubag100Header;

Tubag100Header.prototype = Object.create (SiteSection.prototype);

Tubag100Header.prototype.renderInContext = function () {};

Tubag100Header.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const background = this.background = element.querySelector (".tubag-100-header-background");
	
	const transparentCover = this.transparentCover = background.querySelector (".tubag-100-header-background-transparent-cover");
	const opaqueCover = this.opaqueCover = background.querySelector (".tubag-100-header-background-opaque-cover");
	
	window.setTimeout (function () {
		if (!this.didStartLoading)
			this.startLoading ();
		
	}.bind (this), 2500);
	
};

Tubag100Header.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
};

Tubag100Header.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	this.stopAnimation ("SlideMask");
	this.maskT = 0;
	this.updateMaskLayout ();
	this.didSlideMask = false;
	
};

Tubag100Header.prototype.startLoading = function () {
	SiteSection.prototype.startLoading.apply (this, arguments);
	
	const background = this.background;
	let backgroundImageURL = window.getComputedStyle (background).backgroundImage;
	backgroundImageURL = backgroundImageURL.substring (5, backgroundImageURL.length - 2);
	
	const image = new Image ();
	image.addEventListener ("load", function (event) {
		this.didLoadImage = true;
		
	}.bind (this));
	
	image.src = backgroundImageURL;
	
};

Tubag100Header.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	this.updateMaskLayout ();
	
};

Tubag100Header.prototype.scroll = function (fromResizing) {
	SiteSection.prototype.scroll.apply (this, arguments);
	
	if (!this.didSlideMask) {
		const pageBounds = this.pageBounds;
		const viewportSize = this.getStage ().size;
		
		if (pageBounds.bottom > viewportSize [1] * .4)
			this.slideMask ();
		
	}
	
};

Tubag100Header.prototype.slideMask = function () {
	this.didSlideMask = true;
	
	this.startAnimation ("SlideMask", {direction: 1, rate: .0075, phase: 0, delay: 20});
	
};

Tubag100Header.prototype.animateSlideMask = function () {
	let state = this.states ["SlideMask"];
	const delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	if (!this.didLoadImage)
		return;
	
	state = this.updatedState ("SlideMask");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	
	this.maskT = t;
	this.updateMaskLayout ();
	
};

Tubag100Header.prototype.maskT = 0;

Tubag100Header.prototype.updateMaskLayout = function () {
	const t = this.maskT;
	const viewportSize = this.getStage ().size;
	
	const transparentCover = this.transparentCover;
	const opaqueCover = this.opaqueCover;
	
	
	const fadingElements = [
		opaqueCover,
		transparentCover
		
	];
	
	const fadeRange = .85;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	let t_;
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 0 * distributionRange) / fadeRange));
	t_ = 1 - t_ * t_ * t_
	opaqueCover.style.left = viewportSize [0] * (t_ - .2) + "px";
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 1 * distributionRange) / fadeRange));
	t_ = 1 - t_ * t_ * t_
	transparentCover.style.left = viewportSize [0] * (t_ - .4) + "px";
	
};

//
// Tubag100WallItem extends SiteSection
//

export const Tubag100WallItem = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.Tubag100WallItem = Tubag100WallItem;

Tubag100WallItem.prototype = Object.create (SiteSection.prototype);

Tubag100WallItem.prototype.renderInContext = function () {};

Tubag100WallItem.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const infoText = this.infoText = element.querySelector (".tubag-100-wall-item-info");
	const transparentCover = this.transparentCover = element.querySelector (".tubag-100-wall-item-background");
	
	const image = this.image = element.querySelector ("img");
	
	const hoverImage = this.hoverImage = image.cloneNode (true);
	hoverImage.setAttribute ("src", image.getAttribute ("src").replace (".jpg", "-hover.jpg"));
	
	image.parentNode.insertBefore (hoverImage, image.nextSibling);
	
	this.addListener ("mouseover", this.mouseOver, this);
	this.addListener ("mouseout", this.mouseOut, this);
	
	/*
	window.setTimeout (function () {
		this.mouseOver ();
		
	}.bind (this), 500);
	*/
	
};

Tubag100WallItem.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
};

Tubag100WallItem.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
};

Tubag100WallItem.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const element = this.element;
	viewSize = this.viewSize = [
		element.offsetWidth,
		element.offsetHeight
		
	];
	
	this.updateMaskLayout ();
	
};

Tubag100WallItem.prototype.mouseOver = function (sender) {
	this.isMouseOver = true;
	this.startAnimation ("SlideMask", {direction: 1, rate: .015});
	
};

Tubag100WallItem.prototype.mouseOut = function (sender) {
	this.isMouseOver = false;
	window.setTimeout (function () {
		if (!this.isMouseOver)
			this.startAnimation ("SlideMask", {direction: 0, rate: .015});
		
	}.bind (this), 0);
	
};

Tubag100WallItem.prototype.slideMask = function () {
	this.startAnimation ("SlideMask", {direction: 1, rate: .0075, phase: 0, delay: 20});
	
};

Tubag100WallItem.prototype.animateSlideMask = function () {
	const state = this.updatedState ("SlideMask");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	
	this.maskT = t;
	this.updateMaskLayout ();
	
};

Tubag100WallItem.prototype.maskT = 0;

Tubag100WallItem.prototype.updateMaskLayout = function () {
	const t = this.maskT;
	const viewSize = this.viewSize;
	
	const transparentCover = this.transparentCover;
	const infoText = this.infoText;
	
	const fadingElements = [
		transparentCover,
		infoText
		
	];
	
	const fadeRange = .85;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	let t_;
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 1 * distributionRange) / fadeRange));
	t_ = t_ * t_ * t_
	infoText.style.display = t_ < 1 ? "block" : "none";
	infoText.style.transform = "translate3D(" + (-viewSize [0] * t_) + "px, 0, 0)";
	
	const isPortrait = viewSize [0] < viewSize [1] * 1.25;
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 0 * distributionRange) / fadeRange));
	t_ = t_ * t_ * t_
	transparentCover.style.display = t_ < 1 ? "block" : "none";
	transparentCover.style.right = viewSize [0] * (t_ + (isPortrait ? -.1 : .05)) + "px";
	
	/*
	const image = this.image;
	image.style.filter = "saturate(" + (1 - t) + ") sepia(" + t * .25 + ")";
	*/
	
	const hoverImage = this.hoverImage;
	hoverImage.style.opacity = t;
	
};

//
// Tubag100Timeline extends SiteSection, SlidingSpritePrototype
//

export const Tubag100Timeline = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.Tubag100Timeline = Tubag100Timeline;

Tubag100Timeline.prototype = Object.create (SiteSection.prototype);
Tubag100Timeline.extendPrototype (SlidingSpritePrototype);

Tubag100Timeline.prototype.renderInContext = function () {};

Tubag100Timeline.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	this.isMEP = element.className.indexOf ("-mep-") >= 0;
	
	const contentContainer = this.contentContainer = element.querySelector (".tubag-100-timeline-content, .timeline-content");
	const content = this.content = contentContainer.firstElementChild;
	
	const columns = this.columns = content.querySelectorAll (".row:first-child > .column");
	const separator = this.separator = content.querySelector (".tubag-100-timeline-row-separator, .timeline-row-separator");
	
	for (let i = columns.length; i--;) {
		if (i % 2)
			continue;
		
		const column = columns [i];
		column.style.position = "relative";
		
		const separator = column.separator = document.createElement ("div");
		separator.classList.add ("tubag-100-timeline-column-separator");
		separator.classList.add ("column-separator");
		
		column.appendChild (separator);
		
	}
	
	this.setUpScrolling ();
	
};

Tubag100Timeline.prototype.setUpScrolling = function () {
	SlidingSpritePrototype.call (this, this.context);
	
	var scrollingController = this.scrollingController;
	
	scrollingController.disableMouseWheel = false;
	
	scrollingController.awake ();
	scrollingController.addWheelListener ();
	scrollingController.cancelHorizontalScroll = true;
	
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
	scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
	
	const element = this.element;
	const navigation = this.navigation = element.querySelector (".tubag-100-timeline-navigation, .timeline-navigation");
	const navigationButtons = this.navigationButtons = navigation.querySelectorAll (".tubag-100-timeline-navigation-button, .timeline-navigation-button");
	
	var arrowDownHandler = function (event) {
		event.stopPropagation ();
		event.preventDefault ();
		
		var navigationButton = event.currentTarget;
		var direction = navigationButton.direction;
		
		this.beginHoldArrowInDirection (direction);
		
	}.bind (this);
	
	for (var i = navigationButtons.length; i--;) {
		var navigationButton = navigationButtons [i];
		navigationButton.direction = (i - .5) * 2;
		
		navigationButton.addEventListener ("touchstart", arrowDownHandler);
		navigationButton.addEventListener ("mousedown", arrowDownHandler);
		
	}
	
};

Tubag100Timeline.prototype.getListenerTargetForScrollingController = function () {
	return this.contentContainer;
	
};

Tubag100Timeline.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
};

Tubag100Timeline.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
};

Tubag100Timeline.prototype.scroll = function () {
	const navigation = this.navigation;
	if (navigation.style.top)
		navigation.style.top = "";
	
	const viewSize = this.getStage ().size;
	if (viewSize [1] <= 800) {
		const navigationBounds = navigation.getBoundingClientRect ();
		
		const mainNavigation = Navigation.sharedInstance;
		const topBound = 260 + (
			this.isMEP ? -30 : 0
			
		) + mainNavigation.position [1];
		
		if (navigationBounds.top < topBound)
			navigation.style.top = (topBound - navigationBounds.top) + "px";
		
	}
	
};

Tubag100Timeline.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	const element = this.element;
	const contentContainer = this.contentContainer;
	const content = this.content;
	
	element.classList.remove ("scrollable");
	
	const contentContainerSize = [
		contentContainer.offsetWidth,
		contentContainer.offsetHeight
		
	];
	
	const columns = this.columns;
	
	let contentSize = [
		columns [columns.length - 1].getBoundingClientRect ().right -
			columns [0].getBoundingClientRect ().left,
		content.offsetHeight
		
	];
	
	const scrollingController = this.scrollingController;
	
	if (contentSize [0] > contentContainerSize [0]) {
		element.classList.add ("scrollable");
		/*
		contentSize = [
			contentSize.offsetWidth,
			contentSize.offsetHeight
			
		];
		*/
		scrollingController.awake ();
		
	} else {
		element.classList.remove ("scrollable");
		scrollingController.sleep ();
		
	}
	
	viewSize = this.viewSize = [
		contentContainer.offsetWidth,
		contentContainer.offsetHeight
		
	];
	
	const separator = this.separator;
	separator.style.width = "calc(" + contentSize [0] + "px + 100vw)";
	
	contentContainer.style.height = contentSize [1] + "px";
	
	this.maxScrollOffset = contentSize [0] - viewSize [0];
	
	this.viewOffset = this.currentViewOffset = this.boundedViewOffset (this.viewOffset);
	this.updateViewOffset ();
	
	this.updateItemsLayout ();
	
};

Tubag100Timeline.prototype.updateItemsLayout = function () {
	
};

Tubag100Timeline.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
};

Tubag100Timeline.prototype.getMaxScrollOffset = function () {
	return this.maxScrollOffset;
	
};

Tubag100Timeline.prototype.boundedViewOffset = function (viewOffset) {
	return Math.max (0, Math.min (this.getMaxScrollOffset (), viewOffset));
	
};

Tubag100Timeline.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	
};

Tubag100Timeline.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.4;
		
		this.slidingInertia = .9;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.snapToBounds ();
		this.completeSliding ();
		
	}
	
};

Tubag100Timeline.prototype.scrollHorizontal = function (scrollingController) {
	if (scrollingController.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	var delta = scrollingController.wheelDelta.x;
	var targetViewOffset = this.viewOffset + delta * 1.5;
	
	if (!this.isBumping) {
		if (!(this.bumpLockDirection * delta > 0)) {
			this.bumpLockDirection = 0;
			
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .7;
			
			this.addRunLoopHandler ("processSliding");
			
			this.updateViewOffset ();
			
		}
		
	}
	
};

Tubag100Timeline.prototype.cancelScrollHorizontal = function (scrollingController) {
	
};

Tubag100Timeline.prototype.beginHoldArrowInDirection = function (direction) {
	this.getStage ().addListener ("mouseup", this.endHoldArrow, this);
	
	this.holdArrowSpeed = 2;
	this.holdArrowDirection = direction;
	
	this.addRunLoopHandler ("processHoldArrow");
	this.processHoldArrow ();
	
};

Tubag100Timeline.prototype.processHoldArrow = function () {
	var framesDelta = this.context.animationTimer.framesDelta;
	
	var direction = this.holdArrowDirection;
	var scrollSpeed = this.holdArrowSpeed = Math.min (20, this.holdArrowSpeed + 1 * framesDelta);
	
	var viewOffset = this.viewOffset + scrollSpeed * framesDelta * direction;
	
	if (viewOffset == this.boundedViewOffset (viewOffset)) {
		this.viewOffset = viewOffset;
		
		this.slidingInertia = .8;
		this.addRunLoopHandler ("processSliding");
		
	} else {
		this.viewOffset = (viewOffset + this.boundedViewOffset (viewOffset)) * .5 + scrollSpeed * 6 * this.context.animationTimer.framesDelta * direction;
		
		this.slidingInertia = .95;
		this.addRunLoopHandler ("processSliding");
		
		this.endHoldArrow ();
		
	}
	
};

Tubag100Timeline.prototype.endHoldArrow = function (stage) {
	this.getStage ().removeListener ("mouseup", this.endHoldArrow, this);
	
	this.removeRunLoopHandler ("processHoldArrow");
	
};

Tubag100Timeline.prototype.completeSliding = function () {

};

Tubag100Timeline.prototype.updateViewOffset = function () {
	let viewOffset = this.currentViewOffset;
	viewOffset = Math.round ((this.boundedViewOffset (viewOffset) * 3 + viewOffset * 1) * .25);
	
	const content = this.content;
	content.style.transform = "translateX(" + -viewOffset + "px)";
	
	function showButton (button, doShow) {
		button.style.opacity = doShow ? "" : ".33";
		if (doShow)
			button.classList.remove ("shape");
		else
			button.classList.add ("shape");
		
	}
	
	viewOffset = this.viewOffset;
	
	const navigationButtons = this.navigationButtons;
	showButton (navigationButtons [0], viewOffset > 0);
	showButton (navigationButtons [1], viewOffset <= this.maxScrollOffset - 1);
	
};

//
// Tubag100Gallery extends SiteSection, SlidingSpritePrototype
//

export const Tubag100Gallery = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.Tubag100Gallery = Tubag100Gallery;

Tubag100Gallery.prototype = Object.create (SiteSection.prototype);
Tubag100Gallery.extendPrototype (SlidingSpritePrototype);

Tubag100Gallery.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	element.style.height = "auto";
	element.style.visibility = "visible";
	
	element.classList.add ("unselectable");
	
	SlidingSpritePrototype.apply (this, arguments);
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	
	element.removeChild (contentContainer.element);
	contentContainer.element = element.querySelector (".gallery__slide-container");
	
	this.parseChildren ();
	
	this.finishSetup ();
	
};

Tubag100Gallery.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

Tubag100Gallery.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	this.isAutoRotationLocked = false;
	
};

Tubag100Gallery.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	this.isAutoRotationLocked = true;
	
	const slides = this.slides;
	for (let i = slides.length; i--;) {
		const slide = slides [i];
		if (slide.didSlideMask)
			slide.resetMask ();
		
	}
	
};

Tubag100Gallery.prototype.scroll = function () {
	const pageBounds = this.pageBounds;
	const viewportSize = this.getStage ().size;
	
	const shouldAnimateSlides = this.shouldAnimateSlides =
		pageBounds.top < viewportSize [1] * .9 &&
		pageBounds.bottom > viewportSize [1] * .1;
	
	if (shouldAnimateSlides) {
		const slides = this.slides;
		for (let i = slides.length; i--;) {
			const slide = slides [i];
			if (slide.isAwake && !slide.didSlideMask)
				slide.slideMask ();
			
		}
		
	}
	
};

Tubag100Gallery.prototype.parseChildren = function () {
	var element = this.element;
	var context = this.context;
	
	var slides = this.slides = new Array ();
	
	var contentContainer = this.contentContainer;
	var children = element.querySelectorAll (".tubag-100-gallery-slide");
	
	var numSlides = this.numSlides = children.length;
	
	var firstChildParent = children [0];
	
	firstChildParent = firstChildParent && firstChildParent.slide;
	firstChildParent = firstChildParent && firstChildParent.element.parentNode;
	
	for (var i = 0; i < numSlides; i++) {
		const child = children [i];
		
		const slide = child.slide = new GallerySlide (context);
		slide.parentGallery = this;
		slide.index = i;
		
		slide.takeElement (child);
		slide.setAlpha (0);
		
		var slideParentNode = firstChildParent || child.parentNode;
		contentContainer.addChild (slide);
		slideParentNode.appendChild (slide.element);
		
		slides.push (slide);
		
	}
	
};

Tubag100Gallery.prototype.finishSetup = function (viewSize) {
	SlidingSpritePrototype.call (this, this.context);
	
	var element = this.element;
	
	var navigationElement = this.navigationElement = element.querySelector (".tubag-100-gallery-navigation");
	
	const numSlides = this.numSlides;
	
	if (numSlides > 1) {
		var scrollingController = this.scrollingController;
		
		scrollingController.disableMouseWheel = false;
		
		scrollingController.stage = this.getStage ();
		scrollingController.getMouseListenerTarget = function (eventType) {
			return element;
			
		};
		
		scrollingController.awake ();
		scrollingController.addWheelListener ();
		scrollingController.cancelHorizontalScroll = true;
		
		scrollingController.addListener ("beginDrag", this.beginDrag, this);
		scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
		scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
		scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
		
		scrollingController.addListener ("scrollHorizontal", this.scrollHorizontal, this);
		scrollingController.addListener ("cancelScrollHorizontal", this.cancelScrollHorizontal, this);
		
		this.addListener ("completeSliding", this.completeSliding, this);
		this.addListener ("advance", this.advance, this);
		
		if (navigationElement) {
			var arrowButtons = element.querySelectorAll (".tubag-100-gallery-navigation-button");
			arrowButtons [0].addEventListener ("click", function () {
				this.advanceInDirection (-1, true);
				this.markActivity ();
				
			}.bind (this));
			arrowButtons [0].addEventListener ("touchstart", function () {
				this.advanceInDirection (-1, true);
				this.markActivity ();
				
			}.bind (this));
			arrowButtons [1].addEventListener ("click", function () {
				this.advanceInDirection (1, true);
				this.markActivity ();
				
			}.bind (this));
			arrowButtons [1].addEventListener ("touchstart", function () {
				this.advanceInDirection (1, true);
				this.markActivity ();
				
			}.bind (this));
			
			function stopPropagation (event) {
				event.stopPropagation ();
				event.preventDefault ();
				
			}
			
			for (var i = arrowButtons.length; i--;) {
				var arrowButton = arrowButtons [i];
				arrowButton.addEventListener ("mousedown", stopPropagation);
				arrowButton.addEventListener ("touchstart", stopPropagation);
				
			}
			
		}
		
		this.isAutoRotationLocked = true;
		
		var autoRotationFrequency = this.autoRotationFrequency = parseFloat (element.getAttribute ("data-auto-rotation-frequency")) * 60;
		var shouldAutoRotate = this.shouldAutoRotate = !!autoRotationFrequency;
		if (shouldAutoRotate)
			this.startAutoRotation ();
		
	} else {
		navigationElement.style.display = "none";
		
	}
	
};

Tubag100Gallery.prototype.startAutoRotation = function () {
	// if (FAST_PASS)
	//	return;
	
	this.addListener ("mouseover", this.mouseOverOnAutoRotation, this);
	this.addListener ("mouseout", this.mouseOutOnAutoRotation, this);

	this.autoRotationDelay = this.autoRotationFrequency;
	this.addRunLoopHandler ("processAutoRotation");
	
};

Tubag100Gallery.prototype.stopAutoRotation = function () {
	this.removeRunLoopHandler ("processAutoRotation");
	
};

Tubag100Gallery.prototype.mouseOverOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = true;
	
};

Tubag100Gallery.prototype.mouseOutOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = false;
	this.autoRotationDelay = Math.max (this.autoRotationFrequency, 200);
	
};

Tubag100Gallery.prototype.processAutoRotation = function (sender) {
	if (this.isAutoRotationLocked)
		return;
	
	var autoRotationDelay = this.autoRotationDelay = Math.max (
		0, this.autoRotationDelay - this.context.animationTimer.framesDelta
		
	);
	
	if (autoRotationDelay)
		return;
	
	this.autoRotationDelay = this.autoRotationFrequency;
	this.skipTracking = true;
	this.advanceInDirection (1, {
		maxSpeed: 50,
		acceleration: 4.25
		
	});
	
};

Tubag100Gallery.prototype.setViewSize = function (viewSize) {
	this.updateLayout ();
	
};

Tubag100Gallery.prototype.updateLayout = function (noResize) {
	var slides = this.slides;
	var slide = slides [0];
	if (!slide)
		return;
	
	this.spacing = Site.sharedInstance.gutterWidth;
	
	slide.element.style.display = "";
	
	var containerElement = this.contentContainer.element;
	var containerWidth = Math.round (containerElement.offsetWidth);
	
	const viewSize = [
		containerWidth,
		Math.round (Math.round (containerElement.offsetHeight))
		
	];
	
	this._setViewSize (viewSize, noResize);
	
};

Tubag100Gallery.prototype._setViewSize = function (viewSize, noResize) {
	var stageSize = this.getStage ().size;
	var overscan = this.overscan = Math.ceil (
		(stageSize [0] - viewSize [0]) / 2
		
	);

	const lastViewSize = this.viewSize;
	const lastIndex = lastViewSize && this.slideIndexForViewOffset (this.viewOffset);
	
	this.viewSize = viewSize;
	this.setSize (viewSize);
	
	if (lastViewSize && !noResize && !(this.getStage ().nextTimeToObserve > new Date ().getTime ())) {
		/*
		var percentage = Math.round (
			this.currentViewOffset / (lastViewSize [0] + this.spacing)
			
		);
		this.viewOffset = this.currentViewOffset =
			percentage * (viewSize [0] + this.spacing);
		*/
		
		this.viewOffset = this.currentViewOffset = this.viewOffsetForSlideIndex (lastIndex);
		
		this.removeRunLoopHandler ("processSliding");
		
	}
	
	this.updateViewOffset ();
	
	if (!lastViewSize) {
		this.dispatchEvent ("advance");
		this.dispatchEvent ("completeSliding");
		
	}
	
};

Tubag100Gallery.DRAG_DELTA = 150;

Tubag100Gallery.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	scrollingController.currentEvent.stopPropagation ();
	
	delete this.maxScrollSpeed;
	
};

Tubag100Gallery.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;
	
};

Tubag100Gallery.prototype.snapToBounds = function () {
	var viewOffset = this.viewOffset;
	var index = this.slideIndexForViewOffset (viewOffset, true);
	
	var targetViewOffset = this.viewOffsetForSlideIndex (index);
	
	if (viewOffset != targetViewOffset) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

Tubag100Gallery.prototype.completeSliding = function (sender) {
	const slides = this.slides;
	var index = this.slideIndexForViewOffset (this.viewOffset);
	
	index %= slides.length;
	if (index < 0)
		index += slides.length;
	
	var currentSlide = slides [index];
	if (!currentSlide.isLoading)
		currentSlide.startLoading ();
	
	if (slides.length > 1) {
		var nextItem = slides [(index + 1) % slides.length];
		if (!nextItem.isLoading)
			nextItem.startLoading ();
		
	}
	
	if (slides.length > 2) {
		var previousItem = slides [(index + slides.length - 1) % slides.length];
		if (!previousItem.isLoading)
			previousItem.startLoading ();
		
	}
	
};

Tubag100Gallery.prototype.advance = function (sender) {
	const indicators = this.indicators;
	if (indicators) {
		const currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
		
		for (let i = indicators.length; i--;) {
			const indicator = indicators [i];
			
			if (i == currentImageIndex % indicators.length)
				indicator.classList.add ("active");
			else
				indicator.classList.remove ("active");
			
		}
		
	}
	
};

Tubag100Gallery.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	var viewSize = this.viewSize;
	var dragDelta = Math.min (
		viewSize [0] / 2, Tubag100Gallery.DRAG_DELTA
		
	);
	
	if (Math.abs (deltaX) >= dragDelta && !this.scrollThrough)
		this.advanceInDirection (deltaX < 0 ? -1 : 1);
	else
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	this.markActivity ();
	
};

Tubag100Gallery.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.5;
		
		var viewSize = this.viewSize;
		var dragDelta = Math.min (
			viewSize [0] / 2, Tubag100Gallery.DRAG_DELTA
			
		);
		
		if (this.scrollThrough) {
			this.viewOffset = targetViewOffset;
			this.snapToBounds ();
			
			this.dispatchEvent ("advance");
			
		} else {
			if (Math.abs (targetViewOffset) >= dragDelta) {
				this.advanceInDirection (scrollingController.flickDeltaX < 0 ? -1 : 1);
				
			} else {
				this.snapToBounds ();
				
			}
			
		}
		
	} else {
		this.snapToBounds ();
		
	}
	
};

Tubag100Gallery.prototype.scrollHorizontal = function (scrollingController) {
	if (scrollingController.isDragging)
		return;
	
	if (this.isWheelLocked)
		return;
	
	const now = new Date ().getTime ();
	if (now < this.scrollTimeOut)
		return;
	
	var delta = scrollingController.wheelDelta.x;
	const targetViewOffset = this.viewOffset + delta;
	
	this.viewOffset = targetViewOffset;
	this.slidingInertia = .7;
	
	this.addRunLoopHandler ("processSliding");
	
	const viewSize = this.viewSize;
	
	const slides = this.slides;
	const currentIndex = this.slideIndexForViewOffset (targetViewOffset);
	
	const currentPos = currentIndex * (viewSize [0] + this.spacing);
	
	let currentDelta = targetViewOffset - currentPos;
	const fullWidth = slides.length * (viewSize [0] + this.spacing);
	
	currentDelta += fullWidth * .5;
	currentDelta %= fullWidth;
	if (currentDelta < 0)
		currentDelta += fullWidth;
	currentDelta -= fullWidth * .5;
	
	var dragDelta = Math.min (
		viewSize [0] / 2, Tubag100Gallery.DRAG_DELTA
		
	);
	
	if (Math.abs (currentDelta) >= dragDelta) {
		this.advanceInDirection (currentDelta > 0 ? 1 : -1);
		
		this.scrollTimeOut = now + 1000;
		
	} else {
		// this.updateViewOffset ();
		
	}
	
};

Tubag100Gallery.prototype.cancelScrollHorizontal = function (scrollingController) {
	if (this.scrollingController.isTouching)
		return;
	
	this.snapToBounds ();
	
};

Tubag100Gallery.prototype.advanceInDirection = function (direction, easeIn) {
	if (this.scrollingController.isTouching)
		this.releaseDrag ();
	
	var delta = Math.abs (this.currentViewOffset - this.viewOffset);
	
	var index = this.slideIndexForViewOffset (this.viewOffset, true) + direction;
	
	var viewSize = this.viewSize;
	this.viewOffset = this.viewOffsetForSlideIndex (index);
	
	this.slidingInertia = .85;
	if (easeIn && delta < 10)
		this.maxScrollSpeed = 4;
	this.accelerationDescription = undefined;
	
	this.addRunLoopHandler ("processSliding");
	
	this.dispatchEvent ("advance");
	
};

Tubag100Gallery.prototype.markActivity = function () {
	this.autoRotationDelay = this.autoRotationFrequency * 1.5;
	
};

Tubag100Gallery.prototype.spacing = 40;

Tubag100Gallery.prototype.slideIndexForViewOffset = function (viewOffset, noWrap) {
	var index = Math.round (
		viewOffset / (this.viewSize [0] + this.spacing)
		
	);
	
	if (!noWrap) {
		var slides = this.slides;
		index = index % slides.length;
		if (index < 0)
			index += slides.length;
		
	}
	
	return index;
	
};

Tubag100Gallery.prototype.setViewOffsetByIndex = function (index, dontAnimate) {
	var slides = this.slides;
	index = index % slides.length;
	if (index < 0)
		index += slides.length;
	
	var viewOffset = this.viewOffset;
	
	var currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
	var delta = (index - currentImageIndex) % slides.length;
	if (delta < 0)
		delta += slides.length;
	
	if (delta > slides.length * .5)
		delta = delta - slides.length;
	
	if (!delta)
		return;
	
	var viewSize = this.viewSize;
	var targetViewOffset = this.viewOffset;
	
	var direction = slides.length > 2 ? delta > 0 ? 1 : -1 : currentImageIndex > index ? -1 : 1;
	targetViewOffset += (this.viewSize [0] + this.spacing) * Math.abs (delta) * direction;

	if (viewOffset != targetViewOffset) {
		if (dontAnimate) {
			this.viewOffset = this.currentViewOffset = targetViewOffset;
			this.removeRunLoopHandler ("processSliding");
			this.updateViewOffset ();
			
		} else {
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .85;
			this.maxScrollSpeed = 4;
			this.accelerationDescription = undefined;
			this.addRunLoopHandler ("processSliding");
			
		}
		
	}
	
	this.dispatchEvent ("advance");

};

Tubag100Gallery.prototype.viewOffsetForSlideIndex = function (slideIndex) {
	if (this.adjustHeightToContent) {
		const slides = this.slides;
		const hardOff = Math.floor (
			slideIndex / slides.length
			
		);
		
		slideIndex = slideIndex % slides.length;
		if (slideIndex < 0)
			slideIndex += slides.length;
		
		let cursor = 0;
		
		for (var i = 0; i < slideIndex; i++) {
			const slide = slides [i];
			cursor += slide.contentSize [0];
			
		}
		
		return cursor + hardOff * this.fullWidth;
		
	} else {
		return slideIndex * (this.viewSize [0] + this.spacing);
		
	}
	
};

Tubag100Gallery.prototype.updateViewOffset = function () {
	var slides = this.slides;
	if (!slides.length)
		return;
	
	var viewSize = this.viewSize;
	var viewWidth = viewSize [0];
	var spacing = this.spacing;
	
	var viewOffset = this.currentViewOffset;
	var currentSlideIndex = this.currentSlideIndex = this.slideIndexForViewOffset (viewOffset);
	
	viewOffset = (viewOffset + (viewWidth + spacing) * .5) % (viewWidth + spacing);
	if (viewOffset < 0)
		viewOffset += (viewWidth + spacing);
	
	viewOffset = viewOffset - (viewWidth + spacing) * .5 + currentSlideIndex * (viewWidth + spacing);
	
	viewOffset = Math.round (viewOffset);
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		slide.isUsed = false;
		
	}
	
	var overscan = 0; // this.overscan;
	
	var slide = slides [currentSlideIndex];
	
	var i = currentSlideIndex;
	var cursor = -Math.round (viewSize [0] * .5) - viewOffset + (currentSlideIndex * (viewWidth + spacing));
	
	while (cursor > -viewWidth * .5 + spacing - overscan) {
		i = (i - 1) % slides.length;
		if (i < 0)
			i += slides.length;
		
		var slide = slides [i];
		// slide.setViewSize (viewSize);
		
		cursor -= (slide.contentSize || viewSize) [0] + spacing;
		
	}
	
	var viewOffset = Math.round (viewWidth * .5);
	
	var contentContainer = this.contentContainer;
	
	if (this.numSlides == 1)
		cursor = -viewOffset;
	
	var percentageSum = 0;
	
	while (cursor < Math.floor (viewWidth * .5) + overscan) {
		var slide = slides [i];
		if (slide.isUsed)
			break;
		slide.isUsed = true;
		
		slide.setViewSize (viewSize);
		slide.setPosition (
			cursor + viewOffset,
			0
			
		);
		
		if (!slide.isLoading)
			slide.startLoading ();
		
		slide.loadingPriority = Math.abs (cursor + viewOffset);
		
		if (!slide.isAwake) {
			slide.setAlpha (1);
			slide.awake ();
			
		}
		
		var percentage = Math.max (0, Math.min (1, (1 - Math.abs (
			slide.position [0] / viewSize [0]
			
		))));
		
		slide.setAlpha (1); // percentage * .67 + .33);
		
		if (slide.setPercentage) {
			slide.setPercentage (percentage);
			
		}
		
		i = (i + 1) % slides.length;
		if (i < 0)
			i += slides.length;
		
		cursor += (slide.contentSize || viewSize) [0] + spacing;
		
	}
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		if (!slide.isUsed) {
			slide.setAlpha (0);
			slide.element.style.display = "none";
			
			if (slide.isAwake)
				slide.sleep ();
			
		}
		
	}
		
	this.dispatchEvent ("updateViewOffset");
	
	this.renderInContext (this.context);
	
};

//
// GallerySlide extends AnimatableSprite
//

const GallerySlide = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.renderUsingCSSTransform = true;
	
};

GallerySlide.prototype = Object.create (AnimatableSprite.prototype);

GallerySlide.prototype.takeElement = function (element) {
	this.element = element;
	
	element.classList.add ("unselectable");
	
	element.addEventListener ("click", function (event) {
		if (this.parent.parent.scrollingController.didDrag) {
			event.preventDefault ();
			return;
			
		}
		
	}.bind (this));
	
	const image = this.image = element.querySelector ("img");
	if (image) {
		image.setAttribute ("draggable", "false");
		image.style.userSelect = "none";
		image.style.webkitTouchCallout = "none";
		
	}
	
	const transparentCover = this.transparentCover = element.querySelector (".tubag-100-gallery-slide-background-transparent-cover");
	const opaqueCover = this.opaqueCover = element.querySelector (".tubag-100-gallery-slide-background-opaque-cover");
	
};

GallerySlide.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	this.updateMaskLayout ();
	
};

GallerySlide.prototype.markLoadingPriority = function (loadingPriority) {
	this.loadingPriority = loadingPriority;
	
};

GallerySlide.prototype.startLoading = function () {
	this.isLoading = true;
	
	var image = this.image;
	if (!image)
		return;
	
	image.addEventListener ("load", function (event) {
		this.didLoadImage = true;
		
	}.bind (this));
	
	const backgroundImageURL = image.getAttribute ("data-src");
	image.src = backgroundImageURL;
	
};

GallerySlide.prototype.awake = function () {
	this.isAwake = true;
	
	if (!this.didSlideMask && this.parentGallery.shouldAnimateSlides)
		this.slideMask ();
	
};

GallerySlide.prototype.sleep = function () {
	this.isAwake = false;
	
	this.resetMask ();
	
};

GallerySlide.prototype.resetMask = function () {
	if (this.didSlideMask) {
		this.stopAnimation ("SlideMask");
		this.maskT = 0;
		this.updateMaskLayout ();
		this.didSlideMask = false;
		
	}
	
};

GallerySlide.prototype.slideMask = function () {
	this.didSlideMask = true;
	
	this.startAnimation ("SlideMask", {direction: 1, rate: .0075, phase: 0, delay: 20});
	
};

GallerySlide.prototype.animateSlideMask = function () {
	let state = this.states ["SlideMask"];
	const delay = state.delay = Math.max (0, state.delay - this.context.animationTimer.framesDelta);
	if (delay)
		return;
	
	if (!this.didLoadImage)
		return;
	
	state = this.updatedState ("SlideMask");
	let t = 1 - state.phase;
	t = 1 - t * t * t;
	
	this.maskT = t;
	this.updateMaskLayout ();
	
};

GallerySlide.prototype.maskT = 0;

GallerySlide.prototype.updateMaskLayout = function () {
	const t = this.maskT;
	const viewSize = this.viewSize;
	
	const transparentCover = this.transparentCover;
	const opaqueCover = this.opaqueCover;
	
	
	const fadingElements = [
		opaqueCover,
		transparentCover
		
	];
	
	const fadeRange = .85;
	const distributionRange = (1 - fadeRange) / (fadingElements.length - 1);
	
	let t_;
	
	const isPortrait = viewSize [0] < viewSize [1] * 1.1;
	const isSmall = viewSize [0] < 520;
	const coverOff = isPortrait ? .575 : .475;
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 0 * distributionRange) / fadeRange));
	t_ = 1 - t_ * t_ * t_
	opaqueCover.style.right = viewSize [0] * (t_ - coverOff - (isSmall ? .1 : 0)) + "px";
	
	t_ = 1 - Math.max (0, Math.min (1, (t - 1 * distributionRange) / fadeRange));
	t_ = 1 - t_ * t_ * t_
	transparentCover.style.right = viewSize [0] * (t_ - coverOff - (isSmall ? .3 : .2)) + "px";
	
};

//
// MEPSlideShow extends SiteSection
//

export const MEPSlideShow = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.MEPSlideShow = MEPSlideShow;

MEPSlideShow.prototype = Object.create (SiteSection.prototype);

MEPSlideShow.prototype.renderInContext = function () {};

MEPSlideShow.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const container = this.container = element.querySelector (".slide-show-container");
	const images = this.images = container.querySelectorAll ("img");
	
};

MEPSlideShow.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	this.addRunLoopHandler ("processSlideShow");
	
};

MEPSlideShow.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	this.removeRunLoopHandler ("processSlideShow");
	
};

MEPSlideShow.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	var viewSize = this.getStage ().size;
	
	const isMobile = viewSize [0] < 560;
	const isTablet = !isMobile && viewSize [0] < 1024;
	const isDesktop = !isMobile && !isTablet;
	
	const maxWidth = 680;
	
	const expandToLeft =
		isMobile && !isTablet;
	const expandToRight =
		// isMobile && !isTablet ||
		isDesktop;
	
	const container = this.container;
	container.style.marginRight = "";
	
	const originalBounds = container.getBoundingClientRect ();
	
	if (expandToRight) {
		container.style.marginRight = Math.max (
			originalBounds.right - viewSize [0] - 1,
			originalBounds.width - maxWidth
			
		) + "px";
		
	}
	
};

MEPSlideShow.prototype.ticks = 0;

MEPSlideShow.prototype.processSlideShow = function () {
	const ticks = this.ticks = this.ticks + this.context.animationTimer.framesDelta;
	
	const rate = 6;

	let t = (ticks / 60 / rate) % 1;
	if (t == this.lastT)
		return;
	
	this.lastT = t;
	
	const images = this.images;
	
	const currentIndex = Math.floor (ticks / 60 / rate) % images.length;
	const nextIndex = (currentIndex + 1) % images.length;
	
	for (let i = images.length; i--;) {
		if (i == currentIndex || i == nextIndex)
			continue;
		
		images [1].style.display = "none";
		
	}
	
	const currentImage = images [currentIndex];
	const nextImage = images [nextIndex];
	
	t = Math.max (0, Math.min (1, (t - .75) * 4));
	t = 1 - t;
	t = 1 - t * t * t;
	t = .5 - Math.cos (t * Math.PI) * .5;
	
	currentImage.style.transform = "";
	nextImage.style.transform = "translateX(" + (1 - t) * 100 + "%)";
	
	currentImage.style.position = "relative";
	currentImage.style.zIndex = 0;
	currentImage.style.display = "block";
	nextImage.style.position = "absolute";
	nextImage.style.zIndex = 1;
	nextImage.style.display = "block";
	
};

