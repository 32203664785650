import * as Environment from "../base/Environment.js";

// creates a global "addwheelListener" method
// example: addWheelListener( elem, function( e ) { console.log( e.deltaY ); e.preventDefault(); } );

(function (window, document) {
	
	var onwheel, support;
	
	// detect available wheel event
	support = "onwheel" in document.createElement ("div") ? "wheel" : // Modern browsers support "wheel"
		document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
		"DOMMouseScroll"; // let's assume that remaining browsers are older Firefox
	
	window.addWheelListener = function (elem, callback, useCapture) {
		if (support == "DOMMouseScroll") // handle MozMousePixelScroll in older Firefox
			_addWheelListener (elem, "MozMousePixelScroll", callback, useCapture);
		else
			_addWheelListener (elem, support, callback, useCapture);
		
	};
	
	window.removeWheelListener = function (elem, callback, useCapture) {
		if (support == "DOMMouseScroll")
			elem.removeEventListener ("MozMousePixelScroll", callback.listenerFunction, useCapture || false);
		else
			elem.removeEventListener (support, callback.listenerFunction || callback, useCapture || false);
		
		delete callback.listenerFunction;
		
	};
	
	function _addWheelListener (elem, eventName, callback, useCapture) {
		augmentDOMEventDispatcher (elem);
		
		elem.addEventListener (eventName, false && support == "wheel" ? callback : callback.listenerFunction = function (originalEvent) {
			!originalEvent && (originalEvent = window.event);
 			
			// create a normalized event object
			var event = {
				// keep a ref to the original event object
				originalEvent: originalEvent,
				target: originalEvent.target || originalEvent.srcElement,
				type: "wheel",
				deltaMode: originalEvent.type == "MozMousePixelScroll" ? 0 : 1,
				deltaX: 0,
				deltaZ: 0,
				preventDefault: function () {
					originalEvent.preventDefault ?
						originalEvent.preventDefault () :
						originalEvent.returnValue = false;
					
				}
				
			};
			
			// originalEvent.stopPropagation ();
			// event.preventDefault ();
			
			// calculate deltaY (and deltaX) according to the event
			if (support == "mousewheel") {
				event.deltaY = -1 / 40 * (isNaN (originalEvent.wheelDeltaY) ? 0 : originalEvent.wheelDeltaY);
				// Webkit also support wheelDeltaX
				originalEvent.wheelDeltaX && (event.deltaX = -1 / 40 * originalEvent.wheelDeltaX);
				
			} else if (originalEvent.deltaY == undefined) {
				event.deltaY = originalEvent.detail;
				
			} else {
				event.deltaX = originalEvent.deltaX;
				event.deltaY = originalEvent.deltaY;
				
			}
			
			if (Environment.IS_IE) {
				if (!(event.deltaX || event.deltaY || event.deltaZ))
					event.deltaY = -originalEvent.wheelDelta / 40;
				
			}
			
			if (true || !IS_SAFARI) {
				var clipDelta = 2; // 1.25;
				var clipFactor = 2;
				event.deltaX = Math.max (-clipDelta, Math.min (clipDelta, event.deltaX)) / clipFactor;
				event.deltaY = Math.max (-clipDelta, Math.min (clipDelta, event.deltaY)) / clipFactor;
				// trace ("deltaX", event.deltaX, "deltaY", event.deltaY);
				
			}
			
			// it's time to fire the callback
			return callback (event);
			
		}, useCapture || false);
		
	}
	
}) (window, document);
