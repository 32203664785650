import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// Accordion extends SiteSection
//

export const Accordion = function (context) {
	SiteSection.apply (this, arguments);

};

window.Accordion = Accordion;

Accordion.prototype = Object.create (SiteSection.prototype);

Accordion.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);

	const itemElements = element.querySelectorAll (".accordion-item");
	const items = this.items = new Array ();

	for (let i = 0; i < itemElements.length; i++) {
		const itemElement = itemElements [i];
		const item = this.attachSprite (AccordionItem);
		
		itemElement.accordionItem = item;
		item.takeElement (itemElement);
		items.push (item);

	}

	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			items [0].toggleExpansion ();

		}.bind (this), 250);
		*/

	}

};

Accordion.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);

};

Accordion.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);

};

Accordion.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);

};

//
// AccordionItem extends AnimatableSprite
//

export const AccordionItem = function (context) {
	AnimatableSprite.apply (this, arguments);

};

AccordionItem.prototype = Object.create (AnimatableSprite.prototype);

AccordionItem.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	const isExpanded = this.isExpanded = element.classList.contains ("open");
	this.states ["Expansion"] = {phase: isExpanded ? 1 : 0};

	const head = this.head = element.querySelector (".accordion-item-head");
	const body = this.body = element.querySelector (".accordion-item-body");
	
	if (head) {
		head.addEventListener ("click", function (event) {
			this.toggleExpansion ();
			
		}.bind (this));
		
	}
	
	if (body) {
		window.setTimeout (function () {
			this.useClipping = body.style.overflow == "visible";
			
		}.bind (this), 50);
		
	}
	
};

AccordionItem.prototype.toggleExpansion = function () {
    if (!this.body) return

	const isExpanded = this.isExpanded = !this.isExpanded;
	const element = this.element;
	
	if (isExpanded)
		element.classList.add ("open");
	else
		element.classList.remove ("open");

	const body = this.body;
	body.style.height = "";
	body.style.display = "block";
	
	if (isExpanded)
		this.dispatchEvent ("expand");
	
	this.expandedHeight = body.offsetHeight;

	this.startAnimation ("Expansion", {direction: isExpanded ? 1 : 0, rate: .04});
	
};

AccordionItem.prototype.animateExpansion = function () {
	const state = this.updatedState ("Expansion");
	let t = state.phase;
	t = .5 - Math.cos (t * Math.PI) * .5;

	const body = this.body;
	
	if (t == 1 || t == 0) {
		body.style.height = "";
		body.style.display = "";
		body.style.overflow = "visible";
		
	} else {
		const expandedHeight = this.expandedHeight;
		
		body.style.height = Math.round (expandedHeight * t) + "px";
		body.style.display = "block";
		
		if (this.useClipping) {
			body.style.clipPath = "inset(0 -100vw)";
			
		} else {
			body.style.overflow = "hidden";
			
		}
		
	}

};
