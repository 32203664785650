import * as Environment from "./base/Environment.js";

import {Stage} from "./base/Display2D.js";
import {Site} from "./site/Site.js";
import {Navigation} from "./site/Navigation.js";

//
// setup
//

if (Environment.IS_TOUCH_DEVICE)
	document.documentElement.style.overflowX = "hidden";

const navigationElement = document.querySelector ("header.page-header");
if (navigationElement) {
	const navigationController = Stage.fromElement.call (
		Navigation, navigationElement

	);

}

var siteElement = document.querySelector ("body");
if (siteElement) {
	var siteController = Stage.fromElement.call (
		Site, siteElement
		
	);
	
}
